import React from 'react';
import SvgIcon from '../../common/SvgIcon';
import { socials } from '../constants';
import styles from './footer.module.scss';

const FooterSocials = ({ tt, locale }) => (
  <section className={styles.footerSocials}>
    <div className={styles.socialsContact}>
      <SvgIcon icon="bcorp" className={styles.logo} />
      <p className={styles.bcorp}>
        {tt(`Formamos parte del movimiento que quiere cambiar el mundo.`, locale)}
      </p>
    </div>
    <div className={`${styles.contactList} ${locale !== 'fr' ? styles.isHidden : ''}`}>
      <p className={styles.telephoneTitle}>Notre équipe parle français</p>
      <div className={styles.socialsContact}>
        <SvgIcon icon="telefono" />
        <a href="tel:+34977779090" alt="contactPhone" className={styles.socialsContactPhone}>
          {tt('+34 977 77 90 90', locale)}
        </a>
      </div>
    </div>

    <ul className={styles.socialsItems}>
      {socials.map((social) => (
        <li key={social.text} className={styles.socialsItem}>
          <SvgIcon icon={social.icon} className={styles.socialsIcon} />
          <a
            href={tt(social.destination, locale)}
            target="_blank"
            rel="noopener noreferrer"
            alt={social.text}
            className={styles.socialsIconText}
          >
            {social.text}
          </a>
        </li>
      ))}
    </ul>
  </section>
);

export default FooterSocials;
