import React, { useState } from 'react';
import { Link } from 'gatsby';
import ChangeLanguageLinks from '../../../DesktopMenu/ChangeLanguageLinks';
import styles from './userMenu.module.scss';
import iconsMenuList from '../../../icons/iconsMenuList';
import SearchMenu from '../../../DesktopMenu/SearchMenu/SearchMenu';
import SvgIcon from '../../../../common/SvgIcon';

const UserMenu = ({ locale, cmsExtraMenu, altLanguages, tt }) => {
  const [isLangOpen, setLangOpen] = useState(false);

  const searchIn = '//www.google.com/search';

  return (
    <div className={styles.main}>
      <a href={tt('/auth/login', locale)} className={styles.areaPriv}>
        <SvgIcon icon="person" className={styles.areaPrivIcon} />
      </a>
      <Link className={styles.menuLink} to={cmsExtraMenu[0].href}>
        <img
          className={styles.profileIcon}
          src={iconsMenuList[cmsExtraMenu[0].icon]}
          alt={cmsExtraMenu[0].icon}
        />
        {cmsExtraMenu[0].text}
      </Link>

      <SearchMenu locale={locale} searchIn={searchIn} tt={tt} />

      <div className={styles.langSelector}>
        <SvgIcon icon="internet" className={styles.langSelectorIcon} />
        <button
          className={styles.langSelectorButton}
          type="button"
          href="#"
          onClick={() => setLangOpen((current) => !current)}
        >
          <p className={`${styles.langSelectorText} ${isLangOpen ? styles.isOpenLang : ''}`}>
            {locale}
          </p>
          <SvgIcon icon="chevronDown" className={`${isLangOpen ? styles.isOpenLang : ''}`} />
        </button>

        {isLangOpen && (
          <div className={`${styles.langSelectorList} ${isLangOpen ? styles.isOpen : ''} `}>
            <ChangeLanguageLinks locale={locale} altLanguages={altLanguages} />
          </div>
        )}
      </div>
    </div>
  );
};
export default UserMenu;
