import React from 'react';
import SvgIcon from '../../../../common/SvgIcon';
import styles from './NavList.module.scss';
import NavLink from './NavLink';

const NavItem = ({ text, icon, type, href, className, section, handleNextSection }) => (
  <li className={className}>
    {icon && <SvgIcon className={styles.iconItem} icon={icon} />}
    <NavLink
      text={text}
      href={href}
      type={type}
      handleNextSection={handleNextSection}
      section={section}
    />
    {(type === 'root' || type === 'level') && (
      <SvgIcon icon="chevronRight" className={styles.levelIcon} />
    )}
  </li>
);

export default NavItem;
