import React from 'react';
import styles from './desktopMenu.module.scss';
import NavBar from './NavBar/NavBar';
import UserMenu from './UserMenu/UserMenu';

const DesktopMenu = ({
  tt,
  locale,
  options,
  cmsMenu,
  cmsPromotions,
  cmsExtraMenu,
  altLanguages,
  handleIsMenuDesktopOpen,
  isMenuDesktopOpen,
}) => {
  return (
    <div className={styles.main}>
      <UserMenu
        tt={tt}
        cmsExtraMenu={cmsExtraMenu}
        locale={locale}
        options={options}
        altLanguages={altLanguages}
      />
      <NavBar
        cmsPromotions={cmsPromotions}
        cmsMenu={cmsMenu}
        cmsExtraMenu={cmsExtraMenu}
        altLanguages={altLanguages}
        options={options}
        handleIsMenuDesktopOpen={handleIsMenuDesktopOpen}
        isMenuDesktopOpen={isMenuDesktopOpen}
        locale={locale}
        tt={tt}
      />
    </div>
  );
};
export default DesktopMenu;
