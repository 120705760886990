import React from 'react';
import { Link } from 'gatsby';
import styles from './changeLanguageLinks.module.scss';

const ChangeLanguageLinks = ({ locale, altLanguages }) => (
  <div className={styles.main}>
    {altLanguages.map(
      (option) =>
        option.locale !== locale && (
          <Link key={option.locale} className={styles.langItem} to={option.destination}>
            {option.locale}
          </Link>
        )
    )}
  </div>
);

export default ChangeLanguageLinks;
