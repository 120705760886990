const en = require('../i18n/en.json');
const es = require('../i18n/es.json');
const ca = require('../i18n/ca.json');
const ru = require('../i18n/ru.json');
const fr = require('../i18n/fr.json');
const it = require('../i18n/it.json');
const de = require('../i18n/de.json');

const translate = function traduction(text, locale) {
  const showM = false;

  if (showM) {
    const regex = /[a-z0-9]/gi;
    const ret = text.replace(regex, 'M');
    return locale + ret;
  }
  const jsonArray = Object.getOwnPropertyNames(es);
  let content;
  let property;
  for (let i = 0; i <= jsonArray.length; i++) {
    if (es[jsonArray[i]] === text) {
      property = jsonArray[i];
    }
  }
  if (property) {
    switch (locale) {
      case 'es':
        content = es[property];
        break;
      case 'en':
        content = en[property];
        if (content === undefined) content = es[property];
        break;
      case 'ca':
        content = ca[property];
        if (content === undefined) content = es[property];
        break;
      case 'de':
        content = de[property];
        if (content === undefined) content = es[property];
        break;
      case 'fr':
        content = fr[property];
        if (content === undefined) content = es[property];
        break;
      case 'sv':
        content = sv[property];
        if (content === undefined || content === '') content = `TAG: ${property}`;
        break;
      case 'it':
        content = it[property];
        if (content === undefined) content = es[property];
        break;
      case 'ru':
        content = ru[property];
        if (content === undefined) content = es[property];
        break;
      case 'cn':
        content = cn[property];
        if (content === undefined || content === '') content = `TAG: ${property}`;
        break;
      default:
        content = text;
        break;
    }
    return content;
  }
  return text;
};

// export default translate;
module.exports = translate;
