import Img from 'gatsby-image/withIEPolyfill';
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styles from './jumbotron.module.scss';
import RatingStars from '../../RatingStars/RatingStars';
import Icon from '../../../helpers/icon';
import HeimdallIcons from './jumbotron-icons';
import tt from '../../../helpers/translation';
import ModalMap from '../Widgets/ModalMap/ModalMap';

export const JumbotronData = graphql`
  fragment JumbotronData on jumbotronBlock {
    id
    tag
    pageid
    blockid
    lang
    title
    subtitle
    location
    stars
    starsColor
    map
    imageMobile
    image
    imageMap
    subtitleBgColor
    subtitleTextColor
    position
    localImage1 {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    localImage2 {
      childImageSharp {
        fluid(maxWidth: 550, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const JumbotronContent = ({
  stars,
  starsColor,
  subtitle,
  location,
  map,
  openMap,
  subtitleBgColor,
  subtitleTextColor,
}) => {
  return (
    <>
      {stars ? (
        <div className={styles.starsContainer}>
          <div className={styles.containerLine}>
            <div className={styles.line} />
            <div className={styles.secondLine} />
          </div>
          <div className={styles.secondContainerLine}>
            <RatingStars count={stars} width="20" height="20" color={starsColor} />
          </div>
          <div className={styles.containerLine}>
            <div className={styles.line} />
            <div className={styles.secondLine} />
          </div>
        </div>
      ) : null}
      {subtitle ? (
        <h2
          className={styles.textSubtitle}
          style={{
            color: `${subtitleTextColor || '#fff'}`,
            backgroundColor: `${subtitleBgColor || 'rgba(0, 0, 0, 0.7)'}`,
          }}
        >
          <a href="https://book.portaventuraworld.com/es/tickets" className="t-hero-cta">
            {subtitle}
          </a>
        </h2>
      ) : null}
      {location ? (
        <div className={styles.locationText}>
          <Icon icon="location_on" width="22" height="22" color="white" iconSet={HeimdallIcons} />
          <div className={styles.locationText}>{location}</div>
        </div>
      ) : null}
      {map ? (
        <>
          <button className={styles.buttonMap} onClick={openMap} type="button">
            <Icon
              icon="LOCATION"
              width="20"
              height="20"
              class="icon-arrow"
              color="white"
              iconSet={HeimdallIcons}
            />
            {tt('VER MAPA', 'es')}
          </button>

          {/* EN MOBILE SE DESCARGA DIRECTAMENTE */}

          <a className={`${styles.buttonMap} ${styles.mobile}`} href="" download="image.png">
            <Icon
              icon="LOCATION"
              width="20"
              height="20"
              class="icon-arrow"
              color="white"
              iconSet={HeimdallIcons}
            />
            {tt('VER MAPA', 'es')}
          </a>
        </>
      ) : null}
    </>
  );
};

const ContentDesktop = (data, openMap) => {
  const sources = [
    {
      ...data.localImage2.childImageSharp.fluid,
    },
    {
      ...data.localImage1.childImageSharp.fluid,
      media: `(min-width: 525px)`,
    },
  ];
  return (
    <div className={styles.jumboDesktop}>
      <Img
        fluid={sources}
        backgroundColor="#040e18"
        className={styles.dualTest}
        placeholderStyle={{ position: 'relative' }}
      />
      <div className={styles.contentJumbotron}>
        <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: data.title }} />
        <JumbotronContent openMap={openMap} {...data} />
      </div>
    </div>
  );
};

/**
 * It is used as header of pages. All props should be inside data prop
 * @param {Object} data
 * @param {ImageSharp} data.image
 * @param {string} title
 * @property {string} subtitle
 * @property body - Map, Stars, Location
 */
const JumbotronContainer = (data) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Jumbotron
        {...data}
        openMap={() => {
          setOpen(true);
        }}
      />

      {isOpen ? (
        <ModalMap onClose={() => setOpen(false)} image={data.imageMap} isMap>
          <img src={data.imageMap} className={styles.finalImage} />
        </ModalMap>
      ) : null}
    </>
  );
};

const Jumbotron = (data) => {
  return (
    <div className={styles.jumboContainer}>
      <ContentDesktop {...data} />
    </div>
  );
};

export default JumbotronContainer;
