import { Link } from 'gatsby';
import React, { useState } from 'react';
import SvgIcon from '../../../common/SvgIcon';
import iconsMenuList from '../../icons/iconsMenuList';
import ChangeLanguageLinks from '../ChangeLanguageLinks';
import SearchMenu from '../SearchMenu/SearchMenu';
import styles from './userMenu.module.scss';

const UserMenu = ({ locale, cmsExtraMenu, options, altLanguages, tt }) => {
  const [isOpen, setOpen] = useState(false);

  const toggleOpen = (e) => {
    e.preventDefault();
    setOpen((current) => !current);
  };

  const searchIn = '//www.google.com/search';

  return (
    <div className={styles.main}>
      <ul className={styles.container}>
        <li className={styles.userMenuItem}>
          <div className={`${styles.menuLink} ${styles.hidden}`}>
            <SvgIcon icon="person" />
            <Link className={styles.menuLink} to={cmsExtraMenu[0].href}>
              {cmsExtraMenu[0].text}
            </Link>
          </div>
        </li>
        <li className={styles.userMenuItem2}>
          <a href={tt('/auth/login', locale)}>
            <SvgIcon icon="person" />
          </a>
        </li>
        <li className={styles.userMenuItem}>
          <SvgIcon icon="mobile" />
          <Link className={styles.menuLink} to={cmsExtraMenu[1].href}>
            {cmsExtraMenu[1].text}
          </Link>
        </li>

        <li className={styles.userMenuItem}>
          <SearchMenu userBar locale={locale} searchIn={searchIn} tt={tt} />
        </li>

        <li className={styles.langSelector}>
          <SvgIcon icon="internet" className={styles.menuIcon} />
          <p>{locale.toUpperCase()}</p>
          <button className={styles.langSelectorButton} type="button" href="#" onClick={toggleOpen}>
            <img width="10px" height="10px" src={iconsMenuList.flecha2Menu} alt="flecha2" />
          </button>

          {isOpen && (
            <div className={styles.langSelectorList}>
              <ChangeLanguageLinks locale={locale} options={options} altLanguages={altLanguages} />
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default UserMenu;
