const urls = require('../constants/urls');
const findDestination = require('./findDestination');

const validRoute = (route) =>
  route in urls.pages || route in urls.templates || route in urls.templates_multilang;

/*
a: array in es
b: array in selected lang
c:selected value on a
returns x. X is to C the same as B is to A */
const localizedParams = (a, b, c) =>
  a
    .map((x, i) => (c === x ? b[i] : '__null_param__'))
    .filter((dest) => dest !== '__null_param__')[0];

const findException = (lang, struct, parameters, translated = false) => {
  let p;
  // If parameters are translated we have to switch them back to the original language since
  // Exceptions are defined in the original language
  // This map below does the required conversion
  if (translated && lang !== 'es') {
    p = parameters.map((value, index) =>
      localizedParams(
        Object.values(struct.options)[index].es,
        Object.values(struct.options)[index][lang],
        value
      )
    );
  } else {
    p = parameters;
  }
  let candidates = struct.exceptions.filter((e) => e.lang === lang);
  if (candidates.length === 0) {
    return false;
  }

  Object.entries(struct.options).forEach(([option], idx) => {
    candidates = candidates.filter((e) => e[option] === p[idx]);
  });

  if (candidates.length > 1) {
    throw new Error(
      `Option matches multiple values: ${JSON.stringify({
        lang,
        exceptions: struct.exceptions,
        p,
      })}`
    );
  }

  return candidates.length ? candidates[0] : false;
};

const resolvePageUrl = (lang, destination) => {
  if (!(destination in urls.pages)) {
    return false;
  }

  const struct = urls.pages[destination];
  const urlObject = struct.find((e) => e.lang === lang) || struct.find((e) => e.lang === 'es');
  return urlObject.urlGatsby;
};

const resolveTemplateUrl = (lang, destination, parameters) => {
  if (!(destination in urls.templates)) {
    return false;
  }

  const struct = urls.templates[destination];
  if ('exceptions' in struct) {
    const exception = findException(lang, struct, parameters);
    if (exception) {
      return exception.urlGatsby;
    }
  }

  const urlObject =
    struct.urls.find((e) => e.lang === lang) || struct.urls.find((e) => e.lang === 'es');

  return findDestination.findDestination(urlObject.urlGatsby, struct.options, parameters);
};

const resolveMultilangTemplateUrl = (lang, destination, parameters, translatedParameters) => {
  if (!(destination in urls.templates_multilang)) {
    return false;
  }

  const struct = urls.templates_multilang[destination];
  if ('exceptions' in struct) {
    const exception = findException(lang, struct, parameters, (translated = true));
    if (exception) {
      return exception.urlGatsby;
    }
  }

  const urlObject =
    struct.urls.find((e) => e.lang === lang) || struct.urls.find((e) => e.lang === 'es');

  const intlDestination = [];
  Object.entries(struct.options).forEach(([, entries], i) => {
    intlDestination.push(localizedParams(entries.es, entries[urlObject.lang], parameters[i]));
  });

  const localizedOptions = Object(null);
  Object.entries(struct.options).forEach(([name, entries]) => {
    localizedOptions[name] = entries[urlObject.lang];
  });

  const pattern = urlObject.urlGatsby;

  return findDestination.findDestination(
    pattern,
    localizedOptions,
    translatedParameters ? parameters : intlDestination
  );
};

const translateParametersToSpanish = (destination, parameters, sourceLang) => {
  if (!(destination in urls.templates_multilang)) {
    throw new Error(
      `Trying to translate parameters of a non-multilang section${JSON.stringify({
        destination,
        parameters,
        sourceLang,
      })}`
    );
  }

  const struct = urls.templates_multilang[destination];
  const translated = [];
  Object.entries(struct.options).forEach(([, entries], optionIdx) => {
    const input = parameters[optionIdx];
    const valueIdx = entries[sourceLang]?.indexOf(input);

    if (valueIdx < 0) {
      throw new Error(
        `Could not translate parameters/options to spanish language${JSON.stringify({
          input,
          destination,
          parameters,
          sourceLang,
          candidates: entries[sourceLang],
        })}`
      );
    }
    translated.push(entries.es[valueIdx]);
  });

  return translated;
};

/**
 * Resolves an URL given specific parameters.
 *
 * @param {string} lang the destination language
 * @param {string} destination the route name (key in urls.pages or urls.templates or urls.templates_multilang)
 * @param {Array.<string>} [parameters=[]] the active parameters as an array of ordered (in the same order as defined in urls.xxx.destination.options) strings (es language!)
 */
const resolveUrl = (lang, destination, parameters) => {
  const resolvedDestinationName = validRoute(destination) ? destination : 'index';

  return (
    resolvePageUrl(lang, resolvedDestinationName) ||
    resolveTemplateUrl(lang, resolvedDestinationName, parameters) ||
    resolveMultilangTemplateUrl(lang, resolvedDestinationName, parameters)
  );
};

/**
 * Resolves an URL given some parameters localized in some language.
 *
 * @param {string} lang the destination language
 * @param {string} destination the route name (key in urls.pages or urls.templates or urls.templates_multilang)
 * @param {string} sourceLang the source language (that <parameters> are in!)
 * @param {Array.<string>} parameters the parameters in <sourceLang> language
 */
const resolveLocalizedUrl = (lang, destination, sourceLang, parameters) => {
  const resolvedDestinationName = validRoute(destination) ? destination : 'index';

  // In non-multilang destinations it does not matter beause parameters are not translated.
  const url =
    resolvePageUrl(lang, resolvedDestinationName) ||
    resolveTemplateUrl(lang, resolvedDestinationName, parameters);
  if (url) {
    return url;
  }

  const esParameters = translateParametersToSpanish(
    resolvedDestinationName,
    parameters,
    sourceLang
  );

  return resolveMultilangTemplateUrl(lang, resolvedDestinationName, esParameters);
};

const findAlternatePageLanguages = (destination) => {
  if (!(destination in urls.pages)) {
    return false;
  }

  const struct = urls.pages[destination];
  return struct.map((i) => i.lang);
};

const findAlternateTemplateLanguages = (destination) => {
  if (!(destination in urls.templates)) {
    return false;
  }

  const struct = urls.templates[destination];
  return struct.urls.map((i) => i.lang);
};

const findAlternateMultilangTemplateLanguages = (destination) => {
  if (!(destination in urls.templates_multilang)) {
    return false;
  }

  const struct = urls.templates_multilang[destination];
  return struct.urls.map((i) => i.lang);
};

const findAlternateLanguages = (pageName) => {
  const result =
    findAlternatePageLanguages(pageName) ||
    findAlternateTemplateLanguages(pageName) ||
    findAlternateMultilangTemplateLanguages(pageName);

  if (!result) {
    console.warn(`Unable to find alternate languages for page ${pageName}`);
  }

  return result || [];
};

module.exports = {
  resolve: resolveUrl,
  resolveLocalized: resolveLocalizedUrl,
  findException,
  findAlternateLanguages,
};
