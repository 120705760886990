import React from 'react';
import styles from './footer.module.scss';
import FooterLink from './FooterLink';

const FooterMenu = ({ tt, locale, cmsFooter }) => (
  <section className={styles.footerMenu}>
    <ul className={styles.menuList}>
      {cmsFooter.map((item) => (
        <li key={item.text} className={styles.menuItem}>
          <h3 className={styles.menuItemTitle}>{tt(item.text, locale)}</h3>

          {item.type === 'level' && (
            <ul className={styles.menuSubItemList}>
              {item.children.map((subItem) => (
                <li key={subItem.text} className={styles.menuSubItem}>
                  <FooterLink
                    type={subItem.type}
                    href={subItem.href}
                    text={subItem.text}
                    className={styles.menuSubItemText}
                  />
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  </section>
);

export default FooterMenu;
