import React, { useEffect, useRef, useState } from 'react';
import styles from './NavList.module.scss';
import NavItem from './NavItem';

const NavList = ({ cmsMenu, currentSectionPosition, setCurrentSectionPosition, locale, tt }) => {
  const [menuSections, setMenuSections] = useState(cmsMenu ? [cmsMenu] : null);
  const [sectionTitle, setSectionTitle] = useState(null);

  const handleNextSection = (children, title) => {
    setSectionTitle(title);
    setMenuSections([...menuSections, children]);
    setCurrentSectionPosition(currentSectionPosition + 1);
  };

  const prevSection = useRef();

  useEffect(() => {
    if (currentSectionPosition < prevSection.current) {
      setMenuSections(menuSections.slice(0, currentSectionPosition));
    }

    prevSection.current = currentSectionPosition;
  });

  return (
    <nav>
      <ul className={styles.main}>
        {currentSectionPosition > 1 && <li className={styles.listTitle}>{sectionTitle}</li>}

        {currentSectionPosition === 1 && (
          <NavItem
            key="Semana Santa"
            text={tt('Easter Celebration', locale)}
            icon="promotion_menu"
            type="newsite"
            href={tt(
              'https://www.portaventuraworld.com/semana-santa',
              locale
            )}
            className={`${styles.listItem} ${styles.promo}`}
          />
        )}

        {menuSections && (
          <ul>
            {menuSections[menuSections.length - 1].map(({ text, icon, type, href, children }) => (
              <NavItem
                key={text}
                text={text}
                icon={icon}
                type={type}
                href={href}
                className={styles.listItem}
                section={children}
                handleNextSection={handleNextSection}
              />
            ))}
          </ul>
        )}
      </ul>
    </nav>
  );
};

export default NavList;
