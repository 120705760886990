import React from 'react';
import styles from './footerMenu.module.scss';
import CollapsibleDiv from '../../../../common/CollapsibleDiv/CollapsibleDiv';
import FooterLink from './FooterLink';

const FooterMenu = ({ tt, locale, cmsFooter }) =>
  cmsFooter && (
    <ul className={styles.main}>
      {cmsFooter.map((footerItem) => (
        <li key={footerItem.text} className={styles.footerItem}>
          <CollapsibleDiv
            title={tt(footerItem.text, locale)}
            icon
            className={styles.footerItemTitle}
          >
            <ul className={styles.footerItemContainer}>
              {footerItem.children.map((footerSubItem) => (
                <li key={footerSubItem.text} className={styles.footerSubItem}>
                  <FooterLink
                    text={footerSubItem.text}
                    href={footerSubItem.href}
                    type={footerSubItem.type}
                    className={styles.link}
                  />
                </li>
              ))}
            </ul>
          </CollapsibleDiv>
        </li>
      ))}
    </ul>
  );

export default FooterMenu;
