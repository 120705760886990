module.exports = {
  disabled_pages: [
    // // 'index',
    // 'distributiva_parques',
    // 'distributiva_mundos',
    // 'distributiva_tiendas_pa',
    // 'distributiva_tiendas_fl',
    // 'distributiva_tiendas_ap',
    // 'experience_fl',
    // 'kids_area',
    // 'pase_express',
    // 'distributiva_excursiones_julia',
    // 'servicios_parques',
    // 'como_llegar',
    // 'contacto',
    // 'ficha_parques',
    // 'ficha_atracciones',
    // 'ficha_wellness',
    // 'ficha_promociones',
    // 'pase_anual',
    // 'ficha_espectaculos',
    // 'ficha_grupos',
    // 'ficha_tiendas',
    // 'ficha_restaurantes',
    // 'ficha_hoteles',
    // 'ficha_calendar',
    // 'ficha_mundos',
    // 'distributiva_entorno',
    // 'ficha_entorno',
    // 'campings_asociados',
    // 'nosotros',
    // 'nuestro_compromiso',
    // 'medio_ambiente',
    // 'partners',
    // 'trabajar_nosotros',
    // 'business',
    // 'fundacion',
    // 'gabinete_prensa',
    // 'politica_privacidad',
    // 'aviso_legal',
    // 'condiciones_compra',
    // 'reconocimientos',
    // 'dossier_prensa',
    // 'material_grafico',
    // 'preguntas_frecuentes',
    // 'condiciones_generales',
    // 'intolerancias_alergias',
    // 'ficha_material_grafico',
    // 'agencia_viajes',
    // 'traslados',
    // 'ficha_prensa',
    // 'promotion_halloween',
    // 'shows_halloween',
    // 'material_grafico_halloween',
    // 'material_grafico_navidad',
    // // // ----- PLAN YOUR STAY NEEDED PAGES --------
    // 'plan_your_stay',
    // 'plan_your_stay_types',
    // 'sport_wellness',
    // 'distributiva_hoteles',
    // 'distributiva_atracciones_pa',
    // 'distributiva_atracciones_fl',
    // 'distributiva_atracciones_ap',
    // 'distributiva_restaurantes_pa',
    // 'distributiva_restaurantes_ap',
    // 'distributiva_restaurantes_fl',
    // 'distributiva_espectaculos_pa',
    // 'distributiva_espectaculos_fl',
    // 'ficha_destiny',
    // // // ----- PLAN YOUR STAY NEEDED PAGES --------
    // 'navidad',
    // 'promotion_navidad',
    // 'ficha_espectaculos_navidad',
    // 'shows_navidad',
    // 'promotion_boxing_day',
    // 'checkin',
    // 'novedades',
    // 'easteregg',
    // 'distributiva_actividades',
    // 'ficha_actividades',
    // 'indoor',
    // 'healthy',
    // 'app',
    // 'hotel_roulette',
    // 'laliga',
    // 'laliga_thebeatchallenge',
    // 'laliga_twenty_nines',
    // 'pys_trucos',
    // 'pys_recomendaciones',
    // 'hotel_pase_express',
    // 'pasajes_halloween',
    // 'notFound', // DISABLED
  ],

  // this array will be removed. It tells wether the page has been moved to /paw-pages or not.
  migrated: [
    'index',
    'distributiva_parques',
    'distributiva_mundos',
    'distributiva_promociones',
    'distributiva_hoteles',
    'distributiva_tiendas_fl',
    'distributiva_tiendas_ap',
    'distributiva_tiendas_pa',
    'distributiva_atracciones_fl',
    'distributiva_atracciones_ap',
    'distributiva_atracciones_pa',
    'sport_wellness',
    'pase_express',
    'hotel_pase_express',
    'distributiva_restaurantes_pa',
    'distributiva_restaurantes_ap',
    'distributiva_restaurantes_fl',
    'experience_fl',
    'kids_area',
    'servicios_parques',
    'distributiva_excursiones_julia',
    'distributiva_espectaculos_fl',
    'distributiva_espectaculos_pa',
    'como_llegar',
    'contacto',
    'distributiva_entorno',
    'campings_asociados',
    'nosotros',
    'nuestro_compromiso',
    'medio_ambiente',
    'partners',
    'reconocimientos',
    'trabajar_nosotros',
    'business',
    'fundacion',
    'agencia_viajes',
    'dossier_prensa',
    'gabinete_prensa',
    'material_grafico',
    'condiciones_generales',
    'politica_privacidad',
    'aviso_legal',
    'condiciones_compra',
    'traslados',
    'intolerancias_alergias',
    'plan_your_stay',
    'shows_halloween',
    'promotion_halloween',
    'promotion_navidad',
    'navidad',
    'material_grafico_halloween',
    'shows_navidad',
    'material_grafico_navidad',
    'promotion_boxing_day',
    'checkin',
    'novedades',
    'easteregg',
    'distributiva_actividades',
    'indoor',
    'healthy',
    'app',
    'hotel_roulette',
    'laliga',
    'laliga_thebeatchallenge',
    'laliga_twenty_nines',
    'pys_trucos',
    'pys_recomendaciones',
    'pasajes_halloween',
    'notFound',
  ],
  auto_pages: [
    'entradas',
    'entradas_fl',
    'entradas_bs',
    'entradas_cap',
    'distr_promociones',
    'ficha_promo_hotel_entrada_halloween',
    'ficha_promo_entradas_2_dias',
    'ficha_promo_entrada_hotel',
    'ficha_promo_tienda_online',
    'ficha_promo_promocion_otono',
    'ficha_promo_hotel',
    'ficha_promo_black_friday',
    'ficha_promo_entradas',
    'tarjeta_regalo',
    'ficha_promo_vacaciones',
    'ficha_promo_arbol',
    'ficha_promo_yatetoca',
    'ficha_promo_halloween_passport',
    'ficha_promo_halloween_la_muerte_viva',
    'mejores_sitios_para_comer',
    'mejores_atracciones',
    'mesa_navidad',
    'papel_de_regalo',
    'requisitos_altura',
    'requisitos_altura_fl',
    'requisitos_altura_cap',
    'semana_santa',
    'condiciones_hoteles',
    'delion_experience',
    'ficha_club_oferta_pase_anual',
    'ficha_promo_descuento_entradas',
    'ficha_promo_vacaciones_verano',
    'ficha_promo_halloween_roulette',
    'ficha_promo_balloon_world_cup',
    'veganos',
    'ficha_promo_hotel_portaventura_navidad',
    'hotel_nochebuena',
    'ficha_promo_cyber_monday',
    'ficha_promo_cena_navidad_gratuita',
    'ficha_promo_tu_regalo_de_navidad',
    'ficha_action_fitur',
    'ficha_promo_tickets_hotel_discount',
    'ficha_promo_grupos_vacacionales',
    'ficha_promo_oferta_aniversario',
  ],

  pages: {
    index: [
      { lang: 'es', urlWilson: 'es', urlGatsby: '/' },
      { lang: 'fr', urlWilson: 'fr', urlGatsby: '/fr' },
      { lang: 'ca', urlWilson: 'ca', urlGatsby: '/ca' },
      { lang: 'en', urlWilson: 'en', urlGatsby: '/en' },
    ],
    notFound: [
      { lang: 'es', urlWilson: '404-error', urlGatsby: '/error' },
      { lang: 'fr', urlWilson: 'fr/404-error', urlGatsby: '/fr/error' },
      { lang: 'ca', urlWilson: 'ca/404-error', urlGatsby: '/ca/error' },
      { lang: 'en', urlWilson: 'en/404-error', urlGatsby: '/en/error' },
    ],
    closed: [
      { lang: 'es', urlWilson: 'es/informacion-importante', urlGatsby: '/informacion-importante' },
      {
        lang: 'fr',
        urlWilson: 'fr/information-importante',
        urlGatsby: '/fr/information-importante',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/infomacio-important',
        urlGatsby: '/ca/infomacio-important',
      },
      {
        lang: 'en',
        urlWilson: 'en/important-information',
        urlGatsby: '/en/important-information',
      },
    ],
    distributiva_parques: [
      { lang: 'es', urlWilson: 'es/parques', urlGatsby: '/parques' },
      { lang: 'fr', urlWilson: 'fr/parcs', urlGatsby: '/fr/parcs' },
      { lang: 'ca', urlWilson: 'ca/parcs', urlGatsby: '/ca/parcs' },
      { lang: 'en', urlWilson: 'en/parks', urlGatsby: '/en/parks' },
    ],
    laliga: [
      { lang: 'es', urlWilson: 'es/laliga', urlGatsby: '/laliga' },
      { lang: 'fr', urlWilson: 'fr/laliga', urlGatsby: '/fr/laliga' },
      { lang: 'ca', urlWilson: 'ca/laliga', urlGatsby: '/ca/laliga' },
      { lang: 'en', urlWilson: 'en/laliga', urlGatsby: '/en/laliga' },
    ],
    laliga_thebeatchallenge: [
      {
        lang: 'es',
        urlWilson: 'es/laliga-thebeatchallenge',
        urlGatsby: '/laliga-thebeatchallenge',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/laliga-thebeatchallenge',
        urlGatsby: '/fr/laliga-thebeatchallenge',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/laliga-thebeatchallenge',
        urlGatsby: '/ca/laliga-thebeatchallenge',
      },
      {
        lang: 'en',
        urlWilson: 'en/laliga-thebeatchallenge',
        urlGatsby: '/en/laliga-thebeatchallenge',
      },
    ],
    laliga_twenty_nines: [
      {
        lang: 'es',
        urlWilson: 'es/laliga-twenty-nines',
        urlGatsby: '/laliga-twenty-nines',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/laliga-twenty-nines',
        urlGatsby: '/fr/laliga-twenty-nines',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/laliga-twenty-nines',
        urlGatsby: '/ca/laliga-twenty-nines',
      },
      {
        lang: 'en',
        urlWilson: 'en/laliga-twenty-nines',
        urlGatsby: '/en/laliga-twenty-nines',
      },
    ],
    pys_trucos: [
      {
        lang: 'es',
        urlWilson: 'es/planifica-tu-viaje/trucos',
        urlGatsby: '/planifica-tu-viaje/trucos',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/planifiez-votre-sejour/astuces',
        urlGatsby: '/fr/planifiez-votre-sejour/astuces',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/planifica-el-teu-viatge/trucs',
        urlGatsby: '/ca/planifica-el-teu-viatge/trucs',
      },
      { lang: 'en', urlWilson: 'en/plan-your-trip/tips', urlGatsby: '/en/plan-your-trip/tips' },
    ],
    pys_recomendaciones: [
      {
        lang: 'es',
        urlWilson: 'es/planifica-tu-viaje/consejos-recomendaciones',
        urlGatsby: '/planifica-tu-viaje/consejos-recomendaciones',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/planifiez-votre-sejour/conseils-recommandations',
        urlGatsby: '/fr/planifiez-votre-sejour/conseils-recommandations',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/planifica-el-teu-viatge/consells-recomanacions',
        urlGatsby: '/ca/planifica-el-teu-viatge/consells-recomanacions',
      },
      {
        lang: 'en',
        urlWilson: 'en/plan-your-trip/advice-recommendations',
        urlGatsby: '/en/plan-your-trip/advice-recommendations',
      },
    ],
    hotel_roulette: [
      { lang: 'es', urlWilson: 'es/hoteles/hotel-roulette', urlGatsby: '/hoteles/hotel-roulette' },
      {
        lang: 'fr',
        urlWilson: 'fr/hotels/hotel-roulette',
        urlGatsby: '/fr/hotels/hotel-roulette',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/hotels/hotel-roulette',
        urlGatsby: '/ca/hotels/hotel-roulette',
      },
      {
        lang: 'en',
        urlWilson: 'en/hotels/hotel-roulette',
        urlGatsby: '/en/hotels/hotel-roulette',
      },
    ],
    distributiva_promociones: [
      { lang: 'es', urlWilson: 'es/promociones-antiguo', urlGatsby: '/promociones-antiguo' },
      { lang: 'fr', urlWilson: 'fr/promotions-antiguo', urlGatsby: '/fr/promotions-antiguo' },
      { lang: 'ca', urlWilson: 'ca/promocions-antiguo', urlGatsby: '/ca/promocions-antiguo' },
      { lang: 'en', urlWilson: 'en/promotions-antiguo', urlGatsby: '/en/promotions-antiguo' },
    ],
    healthy: [
      {
        lang: 'es',
        urlWilson: 'es/medidas-generales-higiene',
        urlGatsby: '/medidas-generales-higiene',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/mesures-generales-hygiene',
        urlGatsby: '/fr/mesures-generales-hygiene',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/mesures-generals-higiene',
        urlGatsby: '/ca/mesures-generals-higiene',
      },
      {
        lang: 'en',
        urlWilson: 'en/general-hygiene-measures',
        urlGatsby: '/en/general-hygiene-measures',
      },
    ],
    distributiva_mundos: [
      { lang: 'es', urlWilson: 'es/zonas', urlGatsby: '/zonas' },
      { lang: 'fr', urlWilson: 'fr/zonas', urlGatsby: '/fr/zonas' },
      { lang: 'ca', urlWilson: 'ca/zones', urlGatsby: '/ca/zones' },
      { lang: 'en', urlWilson: 'en/areas', urlGatsby: '/en/areas' },
    ],
    distributiva_hoteles: [
      { lang: 'es', urlWilson: 'es/hoteles', urlGatsby: '/hoteles' },
      { lang: 'fr', urlWilson: 'fr/hotels', urlGatsby: '/fr/hotels' },
      { lang: 'ca', urlWilson: 'ca/hotels', urlGatsby: '/ca/hotels' },
      { lang: 'en', urlWilson: 'en/hotels', urlGatsby: '/en/hotels' },
    ],
    distributiva_tiendas_pa: [
      { lang: 'es', urlWilson: 'es/tiendas', urlGatsby: '/tiendas' },
      { lang: 'fr', urlWilson: 'fr/boutiques', urlGatsby: '/fr/boutiques' },
      { lang: 'ca', urlWilson: 'ca/botigues', urlGatsby: '/ca/botigues' },
      { lang: 'en', urlWilson: 'en/shops', urlGatsby: '/en/shops' },
    ],
    distributiva_tiendas_fl: [
      { lang: 'es', urlWilson: 'es/ferrari-land/tiendas', urlGatsby: '/ferrari-land/tiendas' },
      {
        lang: 'fr',
        urlWilson: 'fr/ferrari-land/boutiques',
        urlGatsby: '/fr/ferrari-land/boutiques',
      },
      { lang: 'ca', urlWilson: 'ca/ferrari-land/botigues', urlGatsby: '/ca/ferrari-land/botigues' },
      { lang: 'en', urlWilson: 'en/ferrari-land/shops', urlGatsby: '/en/ferrari-land/shops' },
    ],
    distributiva_tiendas_ap: [
      { lang: 'es', urlWilson: 'es/aquatic-park/tiendas', urlGatsby: '/aquatic-park/tiendas' },
      {
        lang: 'fr',
        urlWilson: 'fr/aquatic-park/boutiques',
        urlGatsby: '/fr/aquatic-park/boutiques',
      },
      { lang: 'ca', urlWilson: 'ca/aquatic-park/botigues', urlGatsby: '/ca/aquatic-park/botigues' },
      { lang: 'en', urlWilson: 'en/aquatic-park/shops', urlGatsby: '/en/aquatic-park/shops' }, // OJO reportar a t2o aquatic- acuatic
    ],
    distributiva_atracciones_pa: [
      { lang: 'es', urlWilson: 'es/atracciones', urlGatsby: '/atracciones' },
      { lang: 'fr', urlWilson: 'fr/attractions', urlGatsby: '/fr/attractions' },
      { lang: 'ca', urlWilson: 'ca/atraccions', urlGatsby: '/ca/atraccions' },
      { lang: 'en', urlWilson: 'en/rides', urlGatsby: '/en/rides' },
    ],
    distributiva_atracciones_fl: [
      {
        lang: 'es',
        urlWilson: 'es/ferrari-land/atracciones',
        urlGatsby: '/ferrari-land/atracciones',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/ferrari-land/attractions',
        urlGatsby: '/fr/ferrari-land/attractions',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/ferrari-land/atraccions',
        urlGatsby: '/ca/ferrari-land/atraccions',
      },
      { lang: 'en', urlWilson: 'en/ferrari-land/rides', urlGatsby: '/en/ferrari-land/rides' },
    ],
    distributiva_atracciones_ap: [
      {
        lang: 'es',
        urlWilson: 'es/aquatic-park/atracciones',
        urlGatsby: '/aquatic-park/atracciones',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/aquatic-park/attractions',
        urlGatsby: '/fr/aquatic-park/attractions',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/aquatic-park/atraccions',
        urlGatsby: '/ca/aquatic-park/atraccions',
      },
      { lang: 'en', urlWilson: 'en/aquatic-park/rides', urlGatsby: '/en/aquatic-park/rides' },
    ],
    experience_fl: [
      {
        lang: 'es',
        urlWilson: 'es/ferrari-land/experience',
        urlGatsby: '/ferrari-land/experience',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/ferrari-land/experience',
        urlGatsby: '/fr/ferrari-land/experience',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/ferrari-land/experience',
        urlGatsby: '/ca/ferrari-land/experience',
      },
      {
        lang: 'en',
        urlWilson: 'en/ferrari-land/experience',
        urlGatsby: '/en/ferrari-land/experience',
      },
    ],
    kids_area: [
      {
        lang: 'es',
        urlWilson: 'es/ferrari-land/atracciones/kids-area',
        urlGatsby: '/ferrari-land/atracciones/kids-area',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/ferrari-land/attractions/kids-area',
        urlGatsby: '/fr/ferrari-land/attractions/kids-area',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/ferrari-land/atraccions/kids-area',
        urlGatsby: '/ca/ferrari-land/atraccions/kids-area',
      },
      {
        lang: 'en',
        urlWilson: 'en/ferrari-land/attractions/kids-area',
        urlGatsby: '/en/ferrari-land/attractions/kids-area',
      },
    ],
    distributiva_restaurantes_pa: [
      { lang: 'es', urlWilson: 'es/restaurantes', urlGatsby: '/restaurantes' },
      { lang: 'fr', urlWilson: 'fr/restaurants', urlGatsby: '/fr/restaurants' },
      { lang: 'ca', urlWilson: 'ca/restaurants', urlGatsby: '/ca/restaurants' },
      { lang: 'en', urlWilson: 'en/restaurants', urlGatsby: '/en/restaurants' },
    ],
    distributiva_restaurantes_ap: [
      {
        lang: 'es',
        urlWilson: 'es/aquatic-park/restaurantes',
        urlGatsby: '/aquatic-park/restaurantes',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/aquatic-park/restaurants',
        urlGatsby: '/fr/aquatic-park/restaurants',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/aquatic-park/restaurants',
        urlGatsby: '/ca/aquatic-park/restaurants',
      },
      {
        lang: 'en',
        urlWilson: 'en/aquatic-park/restaurants',
        urlGatsby: '/en/aquatic-park/restaurants',
      },
    ],
    distributiva_restaurantes_fl: [
      {
        lang: 'es',
        urlWilson: 'es/ferrari-land/restaurantes',
        urlGatsby: '/ferrari-land/restaurantes',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/ferrari-land/restaurants',
        urlGatsby: '/fr/ferrari-land/restaurants',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/ferrari-land/restaurants',
        urlGatsby: '/ca/ferrari-land/restaurants',
      },
      {
        lang: 'en',
        urlWilson: 'en/ferrari-land/restaurants',
        urlGatsby: '/en/ferrari-land/restaurants',
      },
    ],
    distributiva_entorno: [
      { lang: 'es', urlWilson: 'es/destino', urlGatsby: '/destino' },
      { lang: 'fr', urlWilson: 'fr/destination', urlGatsby: '/fr/destination' },
      { lang: 'ca', urlWilson: 'ca/destinacio', urlGatsby: '/ca/destinacio' },
      { lang: 'en', urlWilson: 'en/destination', urlGatsby: '/en/destination' },
    ],
    distributiva_espectaculos_pa: [
      { lang: 'es', urlWilson: 'es/espectaculos', urlGatsby: '/espectaculos' },
      { lang: 'fr', urlWilson: 'fr/spectacles', urlGatsby: '/fr/spectacles' },
      { lang: 'ca', urlWilson: 'ca/espectacles', urlGatsby: '/ca/espectacles' },
      { lang: 'en', urlWilson: 'en/shows', urlGatsby: '/en/shows' },
    ],
    distributiva_espectaculos_fl: [
      {
        lang: 'es',
        urlWilson: 'es/ferrari-land/espectaculos',
        urlGatsby: '/ferrari-land/espectaculos',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/ferrari-land/spectacles',
        urlGatsby: '/fr/ferrari-land/spectacles',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/ferrari-land/espectacles',
        urlGatsby: '/ca/ferrari-land/espectacles',
      },
      { lang: 'en', urlWilson: 'en/ferrari-land/shows', urlGatsby: '/en/ferrari-land/shows' },
    ],

    sport_wellness: [
      { lang: 'es', urlWilson: 'es/sport-wellness', urlGatsby: '/sport-wellness' },
      { lang: 'fr', urlWilson: 'fr/sport-wellness', urlGatsby: '/fr/sport-wellness' },
      { lang: 'ca', urlWilson: 'ca/sport-wellness', urlGatsby: '/ca/sport-wellness' },
      { lang: 'en', urlWilson: 'en/sport-wellness', urlGatsby: '/en/sport-wellness' },
    ],
    pase_express: [
      { lang: 'es', urlWilson: 'es/entradas/pase-express', urlGatsby: '/entradas/pase-express' },
      {
        lang: 'ca',
        urlWilson: 'ca/entrades/passi-express',
        urlGatsby: '/ca/entrades/passi-express',
      },
      { lang: 'fr', urlWilson: 'fr/entradas/pase-express', urlGatsby: '/fr/entradas/pase-express' },
      { lang: 'en', urlWilson: 'en/tickets/express-pass', urlGatsby: '/en/tickets/express-pass' },
    ],

    pasajes_halloween: [
      {
        lang: 'es',
        urlWilson: 'es/entradas/pasajes-halloween',
        urlGatsby: '/entradas/pasajes-halloween',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/entrades/passatges-halloween',
        urlGatsby: '/ca/entrades/passatges-halloween',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/entrees/passages-halloween',
        urlGatsby: '/fr/entrees/passages-halloween',
      },
      {
        lang: 'en',
        urlWilson: 'en/tickets/halloween-passages',
        urlGatsby: '/en/tickets/halloween-passages',
      },
    ],
    hotel_pase_express: [
      { lang: 'es', urlWilson: 'es/hoteles/pase-express', urlGatsby: '/hoteles/pase-express' },
      {
        lang: 'ca',
        urlWilson: 'ca/hotels/passi-express',
        urlGatsby: '/ca/hotels/passi-express',
      },
      { lang: 'fr', urlWilson: 'fr/hotels/pase-express', urlGatsby: '/fr/hotels/pase-express' },
      { lang: 'en', urlWilson: 'en/hotels/express-pass', urlGatsby: '/en/hotels/express-pass' },
    ],
    distributiva_excursiones_julia: [
      {
        lang: 'es',
        urlWilson: 'es/promociones/entradas-transporte',
        urlGatsby: '/promociones/entradas-transporte',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/promotions/entrees-transport',
        urlGatsby: '/fr/promotions/entrees-transport',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/promocions/entrades-transport',
        urlGatsby: '/ca/promocions/entrades-transport',
      },
      {
        lang: 'en',
        urlWilson: 'en/promotions/tickets-transport',
        urlGatsby: '/en/promotions/tickets-transport',
      },
    ],
    servicios_parques: [
      {
        lang: 'es',
        urlWilson: 'es/nosotros/servicios-en-los-parques',
        urlGatsby: '/nosotros/servicios-en-los-parques',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/services-dans-les-parcs',
        urlGatsby: '/fr/services-dans-les-parcs',
      }, // OJO reportar a t2o - url diferente al resto
      {
        lang: 'ca',
        urlWilson: 'ca/nosaltres/serveis-als-parcs',
        urlGatsby: '/ca/nosaltres/serveis-als-parcs',
      },
      {
        lang: 'en',
        urlWilson: 'en/about-us/park-services',
        urlGatsby: '/en/about-us/park-services',
      },
    ],
    como_llegar: [
      {
        lang: 'es',
        urlWilson: 'es/destino/planificar/como-llegar-a-portaventura',
        urlGatsby: '/destino/planificar/como-llegar-a-portaventura',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/destino/planificar/como-llegar-a-portaventura',
        urlGatsby: '/fr/destino/planificar/como-llegar-a-portaventura',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/el-meu-viatge/planifica/com-arribo-a-portaventura',
        urlGatsby: '/ca/el-meu-viatge/planifica/com-arribo-a-portaventura',
      },
      {
        lang: 'en',
        urlWilson: 'en/destination/planning/how-to-get-to-portaventura',
        urlGatsby: '/en/destination/planning/how-to-get-to-portaventura',
      },
    ],
    campings_asociados: [
      {
        lang: 'es',
        urlWilson: 'es/nosotros/hoteles-campings-asociados',
        urlGatsby: '/nosotros/hoteles-campings-asociados',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/qui-sommes-nous/hotels-campings-associes',
        urlGatsby: '/fr/qui-sommes-nous/hotels-campings-associes',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/nosaltres/hotels-campings-associats',
        urlGatsby: '/ca/nosaltres/hotels-campings-associats',
      },
      {
        lang: 'en',
        urlWilson: 'en/about-us/associated-hotels-campings',
        urlGatsby: '/en/about-us/associated-hotels-campings',
      },
    ],
    contacto: [
      { lang: 'es', urlWilson: '-- hardcoded ---', urlGatsby: '/contacto' },
      { lang: 'fr', urlWilson: '-- hardcoded ---', urlGatsby: '/fr/contact' },
      { lang: 'ca', urlWilson: '-- hardcoded ---', urlGatsby: '/ca/contact' },
      { lang: 'en', urlWilson: '-- hardcoded ---', urlGatsby: '/en/contact' },
    ],
    nosotros: [
      { lang: 'es', urlWilson: 'es/nosotros', urlGatsby: '/nosotros' },
      { lang: 'fr', urlWilson: 'fr/qui-sommes-nous', urlGatsby: '/fr/qui-sommes-nous' },
      { lang: 'ca', urlWilson: 'ca/nosaltres', urlGatsby: '/ca/nosaltres' },
      { lang: 'en', urlWilson: 'en/about-us', urlGatsby: '/en/about-us' },
    ],
    nuestro_compromiso: [
      {
        lang: 'es',
        urlWilson: 'es/responsabilidad-corporativa',
        urlGatsby: '/responsabilidad-corporativa',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/responsabilite-corporative',
        urlGatsby: '/fr/responsabilite-corporative',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/responsabilitat-corporativa',
        urlGatsby: '/ca/responsabilitat-corporativa',
      },
      {
        lang: 'en',
        urlWilson: 'en/corporate-responsibility',
        urlGatsby: '/en/corporate-responsibility',
      },
    ],
    medio_ambiente: [
      { lang: 'es', urlWilson: 'es/medio-ambiente', urlGatsby: '/medio-ambiente' },
      { lang: 'fr', urlWilson: 'fr/medio-ambiente', urlGatsby: '/fr/medio-ambiente' },
      { lang: 'ca', urlWilson: 'ca/medio-ambiente', urlGatsby: '/ca/medio-ambiente' },
      { lang: 'en', urlWilson: 'en/medio-ambiente', urlGatsby: '/en/medio-ambiente' },
    ],
    partners: [
      { lang: 'es', urlWilson: 'es/nosotros/partners', urlGatsby: '/nosotros/partners' },
      {
        lang: 'fr',
        urlWilson: 'fr/qui-sommes-nous/partners',
        urlGatsby: '/fr/qui-sommes-nous/partners',
      },
      { lang: 'ca', urlWilson: 'ca/nosaltres/partners', urlGatsby: '/ca/nosaltres/partners' },
      { lang: 'en', urlWilson: 'en/about-us/partners', urlGatsby: '/en/about-us/partners' },
    ],
    reconocimientos: [
      {
        lang: 'es',
        urlWilson: 'es/nosotros/reconocimientos',
        urlGatsby: '/nosotros/reconocimientos',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/qui-sommes-nous/reconnaissances',
        urlGatsby: '/fr/qui-sommes-nous/reconnaissances',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/nosaltres/reconeixements',
        urlGatsby: '/ca/nosaltres/reconeixements',
      },
      { lang: 'en', urlWilson: 'en/about-us/awards', urlGatsby: '/en/about-us/awards' },
    ],
    trabajar_nosotros: [
      {
        lang: 'es',
        urlWilson: 'es/nosotros/trabaja-con-nosotros',
        urlGatsby: '/nosotros/trabaja-con-nosotros',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/qui-sommes-nous/rejoignez-nous',
        urlGatsby: '/fr/qui-sommes-nous/rejoignez-nous',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/nosaltres/treballa-amb-nosaltres',
        urlGatsby: '/ca/nosaltres/treballa-amb-nosaltres',
      },
      { lang: 'en', urlWilson: 'en/about-us/work-with-us', urlGatsby: '/en/about-us/work-with-us' },
    ],
    business: [
      { lang: 'es', urlWilson: 'es/business-events', urlGatsby: '/business-events' },
      { lang: 'fr', urlWilson: 'fr/business-events', urlGatsby: '/fr/business-events' },
      { lang: 'ca', urlWilson: 'ca/business-events', urlGatsby: '/ca/business-events' },
      { lang: 'en', urlWilson: 'en/business-events', urlGatsby: '/en/business-events' },
    ],
    fundacion: [
      { lang: 'es', urlWilson: 'es/fundacion', urlGatsby: '/fundacion' },
      { lang: 'fr', urlWilson: 'fr/fondation', urlGatsby: '/fr/fondation' },
      { lang: 'ca', urlWilson: 'ca/fundacio', urlGatsby: '/ca/fundacio' },
      { lang: 'en', urlWilson: 'en/foundation', urlGatsby: '/en/foundation' },
    ],
    agencia_viajes: [
      { lang: 'es', urlWilson: 'es/agencias-de-viajes', urlGatsby: '/agencias-de-viajes' },
      { lang: 'fr', urlWilson: 'fr/agence-de-voyages', urlGatsby: '/fr/agence-de-voyages' },
      { lang: 'ca', urlWilson: 'ca/agencies-de-viatges', urlGatsby: '/ca/agencies-de-viatges' },
      { lang: 'en', urlWilson: 'en/travel-agencies', urlGatsby: '/en/travel-agencies' },
    ],
    dossier_prensa: [
      { lang: 'es', urlWilson: 'es/dossier-de-prensa', urlGatsby: '/dossier-de-prensa' },
      { lang: 'fr', urlWilson: 'fr/dossier-de-presse', urlGatsby: '/fr/dossier-de-presse' },
      { lang: 'ca', urlWilson: 'ca/dossier-de-premsa', urlGatsby: '/ca/dossier-de-premsa' },
      { lang: 'en', urlWilson: 'en/press-kit', urlGatsby: '/en/press-kit' },
    ],
    gabinete_prensa: [
      {
        lang: 'es',
        urlWilson: 'es/mapa-web/contacto-de-prensa',
        urlGatsby: '/mapa-web/contacto-de-prensa',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/carte-web/contacts-presse',
        urlGatsby: '/fr/carte-web/contacts-presse',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/mapa-web/contacte-de-premsa',
        urlGatsby: '/ca/mapa-web/contacte-de-premsa',
      },
      {
        lang: 'en',
        urlWilson: 'en/site-map/press-contact',
        urlGatsby: '/en/site-map/press-contact',
      },
    ],
    material_grafico: [
      {
        lang: 'es',
        urlWilson: 'es/mapa-web/material-grafico',
        urlGatsby: '/mapa-web/material-grafico',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/web-map/materiel-graphique',
        urlGatsby: '/fr/web-map/materiel-graphique',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/mapa-web/material-grafic',
        urlGatsby: '/ca/mapa-web/material-grafic',
      },
      { lang: 'en', urlWilson: 'en/site-map/visuals', urlGatsby: '/en/site-map/visuals' },
    ],
    condiciones_generales: [
      { lang: 'es', urlWilson: 'es/condiciones-generales', urlGatsby: '/condiciones-generales' },
      { lang: 'fr', urlWilson: 'fr/condiciones-generales', urlGatsby: '/fr/condiciones-generales' },
      { lang: 'ca', urlWilson: 'ca/condiciones-generales', urlGatsby: '/ca/condiciones-generales' },
      { lang: 'en', urlWilson: 'en/condiciones-generales', urlGatsby: '/en/condiciones-generales' },
    ],
    politica_privacidad: [
      { lang: 'es', urlWilson: 'es/politica-privacidad', urlGatsby: '/politica-privacidad' },
      {
        lang: 'fr',
        urlWilson: 'fr/politique-confidentialite',
        urlGatsby: '/fr/politique-confidentialite',
      },
      { lang: 'ca', urlWilson: 'ca/politica-privacitat', urlGatsby: '/ca/politica-privacitat' },
      { lang: 'en', urlWilson: 'en/privacy-policy', urlGatsby: '/en/privacy-policy' },
    ],
    aviso_legal: [
      { lang: 'es', urlWilson: 'es/aviso-legal', urlGatsby: '/aviso-legal' },
      { lang: 'fr', urlWilson: 'fr/mentions-legales', urlGatsby: '/fr/mentions-legales' },
      { lang: 'ca', urlWilson: 'ca/avis-legal', urlGatsby: '/ca/avis-legal' },
      { lang: 'en', urlWilson: 'en/legal-notice', urlGatsby: '/en/legal-notice' },
    ],
    condiciones_compra: [
      {
        lang: 'es',
        urlWilson: 'es/condiciones-compra-pases',
        urlGatsby: '/condiciones-compra-pases',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/condiciones-compra-pases',
        urlGatsby: '/fr/condiciones-compra-pases',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/condiciones-compra-pases',
        urlGatsby: '/ca/condiciones-compra-pases',
      },
      {
        lang: 'en',
        urlWilson: 'en/condiciones-compra-pases',
        urlGatsby: '/en/condiciones-compra-pases',
      },
    ],
    traslados: [
      { lang: 'es', urlWilson: 'es/traslados-portaventura', urlGatsby: '/traslados-portaventura' },
      {
        lang: 'fr',
        urlWilson: 'fr/transfert-portaventura',
        urlGatsby: '/fr/transfert-portaventura',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/trasllats-portaventura',
        urlGatsby: '/ca/trasllats-portaventura',
      },
      {
        lang: 'en',
        urlWilson: 'en/transfers-portaventura',
        urlGatsby: '/en/transfers-portaventura',
      },
    ],
    intolerancias_alergias: [
      {
        lang: 'es',
        urlWilson: 'es/gastronomia/intolerancias-y-alergias',
        urlGatsby: '/gastronomia/intolerancias-y-alergias',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/gastronomie/intolerances-et-allergies',
        urlGatsby: '/fr/gastronomie/intolerances-et-allergies',
      },
      {
        lang: 'ca',
        urlWilson: 'ca/gastronomia/intolerancies-i-allergies',
        urlGatsby: '/ca/gastronomia/intolerancies-i-allergies',
      },
      {
        lang: 'en',
        urlWilson: 'en/gastronomy/intolerances-and-allergies',
        urlGatsby: '/en/gastronomy/intolerances-and-allergies',
      },
    ],
    plan_your_stay: [
      { lang: 'es', urlWilson: 'es/planifica-tu-viaje', urlGatsby: '/planifica-tu-viaje' },
      {
        lang: 'fr',
        urlWilson: '/fr/planifiez-votre-sejour',
        urlGatsby: '/fr/planifiez-votre-sejour',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/planifica-el-teu-viatge',
        urlGatsby: '/ca/planifica-el-teu-viatge',
      },
      { lang: 'en', urlWilson: '/en/plan-your-trip', urlGatsby: '/en/plan-your-trip' },
    ],
    promotion_halloween: [
      {
        lang: 'es',
        urlWilson: 'es/halloween/ofertas-entradas',
        urlGatsby: '/halloween/ofertas-entradas',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/halloween/offres-entrees',
        urlGatsby: '/fr/halloween/offres-entrees',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/halloween/ofertes-entrades',
        urlGatsby: '/ca/halloween/ofertes-entrades',
      },
      {
        lang: 'en',
        urlWilson: '/en/halloween/offers-tickets',
        urlGatsby: '/en/halloween/offers-tickets',
      },
    ],
    shows_halloween: [
      { lang: 'es', urlWilson: 'es/halloween/espectaculos', urlGatsby: '/halloween/espectaculos' },
      { lang: 'fr', urlWilson: '/fr/halloween/spectacles', urlGatsby: '/fr/halloween/spectacles' },
      {
        lang: 'ca',
        urlWilson: '/ca/halloween/espectacles',
        urlGatsby: '/ca/halloween/espectacles',
      },
      { lang: 'en', urlWilson: '/en/halloween/shows', urlGatsby: '/en/halloween/shows' },
    ],
    shows_navidad: [
      { lang: 'es', urlWilson: 'es/navidad/espectaculos', urlGatsby: '/navidad/espectaculos' },
      { lang: 'fr', urlWilson: '/fr/noel/spectacles', urlGatsby: '/fr/noel/spectacles' },
      { lang: 'ca', urlWilson: '/ca/nadal/espectacles', urlGatsby: '/ca/nadal/espectacles' },
      { lang: 'en', urlWilson: '/en/christmas/shows', urlGatsby: '/en/christmas/shows' },
    ],
    material_grafico_halloween: [
      { lang: 'es', urlWilson: '/es/halloween/galeria', urlGatsby: '/halloween/galeria' },
      { lang: 'fr', urlWilson: '/fr/halloween/galerie', urlGatsby: '/fr/halloween/galerie' },
      { lang: 'ca', urlWilson: '/ca/halloween/galeria', urlGatsby: '/ca/halloween/galeria' },
      { lang: 'en', urlWilson: '/en/halloween/gallery', urlGatsby: '/en/halloween/gallery' },
    ],
    // AUTOPAGES
    entradas: [
      { lang: 'es', urlWilson: '/es/entradas/', urlGatsby: '/entradas' },
      { lang: 'fr', urlWilson: '/fr/entrees', urlGatsby: '/fr/entrees' },
      { lang: 'ca', urlWilson: '/ca/entrades', urlGatsby: '/ca/entrades' },
      { lang: 'en', urlWilson: '/en/tickets', urlGatsby: '/en/tickets' },
    ],
    ficha_promo_vacaciones_verano: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/vacaciones-verano',
        urlGatsby: '/promociones/vacaciones-verano',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/vacances-ete',
        urlGatsby: '/fr/promotions/vacances-ete',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/vacances-estiu',
        urlGatsby: '/ca/promocions/vacances-estiu',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/summer-holidays',
        urlGatsby: '/en/promotions/summer-holidays',
      },
    ],
    ficha_promo_vacaciones: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/vacaciones',
        urlGatsby: '/promociones/vacaciones',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/vacances',
        urlGatsby: '/fr/promotions/vacances',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/vacances',
        urlGatsby: '/ca/promocions/vacances',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/holidays',
        urlGatsby: '/en/promotions/holidays',
      },
    ],
    tarjeta_regalo: [
      {
        lang: 'es',
        urlWilson: 'es/indoor-experience/tarjeta-regalo',
        urlGatsby: '/indoor-experience/tarjeta-regalo',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/indoor-experience/carte-cadeau',
        urlGatsby: '/fr/indoor-experience/carte-cadeau',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/indoor-experience/targeta-regal',
        urlGatsby: '/ca/indoor-experience/targeta-regal',
      },
      {
        lang: 'en',
        urlWilson: '/en/indoor-experience/gift-card',
        urlGatsby: '/en/indoor-experience/gift-card',
      },
    ],
    ficha_promo_arbol: [
      {
        lang: 'es',
        urlWilson: 'es/indoor-experience/amigo-en-el-arbol',
        urlGatsby: '/indoor-experience/amigo-en-el-arbol',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/indoor-experience/ami-dans-le-sapin',
        urlGatsby: '/fr/indoor-experience/ami-dans-le-sapin',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/indoor-experience/amic-arbre-de-nadal',
        urlGatsby: '/ca/indoor-experience/amic-arbre-de-nadal',
      },
      {
        lang: 'en',
        urlWilson: '/en/indoor-experience/friend-in-the-tree',
        urlGatsby: '/en/indoor-experience/friend-in-the-tree',
      },
    ],
    mejores_sitios_para_comer: [
      {
        lang: 'es',
        urlWilson: 'es/restaurantes/los-mejores-sitios-para-comer',
        urlGatsby: '/restaurantes/los-mejores-sitios-para-comer',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/restaurants/les-meilleurs-etablissements-ou-manger',
        urlGatsby: '/fr/restaurants/les-meilleurs-etablissements-ou-manger',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/restaurants/els-millors-llocs-per-menjar',
        urlGatsby: '/ca/restaurants/els-millors-llocs-per-menjar',
      },
      {
        lang: 'en',
        urlWilson: '/en/restaurants/the-best-places-to-eat',
        urlGatsby: '/en/restaurants/the-best-places-to-eat',
      },
    ],
    mejores_atracciones: [
      {
        lang: 'es',
        urlWilson: 'es/atracciones/mejores-atracciones',
        urlGatsby: '/atracciones/mejores-atracciones',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/attractions/meilleures-attractions',
        urlGatsby: '/fr/attractions/meilleures-attractions',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/atraccions/les-millors-atraccions',
        urlGatsby: '/ca/atraccions/les-millors-atraccions',
      },
      {
        lang: 'en',
        urlWilson: '/en/rides/best-attractions',
        urlGatsby: '/en/rides/best-attractions',
      },
    ],
    mesa_navidad: [
      {
        lang: 'es',
        urlWilson: 'es/indoor-experience/mesa-navidad',
        urlGatsby: '/indoor-experience/mesa-navidad',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/indoor-experience/table-noel',
        urlGatsby: '/fr/indoor-experience/table-noel',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/indoor-experience/taula-de-nadal',
        urlGatsby: '/ca/indoor-experience/taula-de-nadal',
      },
      {
        lang: 'en',
        urlWilson: '/en/indoor-experience/christmas-dinner-table',
        urlGatsby: '/en/indoor-experience/christmas-dinner-table',
      },
    ],
    papel_de_regalo: [
      {
        lang: 'es',
        urlWilson: 'es/indoor-experience/papel-de-regalo',
        urlGatsby: '/indoor-experience/papel-de-regalo',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/indoor-experience/papier-cadeau',
        urlGatsby: '/fr/indoor-experience/papier-cadeau',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/indoor-experience/paper-de-regal',
        urlGatsby: '/ca/indoor-experience/paper-de-regal',
      },
      {
        lang: 'en',
        urlWilson: '/en/indoor-experience/gift-wrap',
        urlGatsby: '/en/indoor-experience/gift-wrap',
      },
    ],
    requisitos_altura: [
      {
        lang: 'es',
        urlWilson: 'es/atracciones/requisitos',
        urlGatsby: '/atracciones/requisitos',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/attractions/requirements',
        urlGatsby: '/fr/attractions/requirements',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/atraccions/requisits',
        urlGatsby: '/ca/atraccions/requisits',
      },
      {
        lang: 'en',
        urlWilson: '/en/rides/requirements',
        urlGatsby: '/en/rides/requirements',
      },
    ],
    requisitos_altura_fl: [
      {
        lang: 'es',
        urlWilson: 'es/ferrari-land/atracciones/requisitos',
        urlGatsby: '/ferrari-land/atracciones/requisitos',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/ferrari-land/attractions/requises',
        urlGatsby: '/fr/ferrari-land/attractions/requises',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/ferrari-land/atraccions/requisits',
        urlGatsby: '/ca/ferrari-land/atraccions/requisits',
      },
      {
        lang: 'en',
        urlWilson: '/en/ferrari-land/rides/requirements',
        urlGatsby: '/en/ferrari-land/rides/requirements',
      },
    ],
    requisitos_altura_cap: [
      {
        lang: 'es',
        urlWilson: 'es/aquatic-park/atracciones/requisitos',
        urlGatsby: '/aquatic-park/atracciones/requisitos',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/aquatic-park/attractions/requirements',
        urlGatsby: '/fr/aquatic-park/attractions/requirements',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/aquatic-park/atraccions/requisits',
        urlGatsby: '/ca/aquatic-park/atraccions/requisits',
      },
      {
        lang: 'en',
        urlWilson: '/en/aquatic-park/rides/requirements',
        urlGatsby: '/en/aquatic-park/rides/requirements',
      },
    ],
    semana_santa: [
      {
        lang: 'es',
        urlWilson: 'es/semana-santa',
        urlGatsby: '/semana-santa',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/paques',
        urlGatsby: '/fr/paques',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/setmana-santa',
        urlGatsby: '/ca/setmana-santa',
      },
      {
        lang: 'en',
        urlWilson: '/en/easter',
        urlGatsby: '/en/easter',
      },
    ],
    condiciones_hoteles: [
      {
        lang: 'es',
        urlWilson: 'es/condiciones-hoteles',
        urlGatsby: '/condiciones-hoteles',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/conditions-hotels',
        urlGatsby: '/fr/conditions-hotels',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/condicions-hotels',
        urlGatsby: '/ca/condicions-hotels',
      },
      {
        lang: 'en',
        urlWilson: '/en/hotel-conditions',
        urlGatsby: '/en/hotel-conditions',
      },
    ],
    delion_experience: [
      {
        lang: 'es',
        urlWilson: 'es/delion-experience',
        urlGatsby: '/delion-experience',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/delion-experience',
        urlGatsby: '/fr/delion-experience',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/delion-experience',
        urlGatsby: '/ca/delion-experience',
      },
      {
        lang: 'en',
        urlWilson: '/en/delion-experience',
        urlGatsby: '/en/delion-experience',
      },
    ],
    ficha_club_oferta_pase_anual: [
      {
        lang: 'es',
        urlWilson: 'es/promociones/club-oferta-pase-anual',
        urlGatsby: '/promociones/club-oferta-pase-anual',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/club-offre-pase-anual',
        urlGatsby: '/fr/promotions/club-offre-pase-anual',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/club-oferta-passi-anual',
        urlGatsby: '/ca/promocions/club-oferta-passi-anual',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/club-offer-annual-pass',
        urlGatsby: '/en/promotions/club-offer-annual-pass',
      },
    ],
    ficha_promo_descuento_entradas: [
      {
        lang: 'es',
        urlWilson: 'es/promociones/descuento-entradas',
        urlGatsby: '/promociones/descuento-entradas',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/reduction-entrees',
        urlGatsby: '/fr/promotions/reduction-entrees',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/descompte-entrades',
        urlGatsby: '/ca/promocions/descompte-entrades',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/discount-tickets',
        urlGatsby: '/en/promotions/discount-tickets',
      },
    ],
    entradas_fl: [
      {
        lang: 'es',
        urlWilson: '/es/entradas/ferrari-land',
        urlGatsby: '/entradas/ferrari-land',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/entrees/ferrari-land',
        urlGatsby: '/fr/entrees/ferrari-land',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/entrades/ferrari-land',
        urlGatsby: '/ca/entrades/ferrari-land',
      },
      {
        lang: 'en',
        urlWilson: '/en/tickets/ferrari-land',
        urlGatsby: '/en/tickets/ferrari-land',
      },
    ],
    entradas_cap: [
      {
        lang: 'es',
        urlWilson: '/es/entradas/caribe-aquatic-park',
        urlGatsby: '/entradas/caribe-aquatic-park',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/entrees/caribe-aquatic-park',
        urlGatsby: '/fr/entrees/caribe-aquatic-park',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/entrades/caribe-aquatic-park',
        urlGatsby: '/ca/entrades/caribe-aquatic-park',
      },
      {
        lang: 'en',
        urlWilson: '/en/tickets/caribe-aquatic-park',
        urlGatsby: '/en/tickets/caribe-aquatic-park',
      },
    ],
    entradas_bs: [
      {
        lang: 'es',
        urlWilson: '/es/entradas/best-sellers',
        urlGatsby: '/entradas/best-sellers',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/entrees/best-sellers',
        urlGatsby: '/fr/entrees/best-sellers',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/entrades/best-sellers',
        urlGatsby: '/ca/entrades/best-sellers',
      },
      {
        lang: 'en',
        urlWilson: '/en/tickets/best-sellers',
        urlGatsby: '/en/tickets/best-sellers',
      },
    ],
    distr_promociones: [
      {
        lang: 'es',
        urlWilson: '/es/promociones',
        urlGatsby: '/promociones',
      },
      {
        lang: 'fr',
        urlWilson: 'fr/promotions',
        urlGatsby: '/fr/promotions',
      },
      { lang: 'ca', urlWilson: 'ca/promocions', urlGatsby: '/ca/promocions' },
      { lang: 'en', urlWilson: 'en/promotions', urlGatsby: '/en/promotions' },
    ],
    ficha_promo_hotel_entrada_halloween: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/hotel-entrada-halloween',
        urlGatsby: '/promociones/hotel-entrada-halloween',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/hotel-entree-halloween',
        urlGatsby: '/fr/promotions/hotel-entree-halloween',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/hotel-entrada-halloween',
        urlGatsby: '/ca/promocions/hotel-entrada-halloween',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/hotel-admission-halloween',
        urlGatsby: '/en/promotions/hotel-admission-halloween',
      },
    ],
    ficha_promo_entradas_2_dias: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/portaventura-park-entradas-2-dias',
        urlGatsby: '/promociones/portaventura-park-entradas-2-dias',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/portaventura-park-entrees-2-jours',
        urlGatsby: '/fr/promotions/portaventura-park-entrees-2-jours',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/portaventura-park-entrades-2-dies',
        urlGatsby: '/ca/promocions/portaventura-park-entrades-2-dies',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/portaventura-park-tickets-2-days',
        urlGatsby: '/en/promotions/portaventura-park-tickets-2-days',
      },
    ],
    ficha_promo_entrada_hotel: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/entrada-hotel',
        urlGatsby: '/promociones/entrada-hotel',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/entree-hotel',
        urlGatsby: '/fr/promotions/entree-hotel',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/entrada-hotel',
        urlGatsby: '/ca/promocions/entrada-hotel',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/tickets-hotel',
        urlGatsby: '/en/promotions/tickets-hotel',
      },
    ],
    ficha_promo_tienda_online: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/tienda-online',
        urlGatsby: '/promociones/tienda-online',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/boutique-en-ligne',
        urlGatsby: '/fr/promotions/boutique-en-ligne',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/botiga-en-linia',
        urlGatsby: '/ca/promocions/botiga-en-linia',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/online-shop',
        urlGatsby: '/en/promotions/online-shop',
      },
    ],
    ficha_promo_promocion_otono: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/promocion-otono',
        urlGatsby: '/promociones/promocion-otono',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/promotion-automne',
        urlGatsby: '/fr/promotions/promotion-automne',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/promocio-tardor',
        urlGatsby: '/ca/promocions/promocio-tardor',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/promotion-autumn',
        urlGatsby: '/en/promotions/promotion-autumn',
      },
    ],
    ficha_promo_hotel: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/hotel',
        urlGatsby: '/promociones/hotel',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/hotel',
        urlGatsby: '/fr/promotions/hotel',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/hotel',
        urlGatsby: '/ca/promocions/hotel',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/hotel',
        urlGatsby: '/en/promotions/hotel',
      },
    ],
    ficha_promo_black_friday: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/black-friday',
        urlGatsby: '/promociones/black-friday',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/black-friday',
        urlGatsby: '/fr/promotions/black-friday',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/black-friday',
        urlGatsby: '/ca/promocions/black-friday',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/black-friday',
        urlGatsby: '/en/promotions/black-friday',
      },
    ],
    ficha_promo_cyber_monday: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/cyber-monday',
        urlGatsby: '/promociones/cyber-monday',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/cyber-monday',
        urlGatsby: '/fr/promotions/cyber-monday',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/cyber-monday',
        urlGatsby: '/ca/promocions/cyber-monday',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/cyber-monday',
        urlGatsby: '/en/promotions/cyber-monday',
      },
    ],

    ficha_promo_entradas: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/entradas',
        urlGatsby: '/promociones/entradas',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/entrees',
        urlGatsby: '/fr/promotions/entrees',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/entrades',
        urlGatsby: '/ca/promocions/entrades',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/tickets',
        urlGatsby: '/en/promotions/tickets',
      },
    ],

    ficha_promo_yatetoca: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/yatetoca',
        urlGatsby: '/promociones/yatetoca',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/atontour',
        urlGatsby: '/fr/promotions/atontour',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/jaettoca',
        urlGatsby: '/ca/promocions/jaettoca',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/itsyourturn',
        urlGatsby: '/en/promotions/itsyourturn',
      },
    ],
    ficha_promo_halloween_roulette: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/hotel-roulette',
        urlGatsby: '/promociones/hotel-roulette',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/hotel-roulette',
        urlGatsby: '/fr/promotions/hotel-roulette',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/hotel-roulette',
        urlGatsby: '/ca/promocions/hotel-roulette',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/hotel-roulette',
        urlGatsby: '/en/promotions/hotel-roulette',
      },
    ],
    ficha_promo_balloon_world_cup: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/balloon-world-cup',
        urlGatsby: '/promociones/balloon-world-cup',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/balloon-world-cup',
        urlGatsby: '/fr/promotions/balloon-world-cup',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/balloon-world-cup',
        urlGatsby: '/ca/promocions/balloon-world-cup',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/balloon-world-cup',
        urlGatsby: '/en/promotions/balloon-world-cup',
      },
    ],
    veganos: [
      {
        lang: 'es',
        urlWilson: '/es/comida-vegana-vegetariana',
        urlGatsby: '/comida-vegana-vegetariana',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/cuisine-vegane-vegetarienne',
        urlGatsby: '/fr/cuisine-vegane-vegetarienne',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/menjar-vega-vegetaria',
        urlGatsby: '/ca/menjar-vega-vegetaria',
      },
      {
        lang: 'en',
        urlWilson: '/en/food-vegan-vegetarian',
        urlGatsby: '/en/food-vegan-vegetarian',
      },
    ],
    ficha_promo_halloween_passport: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/halloween-passport',
        urlGatsby: '/promociones/halloween-passport',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/halloween-passport',
        urlGatsby: '/fr/promotions/halloween-passport',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/halloween-passport',
        urlGatsby: '/ca/promocions/halloween-passport',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/halloween-passport',
        urlGatsby: '/en/promotions/halloween-passport',
      },
    ],
    ficha_promo_halloween_la_muerte_viva: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/halloween-la-muerte-viva',
        urlGatsby: '/promociones/halloween-la-muerte-viva',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/halloween-la-muerte-viva',
        urlGatsby: '/fr/promotions/halloween-la-muerte-viva',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/halloween-la-muerte-viva',
        urlGatsby: '/ca/promocions/halloween-la-muerte-viva',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/halloween-la-muerte-viva',
        urlGatsby: '/en/promotions/halloween-la-muerte-viva',
      },
    ],
    ficha_promo_hotel_portaventura_navidad: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/hoteles-portaventura-navidad-nochevieja',
        urlGatsby: '/promociones/hoteles-portaventura-navidad-nochevieja',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/hotels-portaventura-noel-reveillon-saint-sylvestre',
        urlGatsby: '/fr/promotions/hotels-portaventura-noel-reveillon-saint-sylvestre',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/hotels-portaventura-nadal-cap-any',
        urlGatsby: '/ca/promocions/hotels-portaventura-nadal-cap-any',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/hotels-portaventura-christmas-newyears-eve',
        urlGatsby: '/en/promotions/hotels-portaventura-christmas-newyears-eve',
      },
    ],
    hotel_nochebuena: [
      {
        lang: 'es',
        urlWilson: '/es/promociones/hoteles-portaventura-navidad-nochebuena',
        urlGatsby: '/promociones/hoteles-portaventura-navidad-nochebuena',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/hotels-portaventura-reveillon-noel',
        urlGatsby: '/fr/promotions/hotels-portaventura-reveillon-noel',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/hotels-portaventura-nadal-nit-nadal',
        urlGatsby: '/ca/promocions/hotels-portaventura-nadal-nit-nadal',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/hotels-portaventura-christmas-christmas-eve',
        urlGatsby: '/en/promotions/hotels-portaventura-christmas-christmas-eve',
      },
    ],
    // carga_nodos: [{ lang: 'es', urlWilson: 'es/carga-nodos', urlGatsby: '/carga-nodos' }],

    material_grafico_navidad: [
      { lang: 'es', urlWilson: '/es/navidad/galeria', urlGatsby: '/navidad/galeria' },
      { lang: 'fr', urlWilson: '/fr/noel/galerie', urlGatsby: '/fr/noel/galerie' },
      { lang: 'ca', urlWilson: '/ca/nadal/galeria', urlGatsby: '/ca/nadal/galeria' },
      { lang: 'en', urlWilson: '/en/christmas/gallery', urlGatsby: '/en/christmas/gallery' },
    ],
    promotion_navidad: [
      {
        lang: 'es',
        urlWilson: 'es/navidad/ofertas-entradas',
        urlGatsby: '/navidad/ofertas-entradas',
      },
      { lang: 'fr', urlWilson: '/fr/noel/offres-entrees', urlGatsby: '/fr/noel/offres-entrees' },
      {
        lang: 'ca',
        urlWilson: '/ca/nadal/ofertes-entrades',
        urlGatsby: '/ca/nadal/ofertes-entrades',
      },
      {
        lang: 'en',
        urlWilson: '/en/christmas/offers-tickets',
        urlGatsby: '/en/christmas/offers-tickets',
      },
    ],
    promotion_boxing_day: [
      {
        lang: 'en',
        urlWilson: '/en/promotions/boxing-day',
        urlGatsby: '/en/promotions/boxing-day',
      },
    ],
    navidad: [
      { lang: 'es', urlWilson: 'es/navidad', urlGatsby: '/navidad' },
      { lang: 'fr', urlWilson: '/fr/noel', urlGatsby: '/fr/noel' },
      { lang: 'ca', urlWilson: '/ca/nadal', urlGatsby: '/ca/nadal' },
      { lang: 'en', urlWilson: '/en/christmas', urlGatsby: '/en/christmas' },
    ],
    ficha_promo_cena_navidad_gratuita: [
      {
        lang: 'es',
        urlWilson: 'es/promociones/oferta-navidad-entrada-hotel-cena',
        urlGatsby: '/promociones/oferta-navidad-entrada-hotel-cena',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/offre-noel-entree-hotel-diner',
        urlGatsby: '/fr/promotions/offre-noel-entree-hotel-diner',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/oferta-nadal-entrada-hotel-sopar',
        urlGatsby: '/ca/promocions/oferta-nadal-entrada-hotel-sopar',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/offer-christmas-ticket-hotel-dinner',
        urlGatsby: '/en/promotions/offer-christmas-ticket-hotel-dinner',
      },
    ],
    ficha_promo_tu_regalo_de_navidad: [
      {
        lang: 'es',
        urlWilson: 'es/promociones/descuento-navidad-entrada-hotel',
        urlGatsby: '/promociones/descuento-navidad-entrada-hotel',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/offre-noel-entree-hotel',
        urlGatsby: '/fr/promotions/offre-noel-entree-hotel',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/descompte-nadal-entrada-hotel',
        urlGatsby: '/ca/promocions/descompte-nadal-entrada-hotel',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/discount-christmas-ticket-hotel',
        urlGatsby: '/en/promotions/discount-christmas-ticket-hotel',
      },
    ],
    ficha_action_fitur: [
      {
        lang: 'es',
        urlWilson: 'es/ferias2022',
        urlGatsby: '/ferias2022',
      },
    ],
    ficha_promo_tickets_hotel_discount: [
      {
        lang: 'es',
        urlWilson: 'es/promociones/entradas-hotel-descuento',
        urlGatsby: '/promociones/entradas-hotel-descuento',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/entrees-hotel-reduction',
        urlGatsby: '/fr/promotions/entrees-hotel-reduction',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/entrades-hotel-descompte',
        urlGatsby: '/ca/promocions/entrades-hotel-descompte',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/tickets-hotel-discount',
        urlGatsby: '/en/promotions/tickets-hotel-discount',
      },
    ],
    ficha_promo_grupos_vacacionales: [
      {
        lang: 'es',
        urlWilson: 'es/promociones/grupos-vacacionales',
        urlGatsby: '/promociones/grupos-vacacionales',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/groupes-vacances',
        urlGatsby: '/fr/promotions/groupes-vacances',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/grups-de-vacances',
        urlGatsby: '/ca/promocions/grups-de-vacances',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/holiday-groups',
        urlGatsby: '/en/promotions/holiday-groups',
      },
    ],
    ficha_promo_oferta_aniversario: [
      {
        lang: 'es',
        urlWilson: 'es/promociones/oferta-aniversario',
        urlGatsby: '/promociones/oferta-aniversario',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/promotions/offre-french-days',
        urlGatsby: '/fr/promotions/offre-french-days',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/promocions/oferta-aniversari',
        urlGatsby: '/ca/promocions/oferta-aniversari',
      },
      {
        lang: 'en',
        urlWilson: '/en/promotions/anniversary-offer',
        urlGatsby: '/en/promotions/anniversary-offer',
      },
    ],
    app: [
      {
        lang: 'es',
        urlWilson: '/es/app',
        urlGatsby: '/app',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/app',
        urlGatsby: '/fr/app',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/app',
        urlGatsby: '/ca/app',
      },
      {
        lang: 'en',
        urlWilson: '/en/app',
        urlGatsby: '/en/app',
      },
    ],
    checkin: [
      {
        lang: 'es',
        urlWilson: '/es/hoteles/check-in-online',
        urlGatsby: '/hoteles/check-in-online',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/hotels/check-in-online',
        urlGatsby: '/fr/hotels/check-in-online',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/hotels/check-in-en-linia',
        urlGatsby: '/ca/hotels/check-in-en-linia',
      },
      {
        lang: 'en',
        urlWilson: '/en/hotels/online-check-in',
        urlGatsby: '/en/hotels/online-check-in',
      },
    ],
    novedades: [
      { lang: 'es', urlWilson: '/es/novedades-2020', urlGatsby: '/novedades-2020' },
      { lang: 'fr', urlWilson: '/fr/nouveautes-2020', urlGatsby: '/fr/nouveautes-2020' },
      { lang: 'ca', urlWilson: '/ca/novetats-2020', urlGatsby: '/ca/novetats-2020' },
      { lang: 'en', urlWilson: '/en/news-2020', urlGatsby: '/en/news-2020' },
    ],
    easteregg: [
      {
        lang: 'es',
        urlWilson: '/es/novedades-2020/easter-celebration',
        urlGatsby: '/novedades-2020/easter-celebration',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/nouveautes-2020/easter-celebration',
        urlGatsby: '/fr/nouveautes-2020/easter-celebration',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/novetats-2020/easter-celebration',
        urlGatsby: '/ca/novetats-2020/easter-celebration',
      },
      {
        lang: 'en',
        urlWilson: '/en/news-2020/easter-celebration',
        urlGatsby: '/en/news-2020/easter-celebration',
      },
    ],
    distributiva_actividades: [
      {
        lang: 'es',
        urlWilson: '/es/indoor-experience',
        urlGatsby: '/indoor-experience',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/indoor-experience',
        urlGatsby: '/fr/indoor-experience',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/indoor-experience',
        urlGatsby: '/ca/indoor-experience',
      },
      {
        lang: 'en',
        urlWilson: '/en/indoor-experience',
        urlGatsby: '/en/indoor-experience',
      },
    ],
    indoor: [
      {
        lang: 'es',
        urlWilson: '/es/descubrir-indoor-pass-experience',
        urlGatsby: '/descubrir-indoor-pass-experience',
      },
      {
        lang: 'fr',
        urlWilson: '/fr/decouvrez-indoor-pass-experience',
        urlGatsby: '/fr/decouvrez-indoor-pass-experience',
      },
      {
        lang: 'ca',
        urlWilson: '/ca/descobrir-indoor-pass-experience',
        urlGatsby: '/ca/descobrir-indoor-pass-experience',
      },
      {
        lang: 'en',
        urlWilson: '/en/discover-indoor-pass-experience',
        urlGatsby: '/en/discover-indoor-pass-experience',
      },
    ],
  },

  templates: {
    ficha_parques: {
      urls: [
        { lang: 'es', urlWilson: '/es/{park}', urlGatsby: '/{park}' },
        { lang: 'fr', urlWilson: '/fr/{park}', urlGatsby: '/fr/{park}' },
        { lang: 'ca', urlWilson: '/ca/{park}', urlGatsby: '/ca/{park}' },
        { lang: 'en', urlWilson: '/en/{park}', urlGatsby: '/en/{park}' },
      ],
      options: {
        park: ['portaventura', 'ferrari-land', 'aquatic-park', 'halloween'],
      },
    },
    preguntas_frecuentes: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/preguntas-frecuentes/{fq}',
          urlGatsby: '/preguntas-frecuentes/{fq}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/preguntas-frecuentes/{fq}',
          urlGatsby: '/fr/preguntas-frecuentes/{fq}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/preguntas-frecuentes/{fq}',
          urlGatsby: '/ca/preguntas-frecuentes/{fq}',
        },
        {
          lang: 'en',
          urlWilson: '/en/preguntas-frecuentes/{fq}',
          urlGatsby: '/en/preguntas-frecuentes/{fq}',
        },
      ],
      options: {
        fq: [
          '',
          'pase-express',
          'aquatic-park',
          'entradas-a-los-parques',
          'hoteles-de-portaventura',
          'pases-anuales',
          'oferta-de-restauracion',
        ],
      },
      exceptions: [
        {
          lang: 'fr',
          fq: 'pases-anuales',
          urlWilson: '/fr/preguntas-frecuentes/pass-annuels',
          urlGatsby: '/fr/preguntas-frecuentes/pass-annuels',
        },
        {
          lang: 'ca',
          fq: 'pases-anuales',
          urlWilson: '/ca/preguntas-frecuentes/passis-anuals',
          urlGatsby: '/ca/preguntas-frecuentes/passis-anuals',
        },
        {
          lang: 'en',
          fq: 'pases-anuales',
          urlWilson: '/en/preguntas-frecuentes/annual-passes',
          urlGatsby: '/en/preguntas-frecuentes/annual-passes',
        },
      ],
    },
    ficha_habitacion: {
      // disable with hotels
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/hoteles/{hotelname}/habitaciones/{room}',
          urlGatsby: '/hoteles/{hotelname}/habitaciones/{room}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/hotels/{hotelname}/chambres/{room}',
          urlGatsby: '/fr/hotels/{hotelname}/chambres/{room}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/hotels/{hotelname}/habitacions/{room}',
          urlGatsby: '/ca/hotels/{hotelname}/habitacions/{room}',
        },
        {
          lang: 'en',
          urlWilson: '/en/hotels/{hotelname}/rooms/{room}',
          urlGatsby: '/en/hotels/{hotelname}/rooms/{room}',
        },
      ],
      options: {
        hotelname: [
          'mansion-de-lucy',
          'gold-river',
          'port-aventura',
          'colorado-creek',
          'caribe',
          'el-paso',
        ],
        room: [
          'suite-lucy',
          'deluxe-superior-lucy',
          'standard',
          'callaghan',
          'cabanas',
          'junior-suite',
          'suite',
          'deluxe',
          'deluxe-club-san-juan',
          'deluxe-woody',
        ],
      },
      valid_combinations: [
        [0, 0],
        [0, 1],
        [1, 2],
        [1, 3],
        [2, 2],
        [2, 5],
        [2, 6],
        [3, 4],
        [3, 7],
        [4, 2],
        [4, 8],
        [5, 2],
        [5, 9],
      ],
      exceptions: [
        {
          lang: 'ca',
          hotelname: 'gold-river',
          room: 'river-bungalows',
          urlWilson: '/ca/hotels/gold-river/rooms/river-bungalows',
          urlGatsby: '/ca/hotels/gold-river/rooms/river-bungalows',
        },
      ],
    },

    ficha_hotel_restaurante: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/hoteles/{hotelname}/restaurantes/{restaurant}',
          urlGatsby: '/hoteles/{hotelname}/restaurantes/{restaurant}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/hotels/{hotelname}/restaurants/{restaurant}',
          urlGatsby: '/fr/hotels/{hotelname}/restaurants/{restaurant}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/hotels/{hotelname}/restaurants/{restaurant}',
          urlGatsby: '/ca/hotels/{hotelname}/restaurants/{restaurant}',
        },
        {
          lang: 'en',
          urlWilson: '/en/hotels/{hotelname}/restaurants/{restaurant}',
          urlGatsby: '/en/hotels/{hotelname}/restaurants/{restaurant}',
        },
      ],
      options: {
        hotelname: [
          'caribe',
          'el-paso',
          'gold-river',
          'mansion-de-lucy',
          'port-aventura',
          'colorado-creek',
        ],
        restaurant: [
          'buffet-bohio',
          'club-san-juan',
          'el-tinglado',
          'la-bodeguita-del-medio',
          'la-piazza',
          'mango-bongo',
          'puerto-viejo',
          'salsa-cafe',
          'bar-maya',
          'club-maya',
          'el-coyote',
          'el-laberinto',
          'la-bahia',
          'merida',
          'gold-river-saloon',
          'grand-hall-buffet',
          'grand-opera',
          'lobby-bar',
          'river-bar',
          'the-callaghans',
          'bar-mansion-de-lucy',
          'lucys-cuisine',
          'buffet-del-port',
          'cafe-saula',
          'el-xiringuito',
          'port-regata',
          'colorado-lounge-bar',
          'creek-view-buffet',
          'lake-bar',
        ],
      },
      valid_combinations: [
        [0, 0],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [0, 5],
        [0, 6],
        [0, 7],
        [1, 8],
        [1, 9],
        [1, 10],
        [1, 11],
        [1, 12],
        [1, 13],
        [2, 14],
        [2, 15],
        [2, 16],
        [2, 17],
        [2, 18],
        [2, 19],
        [3, 20],
        [3, 21],
        [4, 22],
        [4, 23],
        [4, 24],
        [4, 25],
        [5, 26],
        [5, 27],
        [5, 28],
      ],
      exceptions: [
        {
          lang: 'en',
          hotelname: 'el-paso',
          restaurant: 'el-coyote',
          urlWilson: '/en/hoteles/el-paso/restaurantes/el-coyote',
          urlGatsby: '/en/hoteles/el-paso/restaurantes/el-coyote',
        },
      ],
    },
    ficha_atracciones: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/{park}/atracciones/{ride}',
          urlGatsby: '/{park}/atracciones/{ride}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/{park}/attractions/{ride}',
          urlGatsby: '/fr/{park}/attractions/{ride}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/{park}/atraccions/{ride}',
          urlGatsby: '/ca/{park}/atraccions/{ride}',
        },
        { lang: 'en', urlWilson: '/en/{park}/rides/{ride}', urlGatsby: '/en/{park}/rides/{ride}' },
      ],
      options: {
        park: ['', 'ferrari-land'],
        ride: [
          /* 00 */ 'furius-baco',
          'port-de-la-drassana',
          'estacio-del-nord',
          'tutuki-splash',
          'canoes',
          /* 05 */ 'kontiki',
          'shambhala',
          'dragon-khan',
          'angkor',
          'waitan-port',
          /* 10 */ 'cobra-imperial',
          'tea-cups',
          'area-infantil',
          'yucatan',
          'los-potrillos',
          /* 15 */ 'armadillos',
          'serpiente-emplumada',
          'hurakan-condor',
          'el-diablo-tren-de-la-mina',
          'el-secreto-de-los-mayas',
          /* 20 */ 'buffalo-rodeo',
          'silver-river-flume',
          'penitence-station',
          'tomahawk',
          'laberinto-blacksmith',
          /* 25 */ 'grand-canyon-rapids',
          'carousel',
          'wild-buffalos',
          'stampida',
          'volpaiute',
          /* 30 */ 'crazy-barrels',
          'tami-tami',
          'street-mission',
          'la-granja-de-elmo',
          'coco-piloto',
          /* 35 */ 'mariposas-saltarinas',
          'magic-fish',
          'el-huerto-encantado',
          'waikiki',
          'kiddie-dragons',
          /* 40 */ 'el-salto-de-blas',
          'el-arbol-magico',
          'sesamoaventura-station',
          'el-triangulo-de-las-bermudas',
          'bahama-beach',
          /* 45 */ 'zona-indoor',
          'mambo-limbo',
          'el-tifon',
          'el-rio-loco',
          'el-torrente',
          /* 50 */ 'barracudas',
          'la-laguna-de-woody',
          'junior-body-slides',
          'cayo-cookie',
          'ciclon-tropical',
          /* 55 */ 'rapid-race',
          'el-galeon-pirata',
          'king-khajuna',
          'playa-paraiso',
          'sesamo-beach',
          /* 60 */ 'red-force',
          'torre-caida-libre',
          'torre-rebote',
          'maranello-grand-race',
          'ferrari-land-gallery',
          /* 65 */ 'racing-legends',
          'junior-championship',
          'kids-podium',
          'pole-position-challenge',
          'pit-stop-record',
          /* 70 */ 'crazy-pistons',
          'junior-red-force',
          'kids-tower',
          'champions-race',
          'flying-race',
          /* 75 */ 'flying-dreams',
        ],
      },
      valid_combinations: [
        [0, 0],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [0, 5],
        [0, 6],
        [0, 7],
        [0, 8],
        [0, 9],
        [0, 10],
        [0, 11],
        [0, 12],
        [0, 13],
        [0, 14],
        [0, 15],
        [0, 16],
        [0, 17],
        [0, 18],
        [0, 19],
        [0, 20],
        [0, 21],
        [0, 22],
        [0, 23],
        [0, 24],
        [0, 25],
        [0, 26],
        [0, 27],
        [0, 28],
        [0, 29],
        [0, 30],
        [0, 31],
        [0, 32],
        [0, 33],
        [0, 34],
        [0, 35],
        [0, 36],
        [0, 37],
        [0, 38],
        [0, 39],
        [0, 40],
        [0, 41],
        [0, 42],
        [0, 43],
        [0, 44],
        [0, 45],
        [0, 46],
        [0, 47],
        [0, 48],
        [0, 49],
        [0, 50],
        [0, 51],
        [0, 52],
        [0, 53],
        [0, 54],
        [0, 55],
        [0, 56],
        [0, 57],
        [0, 58],
        [0, 59],
        [1, 60],
        [1, 61],
        [1, 62],
        [1, 63],
        [1, 64],
        [1, 65],
        [1, 66],
        [1, 67],
        [1, 68],
        [1, 69],
        [1, 70],
        [1, 71],
        [1, 72],
        [1, 73],
        [1, 74],
        [1, 75],
      ],

      exceptions: [
        {
          lang: 'fr',
          park: 'ferrari-land',
          ride: 'torre-caida-libre',
          urlWilson: '/fr/ferrari-land/attractions/tour-de-chute-libre',
          urlGatsby: '/fr/ferrari-land/attractions/tour-de-chute-libre',
        },
        {
          lang: 'ca',
          park: 'ferrari-land',
          ride: 'torre-caida-libre',
          urlWilson: '/ca/ferrari-land/atraccions/torre-de-caiguda-lliure',
          urlGatsby: '/ca/ferrari-land/atraccions/torre-de-caiguda-lliure',
        },
        {
          lang: 'fr',
          park: 'ferrari-land',
          ride: 'torre-rebote',
          urlWilson: '/fr/ferrari-land/attractions/tour-de-rebond',
          urlGatsby: '/fr/ferrari-land/attractions/tour-de-rebond',
        },
        {
          lang: 'en',
          park: 'ferrari-land',
          ride: 'torre-caida-libre',
          urlWilson: '/en/ferrari-land/rides/free-fall-tower',
          urlGatsby: '/en/ferrari-land/rides/free-fall-tower',
        },
        {
          lang: 'sv',
          park: 'ferrari-land',
          ride: 'torre-caida-libre',
          urlWilson: '/en/ferrari-land/rides/free-fall-tower',
          urlGatsby: '/en/ferrari-land/rides/free-fall-tower',
        },
        {
          lang: 'cn',
          park: 'ferrari-land',
          ride: 'torre-caida-libre',
          urlWilson: '/en/ferrari-land/rides/free-fall-tower',
          urlGatsby: '/en/ferrari-land/rides/free-fall-tower',
        },
        {
          lang: 'en',
          park: 'ferrari-land',
          ride: 'torre-rebote',
          urlWilson: '/en/ferrari-land/rides/bounce-back-tower',
          urlGatsby: '/en/ferrari-land/rides/bounce-back-tower',
        },
        {
          lang: 'sv',
          park: 'ferrari-land',
          ride: 'torre-rebote',
          urlWilson: '/en/ferrari-land/rides/bounce-back-tower',
          urlGatsby: '/en/ferrari-land/rides/bounce-back-tower',
        },
        {
          lang: 'cn',
          park: 'ferrari-land',
          ride: 'torre-rebote',
          urlWilson: '/en/ferrari-land/rides/bounce-back-tower',
          urlGatsby: '/en/ferrari-land/rides/bounce-back-tower',
        },
      ],
    },

    ficha_espectaculos_navidad: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/{park}/navidad/espectaculos/{shows}',
          urlGatsby: '/{park}/navidad/espectaculos/{shows}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/{park}/noel/spectacles/{shows}',
          urlGatsby: '/fr/{park}/noel/spectacles/{shows}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/{park}/nadal/espectacles/{shows}',
          urlGatsby: '/ca/{park}/nadal/espectacles/{shows}',
        },
        {
          lang: 'en',
          urlWilson: '/en/{park}/christmas/shows/{shows}',
          urlGatsby: '/en/{park}/christmas/shows/{shows}',
        },
      ],
      options: {
        park: [''],
        shows: [
          /* 00 */ 'bosque-encantado',
          'christmas-parade',
          'christmas-world',
          'cuento-navidad',
          'llegada-emisarios-reales',
          /* 05 */ 'christmas-time',
          'navidad-en-sesamoaventura',
          'navidad-tadeo',
          'navidad-woody',
          'posada-gnomos',
          /* 10 */ 'winter-magic-bubblebou',
          'meet-chilly-willy',
          'big-bird',
          'gran-fiesta-navidad',
          'burbuja-deseos',
          /* 15 */ 'bubble-magic-christmas',
          'bang-bang-christmas',
        ],
      },
      valid_combinations: [
        [0, 0],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [0, 5],
        [0, 6],
        [0, 7],
        [0, 8],
        [0, 9],
        [0, 10],
        [0, 11],
        [0, 12],
        [0, 13],
        [0, 14],
        [0, 15],
        [0, 16],
      ],
      exceptions: [],
    },

    ficha_espectaculos: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/{park}/espectaculos/{shows}',
          urlGatsby: '/{park}/espectaculos/{shows}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/{park}/spectacles/{shows}',
          urlGatsby: '/fr/{park}/spectacles/{shows}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/{park}/espectacles/{shows}',
          urlGatsby: '/ca/{park}/espectacles/{shows}',
        },
        {
          lang: 'en',
          urlWilson: '/en/{park}/shows/{shows}',
          urlGatsby: '/en/{park}/shows/{shows}',
        },
      ],
      options: {
        park: ['', 'ferrari-land', 'halloween'],
        shows: [
          /* 00 */ 'fiestaventura',
          'portaventura-parade',
          'aves-del-paraiso',
          '4d-experience',
          'pareos-en-bora-bora',
          /* 05 */ 'noches-de-fuego',
          'aloha-tahiti',
          'magic-bubble',
          'dance-revolution',
          'sesamoaventuras-time-machine',
          /* 10 */ 'cantina-mexico',
          'aventuras-tadeo-jones',
          'sesame-street-show',
          'templo-del-fuego',
          'tex-mex',
          /* 15 */ 'bang-bang-west',
          'cancan',
          'horror-in-texas',
          'dance-movies',
          'rondo-veneziano',
          /* 20 */ 'folklore-italiano',
          'ancient-rome',
          'acrobatic-show-ferrari-land',
          'lets-dance',
          'abby-star-studio',
          /* 25 */ 'casa-de-woody',
          'la-isla-maldita',
          'vampires',
          'la-muerte-viva',
          'rec-experience',
          /* 30 */ 'posada-halloween',
          'horror-en-el-lago',
          'halloween-parade',
          'halloween-kingdom',
          'halloween-family-festival',
          /* 35 */ 'apocalipsis-maya',
          'bang-bang-halloween',
          'halloween-en-sesamo-aventura',
          'viva-mexico',
          'christmas',
          /* 40 */ 'pin-up',
          'shrek',
          'around-world',
          'bmx-show',
          'halloween-forever',
          'cuidemos-el-planeta',
        ],
      },
      valid_combinations: [
        [0, 0],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [0, 5],
        [0, 6],
        [0, 7],
        [0, 8],
        [0, 9],
        [0, 10],
        [0, 11],
        [0, 12],
        [0, 13],
        [0, 14],
        [0, 15],
        [0, 16],
        [0, 17],
        [0, 18],
        [1, 19],
        [1, 20],
        [1, 21],
        [1, 22],
        [0, 23],
        [0, 24],
        [0, 25],
        [2, 26],
        [2, 27],
        [2, 28],
        [0, 29],
        [2, 30],
        [2, 31],
        [2, 32],
        [2, 33],
        [2, 34],
        [2, 35],
        [2, 36],
        [2, 37],
        [0, 38],
        [1, 39],
        [1, 40],
        [0, 41],
        [0, 42],
        [1, 43],
        [2, 44],
        [0, 45],
      ],
      exceptions: [
        {
          lang: 'es',
          park: '',
          shows: 'abby-star-studio',
          urlWilson: '/es/tiendas/abby-star-studio',
          urlGatsby: '/tiendas/abby-star-studio',
        },
        {
          lang: 'en',
          park: '',
          shows: 'abby-star-studio',
          urlWilson: '/en/shops/abby-star-studio',
          urlGatsby: '/en/shops/abby-star-studio',
        },
        {
          lang: 'ca',
          park: '',
          shows: 'abby-star-studio',
          urlWilson: '/ca/botigues/abby-star-studio',
          urlGatsby: '/ca/botigues/abby-star-studio',
        },
        {
          lang: 'fr',
          park: '',
          shows: 'abby-star-studio',
          urlWilson: '/fr/boutiques/abby-star-studio',
          urlGatsby: '/fr/boutiques/abby-star-studio',
        },
        {
          lang: 'es',
          park: '',
          shows: 'casa-de-woody',
          urlWilson: '/es/tiendas/casa-de-woody',
          urlGatsby: '/tiendas/casa-de-woody',
        },
        {
          lang: 'ca',
          park: '',
          shows: 'casa-de-woody',
          urlWilson: '/ca/botigues/casa-de-woody',
          urlGatsby: '/ca/botigues/casa-de-woody',
        },
        {
          lang: 'fr',
          park: '',
          shows: 'casa-de-woody',
          urlWilson: '/fr/boutiques/casa-de-woody',
          urlGatsby: '/fr/boutiques/casa-de-woody',
        },
        {
          lang: 'en',
          park: '',
          shows: 'casa-de-woody',
          urlWilson: '/en/shops/casa-de-woody',
          urlGatsby: '/en/shops/casa-de-woody',
        },
      ],
    },
    ficha_mundos: {
      urls: [
        { lang: 'es', urlWilson: '/es/portaventura/{zona}', urlGatsby: '/portaventura/{zona}' },
        { lang: 'fr', urlWilson: '/fr/portaventura/{zona}', urlGatsby: '/fr/portaventura/{zona}' },
        { lang: 'ca', urlWilson: '/ca/portaventura/{zona}', urlGatsby: '/ca/portaventura/{zona}' },
        { lang: 'en', urlWilson: '/en/portaventura/{zona}', urlGatsby: '/en/portaventura/{zona}' },
      ],
      options: {
        zona: ['mediterrania', 'polynesia', 'china', 'mexico', 'sesamo-aventura', 'far-west'],
      },
      exceptions: [],
    },
    ficha_destiny: {
      urls: [
        { lang: 'es', urlWilson: '/es/destino/{zone}', urlGatsby: '/destino/{zone}' },
        { lang: 'fr', urlWilson: '/fr/destination/{zone}', urlGatsby: '/fr/destination/{zone}' },
        { lang: 'ca', urlWilson: 'ca/destinacio/{zone}', urlGatsby: '/ca/destinacio/{zone}' },
        { lang: 'en', urlWilson: 'en/destination/{zone}', urlGatsby: '/en/destination/{zone}' },
      ],
      options: {
        zone: ['barcelona', 'costa-dorada', 'cataluna'],
      },
      exceptions: [
        {
          lang: 'fr',
          zone: 'cataluna',
          urlWilson: 'fr/destination/catalogne',
          urlGatsby: '/fr/destination/catalogne',
        },
        {
          lang: 'ca',
          zone: 'cataluna',
          urlWilson: 'ca/destinacio/catalunya',
          urlGatsby: '/ca/destinacio/catalunya',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          urlWilson: 'ca/coses-per-fer-hi/costa-daurada',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada',
        },
        {
          lang: 'ca',
          zone: 'barcelona',
          urlWilson: 'ca/coses-per-fer-hi/barcelona',
          urlGatsby: '/ca/coses-per-fer-hi/barcelona',
        },
        {
          lang: 'en',
          zone: 'cataluna',
          urlWilson: 'en/destination/catalonia',
          urlGatsby: '/en/destination/catalonia',
        },
      ],
    },
    ficha_entorno: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/destino/{zone}/{poblaciones}',
          urlGatsby: '/destino/{zone}/{poblaciones}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/destination/{zone}/{poblaciones}',
          urlGatsby: '/fr/destination/{zone}/{poblaciones}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/coses-per-fer-hi/{zone}/{poblaciones}',
          urlGatsby: '/ca/coses-per-fer-hi/{zone}/{poblaciones}',
        },
        {
          lang: 'en',
          urlWilson: '/en/destination/{zone}/{poblaciones}',
          urlGatsby: '/en/destination/{zone}/{poblaciones}',
        },
      ],
      options: {
        zone: ['barcelona', 'costa-dorada', 'cataluna'],
        poblaciones: [
          /* 00 */ 'la-sagrada-familia',
          'casa-batllo',
          'barcelona-turisme',
          'fc-barcelona',
          'circuit-de-barcelona-catalunya',
          /* 05 */ 'la-roca-village',
          'poblaciones-cercanas',
          'cultura',
          'actividades-deportivas',
          'gastronomia',
          /* 10 */ 'shopping',
          'que-hacer-arte-cultura',
          'donde-comer-enogastronomia',
          'que-hacer-deporte',
          'que-hacer-en-familia',
          /* 15 */ 'que-hacer-naturaleza-aventura',
          'rutas-excursiones',
          'poblaciones-cercanas/cambrils',
          'poblaciones-cercanas/reus',
          'poblaciones-cercanas/la-pineda',
          'poblaciones-cercanas/tarragona',
        ],
      },
      valid_combinations: [
        [0, 0],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [0, 5],
        [1, 6],
        [1, 7],
        [1, 8],
        [1, 9],
        [1, 10],
        [2, 11],
        [2, 12],
        [2, 13],
        [2, 14],
        [2, 15],
        [2, 16],
        [1, 17],
        [1, 18],
        [1, 19],
        [1, 20],
      ],

      exceptions: [
        {
          lang: 'fr',
          zone: 'costa-dorada',
          poblaciones: 'actividades-deportivas',
          urlWilson: '/fr/destination/costa-dorada/activites-sportives',
          urlGatsby: '/fr/destination/costa-dorada/activites-sportives',
        },
        {
          lang: 'fr',
          zone: 'costa-dorada',
          poblaciones: 'gastronomia',
          urlWilson: '/fr/destination/costa-dorada/gastronomie',
          urlGatsby: '/fr/destination/costa-dorada/gastronomie',
        },
        {
          lang: 'fr',
          zone: 'costa-dorada',
          poblaciones: 'cultura',
          urlWilson: '/fr/destination/costa-dorada/culture',
          urlGatsby: '/fr/destination/costa-dorada/culture',
        },
        {
          lang: 'fr',
          zone: 'cataluna',
          poblaciones: 'que-hacer-arte-cultura',
          urlWilson: '/fr/destination/catalogne/que-faire-art-culture',
          urlGatsby: '/fr/destination/catalogne/que-faire-art-culture',
        },
        {
          lang: 'fr',
          zone: 'cataluna',
          poblaciones: 'que-hacer-en-familia',
          urlWilson: '/fr/destination/catalogne/que-faire-en-famille',
          urlGatsby: '/fr/destination/catalogne/que-faire-en-famille',
        },
        {
          lang: 'fr',
          zone: 'cataluna',
          poblaciones: 'donde-comer-enogastronomia',
          urlWilson: '/fr/destination/catalogne/ou-manger-enogastronomie',
          urlGatsby: '/fr/destination/catalogne/ou-manger-enogastronomie',
        },
        {
          lang: 'fr',
          zone: 'cataluna',
          poblaciones: 'que-hacer-deporte',
          urlWilson: '/fr/destination/catalogne/que-faire-sport',
          urlGatsby: '/fr/destination/catalogne/que-faire-sport',
        },
        {
          lang: 'fr',
          zone: 'cataluna',
          poblaciones: 'rutas-excursiones',
          urlWilson: '/fr/destination/catalogne/itineraires-excursions',
          urlGatsby: '/fr/destination/catalogne/itineraires-excursions',
        },
        {
          lang: 'fr',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas/reus',
          urlWilson: '/fr/destino/costa-dorada/poblaciones-cercanas/reus',
          urlGatsby: '/fr/destino/costa-dorada/poblaciones-cercanas/reus',
        },
        {
          lang: 'fr',
          zone: 'cataluna',
          poblaciones: 'que-hacer-naturaleza-aventura',
          urlWilson: '/fr/destination/catalogne/que-faire-nature-aventure',
          urlGatsby: '/fr/destination/catalogne/que-faire-nature-aventure',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          poblaciones: 'cultura',
          urlWilson: '/ca/coses-per-fer-hi/costa-daurada/cultura',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada/cultura',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          poblaciones: 'actividades-deportivas',
          urlWilson: '/ca/coses-per-fer-hi/costa-daurada/actividades-deportivas',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada/actividades-deportivas',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          poblaciones: 'gastronomia',
          urlWilson: '/ca/coses-per-fer-hi/costa-daurada/gastronomia',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada/gastronomia',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          poblaciones: 'shopping',
          urlWilson: '/ca/coses-per-fer-hi/costa-daurada/shopping',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada/shopping',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas',
          urlWilson: '/ca/coses-per-fer-hi/costa-daurada/entorn',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada/entorn',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas/cambrils',
          urlWilson: '/ca/coses-per-fer-hi/costa-daurada/entorn/cambrils',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada/entorn/cambrils',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas/reus',
          urlWilson: '/ca/coses-per-fer-hi/costa-daurada/entorn/reus',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada/entorn/reus',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas/la-pineda',
          urlWilson: '/ca/coses-per-fer-hi/costa-daurada/entorn/la-pineda',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada/entorn/la-pineda',
        },
        {
          lang: 'ca',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas/tarragona',
          urlWilson: '/ca/coses-per-fer-hi/costa-daurada/entorn/tarragona',
          urlGatsby: '/ca/coses-per-fer-hi/costa-daurada/entorn/tarragona',
        },
        {
          lang: 'ca',
          zone: 'cataluna',
          poblaciones: 'que-hacer-arte-cultura',
          urlWilson: '/ca/destinacio/catalunya/que-fer-art-cultura',
          urlGatsby: '/ca/destinacio/catalunya/que-fer-art-cultura',
        },
        {
          lang: 'ca',
          zone: 'cataluna',
          poblaciones: 'que-hacer-deporte',
          urlWilson: '/ca/destinacio/catalunya/que-fer-esport',
          urlGatsby: '/ca/destinacio/catalunya/que-fer-esport',
        },
        {
          lang: 'ca',
          zone: 'cataluna',
          poblaciones: 'que-hacer-en-familia',
          urlWilson: '/ca/destinacio/catalunya/que-fer-en-familia',
          urlGatsby: '/ca/destinacio/catalunya/que-fer-en-familia',
        },
        {
          lang: 'ca',
          zone: 'cataluna',
          poblaciones: 'que-hacer-naturaleza-aventura',
          urlWilson: '/ca/destinacio/catalunya/que-fer-natura-aventura',
          urlGatsby: '/ca/destinacio/catalunya/que-fer-natura-aventura',
        },
        {
          lang: 'ca',
          zone: 'cataluna',
          poblaciones: 'donde-comer-enogastronomia',
          urlWilson: '/ca/catalunya/on-menjar-enogastronomia',
          urlGatsby: '/ca/catalunya/on-menjar-enogastronomia',
        },
        {
          lang: 'ca',
          zone: 'cataluna',
          poblaciones: 'rutas-excursiones',
          urlWilson: '/ca/destinacio/catalunya/rutes-excursions',
          urlGatsby: '/ca/destinacio/catalunya/rutes-excursions',
        },
        {
          lang: 'en',
          zone: 'cataluna',
          poblaciones: 'rutas-excursiones',
          urlWilson: '/en/destination/catalonia/routes-excursions',
          urlGatsby: '/en/destination/catalonia/routes-excursions',
        },
        {
          lang: 'en',
          zone: 'cataluna',
          poblaciones: 'donde-comer-enogastronomia',
          urlWilson: '/en/destination/catalonia/where-to-eat-food-and-wine',
          urlGatsby: '/en/destination/catalonia/where-to-eat-food-and-wine',
        },
        {
          lang: 'en',
          zone: 'cataluna',
          poblaciones: 'que-hacer-naturaleza-aventura',
          urlWilson: '/en/destination/catalonia/things-to-do-nature-adventure',
          urlGatsby: '/en/destination/catalonia/things-to-do-nature-adventure',
        },
        {
          lang: 'en',
          zone: 'cataluna',
          poblaciones: 'que-hacer-en-familia',
          urlWilson: '/en/destination/catalonia/things-to-do-with-family',
          urlGatsby: '/en/destination/catalonia/things-to-do-with-family',
        },
        {
          lang: 'en',
          zone: 'cataluna',
          poblaciones: 'que-hacer-deporte',
          urlWilson: '/en/destination/catalonia/things-to-do-sport',
          urlGatsby: '/en/destination/catalonia/things-to-do-sport',
        },
        {
          lang: 'en',
          zone: 'cataluna',
          poblaciones: 'que-hacer-arte-cultura',
          urlWilson: '/en/destination/catalonia/things-to-do-art-culture',
          urlGatsby: '/en/destination/catalonia/things-to-do-art-culture',
        },
        {
          lang: 'en',
          zone: 'barcelona',
          poblaciones: 'la-sagrada-familia',
          urlWilson: '/en/destination/barcelona/sagrada-familia',
          urlGatsby: '/en/destination/barcelona/sagrada-familia',
        },
        {
          lang: 'en',
          zone: 'costa-dorada',
          poblaciones: 'cultura',
          urlWilson: '/en/destination/costa-dorada/culture',
          urlGatsby: '/en/destination/costa-dorada/culture',
        },
        {
          lang: 'en',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas',
          urlWilson: '/en/destination/costa-dorada/nearby-areas',
          urlGatsby: '/en/destination/costa-dorada/nearby-areas',
        },
        {
          lang: 'en',
          zone: 'costa-dorada',
          poblaciones: 'actividades-deportivas',
          urlWilson: '/en/destination/costa-dorada/sporting-activities',
          urlGatsby: '/en/destination/costa-dorada/sporting-activities',
        },
        {
          lang: 'en',
          zone: 'costa-dorada',
          poblaciones: 'gastronomia',
          urlWilson: '/en/destination/costa-dorada/cuisine',
          urlGatsby: '/en/destination/costa-dorada/cuisine',
        },
        {
          lang: 'en',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas/cambrils',
          urlWilson: '/en/destination/costa-dorada/nearby-areas/cambrils',
          urlGatsby: '/en/destination/costa-dorada/nearby-areas/cambrils',
        },
        {
          lang: 'en',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas/reus',
          urlWilson: '/en/destination/costa-dorada/nearby-areas/reus',
          urlGatsby: '/en/destination/costa-dorada/nearby-areas/reus',
        },
        {
          lang: 'en',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas/la-pineda',
          urlWilson: '/en/destination/costa-dorada/nearby-areas/la-pineda',
          urlGatsby: '/en/destination/costa-dorada/nearby-areas/la-pineda',
        },
        {
          lang: 'en',
          zone: 'costa-dorada',
          poblaciones: 'poblaciones-cercanas/tarragona',
          urlWilson: '/en/destination/costa-dorada/nearby-areas/tarragona',
          urlGatsby: '/en/destination/costa-dorada/nearby-areas/tarragona',
        },
      ],
    },
    ficha_tiendas: {
      urls: [
        { lang: 'es', urlWilson: '/es/{park}/tiendas/{shop}', urlGatsby: '/{park}/tiendas/{shop}' },
        {
          lang: 'ca',
          urlWilson: '/ca/{park}/botigues/{shop}',
          urlGatsby: '/ca/{park}/botigues/{shop}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/{park}/boutiques/{shop}',
          urlGatsby: '/fr/{park}/boutiques/{shop}',
        },
        { lang: 'en', urlWilson: '/en/{park}/shops/{shop}', urlGatsby: '/en/{park}/shops/{shop}' },
      ],
      options: {
        park: ['', 'ferrari-land'],
        shop: [
          'records-de-portaventura',
          'dolcos-mes',
          'mas-fonoll',
          'fotografia-furius-baco',
          'la-botigueta',
          'surf-paradise',
          'fotografia-tutuki-splash',
          '4d-experience-shop',
          'escuela-de-conduccion-kids-car',
          'aventura-oriental',
          'lotus-palace',
          'sarpang-shop',
          'fotografia-dragon-khan',
          'timbu-photoride',
          'kambuha',
          'taxco',
          'hurakan-condor-shop',
          'tianguis',
          'fotografia-el-diablo-tren-de-la-mina',
          'general-store',
          'western-clothing-company',
          'flys-photograph-studio',
          'grand-canyon-rapids-shop',
          'fotografia-stampida',
          'fotografia-silver-river-flume',
          'fotografia-grand-canyon-rapids',
          'fotografia-tomahawk',
          'chikitienda',
          'fotografia-la-granja-de-elmo',
          'fotografia-tami-tami',
          'portaventura-caribe-shop',
          'fotografia-portaventura-caribe',
          'ferrari-land-store',
          'junior-championship-shop',
          'photo-gallery',
          'red-force-photoride',
          'photo-pit-stop-record',
          'foto-4d-experience',
          'fotografia-escuela-de-conduccion',
          'fotografia-los-potrillos',
          'tienda-comic-west',
          'halloween-tianguis',
        ],
      },
      valid_combinations: [
        [0, 0],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [0, 5],
        [0, 6],
        [0, 7],
        [0, 8],
        [0, 9],
        [0, 10],
        [0, 11],
        [0, 12],
        [0, 13],
        [0, 14],
        [0, 15],
        [0, 16],
        [0, 17],
        [0, 18],
        [0, 19],
        [0, 20],
        [0, 21],
        [0, 22],
        [0, 23],
        [0, 24],
        [0, 25],
        [0, 26],
        [0, 27],
        [0, 28],
        [0, 29],
        [0, 30],
        [0, 31],
        [1, 32],
        [1, 33],
        [1, 34],
        [1, 35],
        [1, 36],
        [0, 37],
        [0, 38],
        [0, 39],
        [0, 40],
        [0, 41],
      ],
      exceptions: [
        {
          lang: 'en',
          park: '',
          shop: 'fotografia-furius-baco',
          urlWilson: '/en/shops/photoride-furius-baco',
          urlGatsby: '/en/shops/photoride-furius-baco',
        },
      ],
    },

    ficha_restaurantes: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/{park}/restaurantes/{restaurant}',
          urlGatsby: '/{park}/restaurantes/{restaurant}',
        },
        {
          lang: 'en',
          urlWilson: '/en/{park}/restaurants/{restaurant}',
          urlGatsby: '/en/{park}/restaurants/{restaurant}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/{park}/restaurants/{restaurant}',
          urlGatsby: '/ca/{park}/restaurants/{restaurant}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/{park}/restaurants/{restaurant}',
          urlGatsby: '/fr/{park}/restaurants/{restaurant}',
        },
      ],
      options: {
        park: ['', 'ferrari-land'],
        restaurant: [
          'vinosfera-tapes-i-vins',
          'raco-de-mar',
          'cafe-saula',
          'puntos-de-alimentacion-mediterranea',
          'bora-bora',
          'puntos-de-alimentacion-polynesia',
          'marco-polo',
          'sichuan',
          'dagana',
          'canton',
          'puntos-de-alimentacion-china',
          'hacienda-el-charro',
          'la-cantina',
          'puntos-de-alimentacion-mexico',
          'the-iron-horse',
          'the-old-steak-house',
          'jeremias-food',
          'chickens-stampida',
          'puntos-de-alimentacion-far-west',
          'la-cocina-de-epi',
          'puntos-de-alimentacion-sesamoaventura',
          'reggae-cafe',
          'focacceria',
          'the-surfer',
          'la-cabana',
          'puntos-de-alimentacion-aquatic-park',
          'ristorante-cavallino',
          'pite-lane',
          'stop-go',
          'ice-cream-box',
          'paddock',
          'fast-lap',
          'finish-line',
        ],
      },
      valid_combinations: [
        [0, 0],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [0, 5],
        [0, 6],
        [0, 7],
        [0, 8],
        [0, 9],
        [0, 10],
        [0, 11],
        [0, 12],
        [0, 13],
        [0, 14],
        [0, 15],
        [0, 16],
        [0, 17],
        [0, 18],
        [0, 19],
        [0, 20],
        [0, 21],
        [0, 22],
        [0, 23],
        [0, 24],
        [0, 25],
        [1, 26],
        [1, 27],
        [1, 28],
        [1, 29],
        [1, 30],
        [1, 31],
        [1, 32],
      ],
      exceptions: [
        {
          lang: 'en',
          park: '',
          restaurant: 'puntos-de-alimentacion-sesamoaventura',
          urlWilson: '/en/restaurants/puntos-de-alimentacion-sesamo-aventura',
          urlGatsby: '/en/restaurants/puntos-de-alimentacion-sesamo-aventura',
        },
        {
          lang: 'ca',
          park: '',
          restaurant: 'puntos-de-alimentacion-polynesia',
          urlWilson: '/ca/restaurants/punts-d-alimentacio-polynesia',
          urlGatsby: '/ca/restaurants/punts-d-alimentacio-polynesia',
        },
        {
          lang: 'ca',
          park: '',
          restaurant: 'puntos-de-alimentacion-mediterranea',
          urlWilson: '/ca/restaurants/puntos-de-alimentacion-mediterrania',
          urlGatsby: '/ca/restaurants/puntos-de-alimentacion-mediterrania',
        },
        {
          lang: 'en',
          park: '',
          restaurant: 'puntos-de-alimentacion-mediterranea',
          urlWilson: '/en/restaurants/puntos-de-alimentacion-mediterrania',
          urlGatsby: '/en/restaurants/puntos-de-alimentacion-mediterrania',
        },
        {
          lang: 'ca',
          park: '',
          restaurant: 'puntos-de-alimentacion-china',
          urlWilson: '/ca/restaurants/punts-d-alimentacio-china',
          urlGatsby: '/ca/restaurants/punts-d-alimentacio-china',
        },
        {
          lang: 'ca',
          park: 'ferrari-land',
          restaurant: 'ristorante-cavallino',
          urlWilson: '/ca/ferrari-land/restaurants/ristorants-cavallino',
          urlGatsby: '/ca/ferrari-land/restaurants/ristorants-cavallino',
        },
      ],
    },

    pase_anual: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/area-privada/beneficios-club/{passType}',
          urlGatsby: '/pases-anuales/{passType}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/area-privada/beneficios-club/{passType}',
          urlGatsby: '/fr/pases-anuales/{passType}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/area-privada/beneficios-club/{passType}',
          urlGatsby: '/ca/pases-anuales/{passType}',
        },
        {
          lang: 'en',
          urlWilson: '/en/area-privada/beneficios-club/{passType}',
          urlGatsby: '/en/pases-anuales/{passType}',
        },
      ],
      options: {
        passType: ['', 'pase-gold', 'pase-premium', 'pase-platinum'],
      },
      exceptions: [
        {
          lang: 'es',
          passType: '',
          urlWilson: '/es/area-privada/beneficios-club/pase-platinum',
          urlGatsby: '/pases-anuales',
        },
        {
          lang: 'fr',
          passType: '',
          urlWilson: '/fr/area-privada/beneficios-club/pase-platinum',
          urlGatsby: '/fr/pases-anuales',
        },
        {
          lang: 'ca',
          passType: '',
          urlWilson: '/ca/area-privada/beneficios-club/pase-platinum',
          urlGatsby: '/ca/pases-anuales',
        },
        {
          lang: 'en',
          passType: '',
          urlWilson: '/en/area-privada/beneficios-club/pase-platinum',
          urlGatsby: '/en/pases-anuales',
        },
      ],
    },
    ficha_material_grafico: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/mapa-web/material-grafico/{section}',
          urlGatsby: '/mapa-web/material-grafico/{section}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/web-map/materiel-graphique/{section}',
          urlGatsby: '/fr/web-map/materiel-graphique/{section}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/mapa-web/material-grafic/{section}',
          urlGatsby: '/ca/mapa-web/material-grafic/{section}',
        },
        {
          lang: 'en',
          urlWilson: '/en/site-map/visuals/{section}',
          urlGatsby: '/en/site-map/visuals/{section}',
        },
      ],
      options: {
        section: [
          'b-e',
          'portaventura-park',
          'ferrari-land',
          'aquatic-park',
          'cirque-du-soleil',
          'hotel',
        ],
      },
      exceptions: [
        {
          lang: 'es',
          section: 'hotel',
          urlWilson: '/es/mapa-web/material-grafico/hoteles',
          urlGatsby: '/mapa-web/material-grafico/hoteles',
        },
        {
          lang: 'en',
          section: 'hotel',
          urlWilson: '/en/site-map/visuals/hotels',
          urlGatsby: '/en/site-map/visuals/hotels',
        },
        {
          lang: 'fr',
          section: 'hotel',
          urlWilson: '/fr/web-map/materiel-graphique/hotels',
          urlGatsby: '/fr/web-map/materiel-graphique/hotels',
        },
        {
          lang: 'ca',
          section: 'hotel',
          urlWilson: '/ca/mapa-web/material-grafic/hotels',
          urlGatsby: '/ca/mapa-web/material-grafic/hotels',
        },
      ],
    },
  },
  templates_multilang: {
    ficha_prensa: {
      urls: [
        { lang: 'es', urlWilson: '/es/mapa-web/{note}', urlGatsby: '/mapa-web/{note}' },
        { lang: 'fr', urlWilson: '/fr/plan-du-site/{note}', urlGatsby: '/fr/plan-du-site/{note}' },
        { lang: 'ca', urlWilson: '/ca/mapa-web/{note}', urlGatsby: '/ca/mapa-web/{note}' },
        { lang: 'en', urlWilson: '/en/site-map/{note}', urlGatsby: '/en/site-map/{note}' },
      ],
      options: {
        note: {
          es: [
            'notas-de-prensa-2010',
            'notas-de-prensa-2011',
            'notas-de-prensa-2012',
            'notas-de-prensa-2013',
            'notas-de-prensa-2014',
            'notas-de-prensa-2015',
            'notas-de-prensa-2016',
            'notas-de-prensa-2017',
            'notas-de-prensa-2018',
            'notas-de-prensa-2019',
            'notas-de-prensa-2020',
            'notas-de-prensa-2021',
            'notas-de-prensa-2022',
            'notas-de-prensa-2023',
          ],
          fr: [
            'communiques-de-presse-2010',
            'communiques-de-presse-2011',
            'communiques-de-presse-2012',
            'communiques-de-presse-2013',
            'communiques-de-presse-2014',
            'communiques-de-presse-2015',
            'communiques-de-presse-2016',
            'communiques-de-presse-2017',
            'communiques-de-presse-2018',
            'communiques-de-presse-2019',
            'communiques-de-presse-2020',
            'communiques-de-presse-2021',
            'communiques-de-presse-2022',
            'communiques-de-presse-2023',
          ],
          ca: [
            'notes-de-premsa-2010',
            'notes-de-premsa-2011',
            'notes-de-premsa-2012',
            'notes-de-premsa-2013',
            'notes-de-premsa-2014',
            'notes-de-premsa-2015',
            'notes-de-premsa-2016',
            'notes-de-premsa-2017',
            'notes-de-premsa-2018',
            'notes-de-premsa-2019',
            'notes-de-premsa-2020',
            'notes-de-premsa-2020',
            'notes-de-premsa-2020',
            'notes-de-premsa-2023',
          ],
          en: [
            'press-releases-2010',
            'press-releases-2011',
            'press-releases-2012',
            'press-releases-2013',
            'press-releases-2014',
            'press-releases-2015',
            'press-releases-2016',
            'press-releases-2017',
            'press-releases-2018',
            'press-releases-2019',
            'press-releases-2020',
            'press-releases-2021',
            'press-releases-2022',
            'press-releases-2023',
          ],
        },
      },
      exceptions: [],
    },
    plan_your_stay_types: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/planifica-tu-viaje/{type}',
          urlGatsby: '/planifica-tu-viaje/{type}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/planifiez-votre-sejour/{type}',
          urlGatsby: '/fr/planifiez-votre-sejour/{type}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/planifica-el-teu-viatge/{type}',
          urlGatsby: '/ca/planifica-el-teu-viatge/{type}',
        },
        {
          lang: 'en',
          urlWilson: '/en/plan-your-trip/{type}',
          urlGatsby: '/en/plan-your-trip/{type}',
        },
      ],
      options: {
        type: {
          es: [
            'familia-1-dia',
            'familia-escapada',
            'familia-vacaciones',
            'amigos-1-dia',
            'amigos-escapada',
            'amigos-vacaciones',
          ],
          fr: [
            'famille-1-jour',
            'famille-escapade',
            'famille-vacances',
            'amis-1-jour',
            'amis-escapade',
            'amis-vacances',
          ],
          ca: [
            'familia-dia',
            'familia-escapada',
            'familia-vacances',
            'amics-dia',
            'amics-escapada',
            'amics-vacances',
          ],
          en: [
            'family-1-day',
            'family-getaway',
            'family-holiday',
            'friends-1-day',
            'friends-getaway',
            'friends-holiday',
          ],
        },
      },
      exceptions: [],
    },
    ficha_grupos: {
      urls: [
        { lang: 'es', urlWilson: '/es/grupos/{section}', urlGatsby: '/grupos/{section}' },
        { lang: 'fr', urlWilson: '/fr/groupes/{section}', urlGatsby: '/fr/groupes/{section}' },
        { lang: 'ca', urlWilson: '/ca/grups/{section}', urlGatsby: '/ca/grups/{section}' },
        { lang: 'en', urlWilson: '/en/groups/{section}', urlGatsby: '/en/groups/{section}' },
      ],
      options: {
        section: {
          es: [
            'grupos-vacacionales',
            'grupos-escolares',
            'personas-discapacidad-pmr',
            'familia-numerosa',
          ],
          fr: [
            'vacances-groupes',
            'groupes-scolaires',
            'personas-discapacidad-pmr',
            'familia-numerosa',
          ],
          ca: [
            'grups-de-vacances',
            'grups-escoles',
            'personas-discapacidad-pmr',
            'familia-numerosa',
          ],
          en: ['holiday-groups', 'groups-schools', 'personas-discapacidad-pmr', 'familia-numerosa'],
        },
      },
      exceptions: [
        {
          lang: 'es',
          section: 'personas-discapacidad-pmr',
          urlGatsby: '/entradas/personas-discapacidad-pmr',
          urlWilson: '/es/entradas/personas-discapacidad-pmr',
        },
        {
          lang: 'en',
          section: 'personas-discapacidad-pmr',
          urlGatsby: '/en/tickets/disabled-visitors-pmr',
          urlWilson: '/en/tickets/disabled-visitors-pmr',
        },
        {
          lang: 'ca',
          section: 'personas-discapacidad-pmr',
          urlGatsby: '/ca/entrades/persones-discapacitat-pmr',
          urlWilson: '/ca/entrades/persones-discapacitat-pmr',
        },
        {
          lang: 'fr',
          section: 'personas-discapacidad-pmr',
          urlGatsby: '/fr/entrees/personnes-handicapees-pmr',
          urlWilson: '/fr/entrees/personnes-handicapees-pmr',
        },
        {
          lang: 'es',
          section: 'familia-numerosa',
          urlGatsby: '/entradas/familia-numerosa',
          urlWilson: '/es/entradas/familia-numerosa',
        },
        {
          lang: 'en',
          section: 'familia-numerosa',
          urlGatsby: '/en/tickets/large-families',
          urlWilson: '/en/tickets/large-families',
        },
        {
          lang: 'ca',
          section: 'familia-numerosa',
          urlGatsby: '/ca/entrades/familia-nombrosa',
          urlWilson: '/ca/entrades/familia-nombrosa',
        },
        {
          lang: 'fr',
          section: 'familia-numerosa',
          urlGatsby: '/fr/entrees/famille-nombreuse',
          urlWilson: '/fr/entrees/famille-nombreuse',
        },
      ],
    },
    ficha_hoteles: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/hoteles/{hotelname}/{section}',
          urlGatsby: '/hoteles/{hotelname}/{section}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/hotels/{hotelname}/{section}',
          urlGatsby: '/fr/hotels/{hotelname}/{section}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/hotels/{hotelname}/{section}',
          urlGatsby: '/ca/hotels/{hotelname}/{section}',
        },
        {
          lang: 'en',
          urlWilson: '/en/hotels/{hotelname}/{section}',
          urlGatsby: '/en/hotels/{hotelname}/{section}',
        },
      ],
      options: {
        hotelname: {
          es: [
            'gold-river',
            'port-aventura',
            'mansion-de-lucy',
            'caribe',
            'el-paso',
            'colorado-creek',
            'estacionamiento-autocaravanas',
          ],
          fr: [
            'gold-river',
            'port-aventura',
            'mansion-de-lucy',
            'caribe',
            'el-paso',
            'colorado-creek',
            'stationnement-camping-cars',
          ],
          ca: [
            'gold-river',
            'port-aventura',
            'mansion-de-lucy',
            'caribe',
            'el-paso',
            'colorado-creek',
            'estacionament-autocaravanes',
          ],
          en: [
            'gold-river',
            'port-aventura',
            'mansion-de-lucy',
            'caribe',
            'el-paso',
            'colorado-creek',
            'parking-caravaning',
          ],
        },
        section: {
          es: [
            '',
            'beneficios-exclusivos',
            'habitaciones',
            'restaurantes',
            'servicios',
            'situacion',
            'delion-experience',
          ],
          en: [
            '',
            'exclusive-benefits',
            'rooms',
            'restaurants',
            'services',
            'location',
            'delion-experience',
          ],
          fr: [
            '',
            'avantages-exclusifs',
            'chambres',
            'restaurants',
            'services',
            'localisation',
            'delion-experience',
          ],
          ca: [
            '',
            'beneficis-exclusius',
            'habitacions',
            'restaurants',
            'serveis',
            'ubicacio',
            'delion-experience',
          ],
        },
      },
      valid_combinations: [
        [0, 0],
        [0, 1],
        [0, 2],
        [0, 3],
        [0, 4],
        [0, 5],
        [0, 6],
        [1, 0],
        [1, 1],
        [1, 2],
        [1, 3],
        [1, 4],
        [1, 5],
        [1, 6],
        [2, 0],
        [2, 1],
        [2, 2],
        [2, 3],
        [2, 4],
        [2, 5],
        [3, 0],
        [3, 1],
        [3, 2],
        [3, 3],
        [3, 4],
        [3, 5],
        [4, 0],
        [4, 1],
        [4, 2],
        [4, 3],
        [4, 4],
        [4, 5],
        [5, 0],
        [5, 1],
        [5, 2],
        [5, 3],
        [5, 4],
        [5, 5],
        [5, 6],
        [6, 0],
        [6, 4],
        [6, 5],
      ],
      exceptions: [
        {
          hotelname: 'colorado-creek',
          section: 'chambres',
          lang: 'fr',
          urlWilson: '/fr/hoteles/colorado-creek/habitaciones',
          urlGatsby: '/fr/hotels/colorado-creek/chambres',
        },
        {
          hotelname: 'estacionament-autocaravanes',
          section: 'serveis',
          lang: 'ca',
          urlWilson: '/ca/hotels/parking-caravaning/serveis',
          urlGatsby: '/ca/hotels/estacionament-autocaravanes/serveis',
        },
      ],
    },
    ficha_actividades: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/indoor-experience/{section}',
          urlGatsby: '/indoor-experience/{section}',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/indoor-experience//{section}',
          urlGatsby: '/fr/indoor-experience/{section}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/indoor-experience/{section}',
          urlGatsby: '/ca/indoor-experience/{section}',
        },
        {
          lang: 'en',
          urlWilson: '/en/indoor-experience/{section}',
          urlGatsby: '/en/indoor-experience/{section}',
        },
      ],
      options: {
        section: {
          es: [
            '7-diferencias',
            'colorea-furius-baco',
            'manualidades-locomotora',
            'receta-crema-catalana',
            'receta-secreto-iberico',
            'puzle-mediterrania',
            '7-diferencias-polynesia',
            'colorea-polynesia',
            'puzle-polynesia',
            'manualidades-locomotora-2',
            'receta-solomillo-pina',
            'receta-mousse-coco',
            'tutuki-splash-virtual',
            'siente-pareos-bora-bora',
            'puzle-sesamoaventura',
            'colorea-sesamoaventura',
            '7-diferencias-sesamoaventura',
            'receta-cookies-chocolate',
            'virtual-tami-tami',
            'manualidades-locomotora-3',
            '7-diferencias-china',
            'shambhala-virtual',
            'colorea-china',
            'maqueta-gran-muralla',
            'puzle-china',
            'receta-ensalada-china',
            'receta-rollito-primavera-vegetariano',
            'colorea-mexico',
            '7-diferencias-mexico',
            'maqueta-hurakan-condor',
            'puzle-mexico',
            'ternera-pancho-villa',
            'nachos-guacamole',
            'vive-viva-mexico',
            'receta-nido-crias',
            'halloween-lampara',
            'halloween-caretas',
            'halloween-estampas-pies',
            'puzles-terrorificos',
            'receta-tierra-cementerio',
            'manteles-halloween',
            'sustos-sarcofago-espejo',
            'receta-dulces-ataudes',
            'halloween-cabeza-frasco',
            'manual-juguetes',
            'escape-room',
            'historias-terror',
            'puzle-online',
            'halloween-arana',
            'hilos-terrorificos-twitter',
            'lampara-calabaza-halloween',
          ],
          fr: [
            '7-differences',
            'coloriage-furius-baco',
            'loisirs-creatifs-locomotive',
            'recette-creme-catalane',
            'recette-secret-iberique',
            'puzzle-mediterrania',
            '7-differences-polynesia',
            'coloriage-polynesia',
            'puzzle-polynesia',
            'loisirs-creatifs-locomotive-2',
            'recette-faux-filet-ananas',
            'recette-mousse-noix-de-coco',
            'tutuki-splash-virtuel',
            'vivez-pareos-bora-bora',
            'puzzle-sesamoaventura',
            'coloriage-sesamoaventura',
            '7-differences-sesamoaventura',
            'recette-cookies-chocolat',
            'tami-tami-virtuel',
            'loisirs-creatifs-locomotive-3',
            '7-differences-china',
            'shambhala-virtuel',
            'coloriage-china',
            'maquette-grande-muraille',
            'puzzle-china',
            'recette-salade-chine',
            'recette-rouleaux-printemps-vegetarien',
            'coloriage-mexico',
            '7-differences-mexico',
            'maquette-hurakan-condor',
            'puzzle-mexico',
            'boeuf-pancho-villa',
            'nachos-guacamole',
            'vivez-viva-mexico',
            'receta-nido-crias',
            'halloween-lampe',
            'halloween-masques',
            'estampas-pies',
            'puzzles-effrayants',
            'recette-terre-cimetiere',
            'set-de-table-halloween',
            'blagues-sarcophage-miroir',
            'recette-brownies-cercueils',
            'halloween-tete-bocal',
            'manuel-jouets',
            'escape-game',
            'histoires-terrifiantes',
            'puzzle-en-ligne',
            'halloween-araignee',
            'discussions-terrifiantes-twitter',
            'lanterne-citrouille-halloween',
          ],
          ca: [
            '7-diferencies',
            'pinta-furius-baco',
            'treball-manual-locomotora',
            'recepta-crema-catalana',
            'recepta-secret-iberic',
            'trencaclosques-mediterrania',
            '7-diferencies-polynesia',
            'pinta-polynesia',
            'trencaclosques-polynesia',
            'treball-manual-locomotora-2',
            'recepta-filet-pinya',
            'recepta-mousse-coco',
            'tutuki-splash-virtual',
            'sent-pareos-bora-bora',
            'trencaclosques-sesamoaventura',
            'pinta-sesamoaventura',
            '7-diferencies-sesamoaventura',
            'recepta-cookies-xocolata',
            'tami-tami-virtual',
            'treball-manual-locomotora-3',
            '7-diferencies-china',
            'shambhala-virtual',
            'pinta-china',
            'maqueta-gran-muralla',
            'trencaclosques-china',
            'recepta-amanida-xinesa',
            'recepta-rotlle-de-primavera-vegetaria',
            'pinta-mexico',
            '7-diferencies-mexico',
            'maqueta-hurakan-condor',
            'trencaclosques-mexico',
            'vedella-pancho-villa',
            'nachos-guacamole',
            'viu-viva-mexico',
            'receta-nido-crias',
            'halloween-llum',
            'halloween-caretes',
            'halloween-estampes-peus',
            'trencaclosques-terrorifics',
            'recepta-terra-cementiri',
            'estovalles-halloween',
            'ensurt-sarcofag-mirall',
            'recepta-sarcofags-dolcos',
            'halloween-cap-dins-el-pot',
            'manual-joguines',
            'escape-room',
            'histories-de-terror',
            'trencaclosques-en-linia',
            'halloween-aranya',
            'fils-terrorifics-twitter',
            'carabassa-de-halloween-amb-llum',
          ],
          en: [
            '7-differences',
            'colour-furius-baco',
            'steam-train-crafts',
            'crema-catalana-recipe',
            'secreto-iberico-recipe',
            'mediterrania-puzzle',
            '7-differences-polynesia',
            'colour-polynesia',
            'polynesia-puzzle',
            'steam-train-crafts-2',
            'recipe-pork-loin-pineapple',
            'recipe-coconut-mousse',
            'virtual-tutuki-splash',
            'experience-pareos-bora-bora',
            'sesamoaventura-puzzle',
            'colour-sesamoaventura',
            '7-differences-sesamoaventura',
            'chocolate-cookies-recipe',
            'virtual-tami-tami',
            'steam-train-crafts-3',
            '7-differences-china',
            'virtual-shambhala',
            'colour-china',
            'model-great-wall',
            'china-puzzle',
            'chinese-salad-recipe',
            'recipe-vegetarian-spring-roll',
            'colour-mexico',
            '7-differences-mexico',
            'model-hurakan-condor',
            'mexico-puzzle',
            'pancho-villa-steak',
            'nachos-with-guacamole',
            'experience-viva-mexico',
            'receta-nido-crias',
            'halloween-lamp',
            'halloween-masks',
            'halloween-footprints',
            'terrifying-puzzles',
            'recipe-gourmet-graveyard',
            'halloween-placemats',
            'sarcophagus-mirror-scares',
            'recipe-sweet-coffins',
            'halloween-head-jar',
            'toys-manual',
            'escape-room',
            'scary-stories',
            'online-puzzle',
            'halloween-spider',
            'terrifying-twitter-threads',
            'halloween-pumpkin-lantern',
          ],
        },
      },
      exceptions: [],
    },

    ficha_wellness: {
      urls: [
        { lang: 'es', urlWilson: '/es/sport-wellness/{name}', urlGatsby: '/sport-wellness/{name}' },
        {
          lang: 'fr',
          urlWilson: '/fr/sport-wellness/{name}',
          urlGatsby: '/fr/sport-wellness/{name}',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/sport-wellness/{name}',
          urlGatsby: '/ca/sport-wellness/{name}',
        },
        {
          lang: 'en',
          urlWilson: '/en/sport-wellness/{name}',
          urlGatsby: '/en/sport-wellness/{name}',
        },
      ],
      options: {
        name: {
          es: ['gimnasio', 'beach-club', 'wellness'],
          en: ['gyms', 'beach-club', 'wellness'],
          fr: ['salles-de-sport', 'beach-club', 'wellness'],
          ca: ['gimnasios', 'beach-club', 'wellness'],
        },
      },
    },

    ficha_promociones: {
      urls: [
        { lang: 'es', urlWilson: '/es/promociones/{promo}', urlGatsby: '/promociones/{promo}' },
        { lang: 'fr', urlWilson: '/fr/promotions/{promo}', urlGatsby: '/fr/promotions/{promo}' },
        { lang: 'ca', urlWilson: '/ca/promocions/{promo}', urlGatsby: '/ca/promocions/{promo}' },
        { lang: 'en', urlWilson: '/en/promotions/{promo}', urlGatsby: '/en/promotions/{promo}' },
      ],
      options: {
        promo: {
          es: [
            'entrada-hotel-antiguo',
            'portaventura-park-entradas-2-dias-antiguo',
            'tickets-parques',
            'entradas-ferrariland',
            'tienda-online-antiguo',
            'descuento-entradas-antiguo',
            'noche-blanca',
            'la-isla-maldita',
            'tickets-halloween',
            'hotel-entrada-halloween-antiguo',
            'promocion-otono-antiguo',
            'hoteles-portaventura-navidad-nochebuena-antigua',
            'hoteles-portaventura-navidad-nochevieja-antigua',
            'entradas-navidad',
            'cyber-monday-antigua',
            'vacaciones-verano-antigua',
            'hotel-antiguo',
          ],
          fr: [
            'entree-hotel-antiguo',
            'portaventura-park-entrees-2-jours-antiguo',
            'entrees-parcs',
            'entrees-ferrariland',
            'boutique-en-ligne-antiguo',
            'reduction-entrees-antiguo',
            'nuit-blanche',
            'la-isla-maldita',
            'entrees-halloween',
            'hotel-entree-halloween-antiguo',
            'promotion-automne-antiguo',
            'hotels-portaventura-reveillon-noel-antigua',
            'hotels-portaventura-noel-reveillon-saint-sylvestre-antigua',
            'entrees-noel',
            'cyber-monday-antigua',
            'vacances-ete-antigua',
            'hotel-antiguo',
          ],
          ca: [
            'entrada-hotel-antiguo',
            'portaventura-park-entrades-2-dies-antiguo',
            'entrades-parcs',
            'entrades-ferrariland',
            'botiga-en-linia-antiguo',
            'descompte-entrades-antiguo',
            'nit-blanca',
            'la-isla-maldita',
            'entrades-halloween',
            'hotel-entrada-halloween-antiguo',
            'promocio-tardor-antiguo',
            'hotels-portaventura-nadal-nit-nadal-antigua',
            'hotels-portaventura-nadal-cap-any-antigua',
            'entrades-nadal',
            'cyber-monday-antigua',
            'vacances-estiu-antiguo',
            'hotel-antiguo',
          ],
          en: [
            'tickets-hotel-antiguo',
            'portaventura-park-tickets-2-days-antiguo',
            'tickets-parks',
            'tickets-ferrariland',
            'online-shop-antiguo',
            'discount-tickets-antiguo',
            'white-night',
            'la-isla-maldita',
            'tickets-halloween',
            'hotel-admission-halloween-antiguo',
            'promotion-autumn-antiguo',
            'hotels-portaventura-christmas-christmas-eve-antigua',
            'hotels-portaventura-christmas-newyears-eve-antigua',
            'tickets-christmas',
            'cyber-monday-antigua',
            'summer-holidays-antiguo',
            'hotel-antiguo',
          ],
        },
      },
    },
    ficha_calendar: {
      urls: [
        {
          lang: 'es',
          urlWilson: '/es/{item}/horarios-calendario',
          urlGatsby: '/{item}/horarios-calendario',
        },
        {
          lang: 'fr',
          urlWilson: '/fr/{item}/horaires-calendrier',
          urlGatsby: '/fr/{item}/horaires-calendrier',
        },
        {
          lang: 'ca',
          urlWilson: '/ca/{item}/horaris-calendari',
          urlGatsby: '/ca/{item}/horaris-calendari',
        },
        { lang: 'en', urlWilson: '/en/{item}/dates-times', urlGatsby: '/en/{item}/dates-times' },
      ],
      options: {
        item: {
          es: [
            '',
            'ferrari-land',
            'aquatic-park',
            'hoteles/gold-river',
            'hoteles/port-aventura',
            'hoteles/mansion-de-lucy',
            'hoteles/caribe',
            'hoteles/el-paso',
            'hoteles/colorado-creek',
            'hoteles/estacionamiento-autocaravanas',
          ],
          en: [
            '',
            'ferrari-land',
            'aquatic-park',
            'hotels/gold-river',
            'hotels/port-aventura',
            'hotels/mansion-de-lucy',
            'hotels/caribe',
            'hotels/el-paso',
            'hotels/colorado-creek',
            'hotels/parking-caravaning',
          ],
          ca: [
            '',
            'ferrari-land',
            'aquatic-park',
            'hotels/gold-river',
            'hotels/port-aventura',
            'hotels/mansion-de-lucy',
            'hotels/caribe',
            'hotels/el-paso',
            'hotels/colorado-creek',
            'hotels/estacionament-autocaravanes',
          ],
          fr: [
            '',
            'ferrari-land',
            'aquatic-park',
            'hotels/gold-river',
            'hotels/port-aventura',
            'hotels/mansion-de-lucy',
            'hotels/caribe',
            'hotels/el-paso',
            'hotels/colorado-creek',
            'hotels/stationnement-camping-cars',
          ],
        },
      },
      exceptions: [
        {
          item: 'hotels/colorado-creek',
          lang: 'en',
          urlWilson: '/en/hoteles/colorado-creek/horarios-calendario',
          urlGatsby: '/en/hotels/colorado-creek/dates-times',
        },
      ],
    },
  },
  ficha_entradas: {
    urls: [
      { lang: 'es', urlWilson: '/es/entradas{section}' },
      { lang: 'fr', urlWilson: '/fr/entrees{section}' },
      { lang: 'ca', urlWilson: '/ca/entrades{section}' },
      { lang: 'en', urlWilson: '/en/tickets{section}' },
    ],
    options: {
      section: [
        '-antiguo',
        '/ferrari-land-antiguo',
        '/caribe-aquatic-park-antiguo',
        '/best-sellers-antiguo',
      ],
    },
  },
};
