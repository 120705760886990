import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import DesktopMenu from './DesktopMenu/DesktopMenu';
import styles from './header.module.scss';
import MenuMobile from './MobileMenu/MobileMenu';

function Header({
  tt,
  locale,
  options,
  cmsMenu,
  cmsPromotions,
  cmsFooter,
  cmsExtraMenu,
  cmsLegalOptions,
  cmsContactOptions,
  altLanguages,
}) {
  const [isMenuDesktopOpen, setIsMenuDesktopOpen] = useState(false);

  return (
    <>
      <header className={styles.main}>
        <DesktopMenu
          tt={tt}
          locale={locale}
          options={options}
          cmsExtraMenu={cmsExtraMenu}
          cmsPromotions={cmsPromotions}
          cmsMenu={cmsMenu}
          altLanguages={altLanguages}
          handleIsMenuDesktopOpen={setIsMenuDesktopOpen}
          isMenuDesktopOpen={isMenuDesktopOpen}
        />
        <MenuMobile
          tt={tt}
          locale={locale}
          options={options}
          cmsFooter={cmsFooter}
          cmsPromotions={cmsPromotions}
          cmsExtraMenu={cmsExtraMenu}
          cmsMenu={cmsMenu}
          cmsLegalOptions={cmsLegalOptions}
          cmsContactOptions={cmsContactOptions}
          altLanguages={altLanguages}
        />
        <div className={isMenuDesktopOpen ? styles.isMenuDesktopOpen : ''} />
        {/* <InfoBar locale={locale} /> */}
      </header>
      <div className={styles.main__spacer} />
    </>
  );
}
export default injectIntl(Header);
