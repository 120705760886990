const newsLetterFetch = async (email, locale) => {
  const result = await fetch('https://www.portaventuraworld.com/newsletter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ email, locale }),
  })
    .then((emailConfirmation) => emailConfirmation.json())
    .catch((error) => error);

  return result;
};

export default newsLetterFetch;
