import ticketMenu from '../../../icons/svgIcons/ticket-menu.svg';
import parqueMenu from '../../../icons/svgIcons/parque-menu.svg';
import hotelMenu from '../../../icons/svgIcons/hotel-menu.svg';
import mapaMenu from '../../../icons/svgIcons/mapa-menu.svg';
import movilMenu from '../../../icons/svgIcons/movil-menu.svg';
import formularioMenu from '../../../icons/svgIcons/formulario-menu.svg';
import perfilMenu from '../../../icons/svgIcons/perfil-menu.svg';
import promotionMenu from '../../../icons/svgIcons/promotion-menu.svg';
import idiomaMenu from '../../../icons/svgIcons/idioma-menu.svg';
import flecha2Menu from '../../../icons/svgIcons/flecha2-menu.svg';
import buscadorMenu from '../../../icons/svgIcons/buscador-menu.svg';
import youtubeMenu from '../../../icons/svgIcons/youtube-menu.svg';
import twitterMenu from '../../../icons/svgIcons/twitter-menu.svg';
import facebookMenu from '../../../icons/svgIcons/facebook-menu.svg';
import instagramMenu from '../../../icons/svgIcons/instagram-menu.svg';
import telefonoMenu from '../../../icons/svgIcons/telefono-menu.svg';
import buscadorMenuBlack from '../../../icons/svgIcons/buscador-menu-black.svg';
import movilMenuBlack from '../../../icons/svgIcons/movil-menu-black.svg';
import idiomaMenuBlack from '../../../icons/svgIcons/idioma-menu-black.svg';

const iconsMenuList = {
  ticketMenu,
  parqueMenu,
  hotelMenu,
  mapaMenu,
  movilMenu,
  formularioMenu,
  perfilMenu,
  promotionMenu,
  idiomaMenu,
  flecha2Menu,
  buscadorMenu,
  twitterMenu,
  facebookMenu,
  youtubeMenu,
  instagramMenu,
  telefonoMenu,
  buscadorMenuBlack,
  movilMenuBlack,
  idiomaMenuBlack,
};

export default iconsMenuList;
