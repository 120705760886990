import PropTypes from 'prop-types';
import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './modal.module.scss';
import Icon from '../../../../helpers/icon';
import astridIcons from '../../Map/astridIcons';
import tt from '../../../../helpers/translation';

const ModalMap = ({ onClose, children, isMap }) => {
  return (
    <div className={styles.activeModal}>
      <div className={styles.modalBackground} tabIndex="0" />
      <div className={styles.modalContent}>
        <button className={styles.closeModal} aria-label="close" type="button" onClick={onClose} />
        {isMap ? (
          <div className={styles.mapDownload}>
            <Icon
              icon="get_app"
              width="20"
              height="20"
              class="icon-arrow"
              color="white"
              iconSet={astridIcons}
            />

            <a href="" download="image.png">
              {tt('Descargar Mapa', 'es')}
            </a>
          </div>
        ) : null}
        {children}
      </div>
    </div>
  );
};

ModalMap.propTypes = {
  onClose: PropTypes.bool.isRequired,
};

export default ModalMap;
