import { Link } from 'gatsby';
import React, { useState } from 'react';
import Menu from './Menu/Menu';
import styles from './mobileMenu.module.scss';
import SvgIcon from '../../common/SvgIcon';

const MobileMenu = ({
  tt,
  locale,
  options,
  cmsExtraMenu,
  cmsMenu,
  cmsFooter,
  cmsLegalOptions,
  cmsContactOptions,
  altLanguages,
}) => {
  const [open, setOpen] = useState(false);
  const ToogleMobile = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.main}>
      <Link to={`/${locale === 'es' ? '' : locale}`} className={styles.logoLink}>
        <SvgIcon icon="logoPaw" className={styles.logo} />
      </Link>
      <button type="button" className={styles.button} onClick={ToogleMobile}>
        <SvgIcon icon="burger" />
      </button>
      <Menu
        tt={tt}
        open={open}
        cmsFooter={cmsFooter}
        setOpen={ToogleMobile}
        locale={locale}
        options={options}
        cmsMenu={cmsMenu}
        cmsExtraMenu={cmsExtraMenu}
        altLanguages={altLanguages}
        cmsLegalOptions={cmsLegalOptions}
        cmsContactOptions={cmsContactOptions}
      />
    </div>
  );
};
export default MobileMenu;
