import React, { useState } from 'react';
import SvgIcon from '../../../common/SvgIcon';
import ContactInfo from './ContactInfo/ContactInfo';
import FooterMenu from './FooterMenu/FooterMenu';
import LegalOptions from './LegalOptionsMobile/LegalOptions';
import styles from './menu.module.scss';
import NavList from './NavBar/NavList';
import Socials from './SocialsMobile/Socials';
import UserMenu from './UserMenu/UserMenu';

const Menu = ({
  tt,
  open,
  setOpen,
  locale,
  cmsExtraMenu,
  options,
  cmsMenu,
  cmsFooter,
  cmsLegalOptions,
  cmsContactOptions,
  altLanguages,
}) => {
  const [currentSectionPosition, setCurrentSectionPosition] = useState(1);
  const isHidden = currentSectionPosition === 1;
  const isFirstPosition = isHidden;

  const handlePreviousSection = () => {
    if (isFirstPosition) return;
    setCurrentSectionPosition(currentSectionPosition - 1);
  };

  return (
    <div className={`${styles.root} ${open ? styles.isOpen : ''}`}>
      <div className={`${styles.wrapper} ${open ? styles.isOpen : ''}`}>
        <div className={styles.btnWrapper}>
          {!isHidden && (
            <button className={styles.btn} type="button" onClick={handlePreviousSection}>
              <SvgIcon icon="back" className={styles.btnIcon} />
            </button>
          )}
          <button type="button" className={`${styles.btn} ${styles.push}`} onClick={setOpen}>
            <SvgIcon icon="close" className={styles.btnIcon} />
          </button>
        </div>

        {isHidden && (
          <UserMenu
            tt={tt}
            cmsExtraMenu={cmsExtraMenu}
            locale={locale}
            setOpen={setOpen}
            options={options}
            altLanguages={altLanguages}
          />
        )}

        <NavList
          tt={tt}
          locale={locale}
          cmsMenu={cmsMenu}
          cmsExtraMenu={cmsExtraMenu}
          currentSectionPosition={currentSectionPosition}
          setCurrentSectionPosition={setCurrentSectionPosition}
        />
        {isHidden && (
          <div className={styles.footerSpacer}>
            <Socials tt={tt} locale={locale} />
            <ContactInfo tt={tt} locale={locale} cmsContactOptions={cmsContactOptions} />
            <FooterMenu tt={tt} locale={locale} cmsFooter={cmsFooter} />
            <LegalOptions locale={locale} cmsLegalOptions={cmsLegalOptions} />
            <div className={styles.copyRight}>
              <p>
                {tt(
                  '© Información legal 2022. PortAventura. Todos los derechos reservados.',
                  locale
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
