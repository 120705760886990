import React from 'react';
import { Link } from 'gatsby';
import styles from './NavList.module.scss';

const NavLink = ({ text, href, type, handleNextSection, section }) => {
  const link = {
    internal: (
      <Link className={styles.textMenu} to={href}>
        {text}
      </Link>
    ),
    newsite: (
      <a className={styles.textMenu} href={href}>
        {text}
      </a>
    ),
    external: (
      <a className={styles.textMenu} href={href}>
        {text}
      </a>
    ),
    root: (
      <button
        type="button"
        onClick={() => section && handleNextSection(section, text)}
        className={`${styles.textMenu} ${styles.btn}`}
      >
        {text}
      </button>
    ),
    level: (
      <button
        type="button"
        onClick={() => section && handleNextSection(section, text)}
        className={`${styles.textMenu} ${styles.btn}`}
      >
        {text}
      </button>
    ),
    error: () => {
      throw new Error(`Unknown menu link type ${type}`);
    },
  };

  return link[type] ? link[type] : link.error;
};

export default NavLink;
