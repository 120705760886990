/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react';
import { Link } from 'gatsby';

const FooterLink = ({ text, href, type, className }) => {
  const link = {
    internal: (
      <Link className={className} to={href}>
        {text}
      </Link>
    ),
    newsite: (
      <a className={className} href={href}>
        {text}
      </a>
    ),
    external: (
      <a className={className} href={href} rel="noopener noreferrer" target="_blank">
        {text}
      </a>
    ),
    cookie: (
      <a className={className} href="javascript: Cookiebot.renew()">
        {text}
      </a>
    ),
    error: () => {
      throw new Error(`Unknown menu link type ${type}`);
    },
  };

  return link[type] ? link[type] : link.error;
};

export default FooterLink;
