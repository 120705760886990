import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { addLocaleData, IntlProvider } from 'react-intl';
import caData from 'react-intl/locale-data/ca';
import enData from 'react-intl/locale-data/en';
import esData from 'react-intl/locale-data/es';
import frData from 'react-intl/locale-data/fr';
import ruData from 'react-intl/locale-data/ru';
import { hardcodedMenuToCMSMenu } from '../../helpers/harcodedMenuToCMSMenu';
import { hardcodedLanguageUrlsToCMSLanguageURLs } from '../../helpers/hardcodedLanguageUrlsToCMSLanguageURLs';
import tt from '../../helpers/translation';
import useCookiebotSetLanguage from '../../hooks/useCookiebotSetLanguage';
// Messages
import ca from '../../i18n/ca.json';
import en from '../../i18n/en.json';
import es from '../../i18n/es.json';
import fr from '../../i18n/fr.json';
import ru from '../../i18n/ru.json';
import favicon from '../../images/favicon.ico';
import '../../newStyles/global.scss';
import '../../styles/global.scss';
import FastAccesMenu from '../Auto/FastAccesMenu/FastAccesMenu';
import Jumbotron from '../Auto/Jumbotron/Jumbotron';
import {
  contactOptions,
  footerMenuInfo,
  hardcodedExtraEntriesMenu,
  hardcodedMenu,
  hardcodedPromotions,
  legalOptions,
} from '../NewMenu/data/getMenu';
import Footer from './DesktopFooter/Footer';
import Header from './Header';
import styles from './layout.module.scss';

const messages = {
  ca,
  en,
  es,
  fr,
  ru,
};

addLocaleData([...caData, ...enData, ...esData, ...frData, ...ruData]);

const BASE_URL = process.env.GATSBY_BASE_URL || 'https://www.portaventuraworld.com';

const Layout = ({ locale, children, pageName, options, jumboData, layoutFromOld }) => {
  const cmsMenu = hardcodedMenuToCMSMenu(hardcodedMenu, locale);
  const cmsExtraMenu = hardcodedMenuToCMSMenu(hardcodedExtraEntriesMenu, locale);
  const cmsFooter = hardcodedMenuToCMSMenu(footerMenuInfo, locale);
  const cmsPromotions = hardcodedMenuToCMSMenu(hardcodedPromotions, locale);
  const altLanguages = hardcodedLanguageUrlsToCMSLanguageURLs(pageName, options, locale);
  const cmsContactOptions = hardcodedMenuToCMSMenu(contactOptions, locale);
  const cmsLegalOptions = hardcodedMenuToCMSMenu(legalOptions, locale);

  useCookiebotSetLanguage(locale);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <>
        <Helmet>
          <link rel="icon" href={favicon} />
          <html lang={locale} className={layoutFromOld ? 'oldStyle' : 'newstyle'} />
          {altLanguages.map((e) => (
            <link
              key={e}
              rel="alternate"
              hrefLang={e.locale}
              href={`${BASE_URL}${e.destination}`}
            />
          ))}
        </Helmet>
        <Header
          tt={tt}
          locale={locale}
          cmsMenu={cmsMenu}
          cmsFooter={cmsFooter}
          cmsPromotions={cmsPromotions}
          cmsExtraMenu={cmsExtraMenu}
          cmsLegalOptions={cmsLegalOptions}
          cmsContactOptions={cmsContactOptions}
          altLanguages={altLanguages}
        />

        <div className={styles.jumbotronContainer}>
          {jumboData ? <Jumbotron {...jumboData} /> : <></>}
        </div>

        {children}

        <FastAccesMenu locale={locale} pageName={pageName} />
        <Footer tt={tt} locale={locale} cmsFooter={cmsFooter} cmsLegalOptions={cmsLegalOptions} />
      </>
    </IntlProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
