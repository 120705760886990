import PropTypes from 'prop-types';
import React from 'react';
import './RatingStars.scss';
import Icon from '../../helpers/icon';
import starIcons from './ratingstars-icons';

const Main = props => (
  <div className="stars">
    {Array.apply(null, { length: props.count }).map((e, i) => (
      <Icon
        icon="star-full"
        color={props.color}
        width={props.width ? props.width : '11'}
        height={props.width ? props.width : '11'}
        iconSet={starIcons}
      />
    ))}
  </div>
);

Main.propTypes = {
  count: PropTypes.number.isRequired,
  color: PropTypes.string,
};

Main.defaultProps = {
  count: 5,
};

export default Main;
