import tt from './translation';
import urlResolver from './urlResolver';

/**
 * @typedef {Object} HardcodedMenuInternalLink
 * @property {'internal'} HardcodedMenuInternalLink.type
 * @property {string} HardcodedMenuInternalLink.destination
 * @property {Array|undefined} HardcodedMenuLink.parameters
 * @property {string} HardcodedMenuLink.textToTranslate
 */
/**
 * @typedef {Object} HardcodedExternalLink
 * @property {'external'} HardcodedExternalLink.type
 * @property {string} HardcodedExternalLink.url
 * @property {string} HardcodedMenuLink.textToTranslate
 */

/**
 * @typedef {Object} HardcodedBanner
 * @property {'banner'} HardcodedBanners.type
 * @property {string} HardcodedBanners.destination
 * @property {string} HardcodedBanners.textToTranslate
 * @property {string} HardcodedBanners.infoToTranslate
 * @property {Array} HardcodedBanners.parameters
 * @property {string} HardcodedBanners.src
 */

/**
 * @typedef {Array<HardcodedBanner>} HardcodedBanners
 */

/**
 * @type HardcodedBanners
 */

/**
 * @typedef {Object} HardcodedMenuLevel
 * @property {'level'} HardcodedMenuLevel.type
 * @property {string} HardcodedMenuLevel.textToTranslate
 * @property {string} HardcodedMenuLevel.icon
 * @property {Array<HardcodedMenuInternalLink|HardcodedExternalLink|HardcodedMenuLevel>} HardcodedMenuLevel.children
 */

/**
 * @typedef {Array<HardcodedMenuInternalLink|HardcodedExternalLink|HardcodedMenuLevel|HardcodedBanners>} HardcodedMenu
 */

/**
 * @type HardcodedMenu
 */

/**
 * @typedef {Object} CMSMenuEntry
 * @property {'internal'|'external'} CMSMenuEntry.type
 * @property {string} CMSMenuEntry.href
 * @property {string} CMSMenyEntry.text
 * @property {Array<CMSMenuEntry>} CMSMenuEntry.children
 * @property {}
 */
/**
 * @typedef {Array<CMSMenuEntry>} CMSMenu
 */

/**
 * @param {HardcodedMenu} menu
 * @param {any} urls
 * @param {string} locale locale actual
 * @returns {CMSMenu}
 */
export const hardcodedMenuToCMSMenu = (menu, locale) => {
  return menu.map((entry) => {
    switch (entry.type) {
      case 'internal':
        return {
          text: tt(entry.textToTranslate, locale),
          type: entry.type,
          href: urlResolver.resolve(locale, entry.destination, entry.parameters),
          ...(entry.icon && { icon: entry.icon }),
          ...(entry.banners
            ? { banners: hardcodedMenuToCMSMenu(entry.banners, locale) }
            : { banners: [] }),
          ...(entry.classname && { classname: entry.classname }),
        };
      case 'newsite':
        return {
          text: tt(entry.textToTranslate, locale),
          type: entry.type,
          href: tt(entry.destination, locale),
          ...(entry.icon && { icon: entry.icon }),
          ...(entry.banners
            ? { banners: hardcodedMenuToCMSMenu(entry.banners, locale) }
            : { banners: [] }),
        };
      case 'external':
        return {
          text: tt(entry.textToTranslate, locale),
          type: entry.type,
          href: tt(entry.destination, locale),
          ...(entry.icon && { icon: entry.icon }),
          ...(entry.banners
            ? { banners: hardcodedMenuToCMSMenu(entry.banners, locale) }
            : { banners: [] }),
        };
      case 'root':
      case 'level':
        return {
          text: tt(entry.textToTranslate, locale),
          type: entry.type,
          children: hardcodedMenuToCMSMenu(entry.children, locale),
          ...(entry.icon && { icon: entry.icon }),
          ...(entry.banners
            ? { banners: hardcodedMenuToCMSMenu(entry.banners, locale) }
            : { banners: [] }),
        };
      case 'cookie':
        return {
          text: tt(entry.textToTranslate, locale),
          type: entry.type,
        };
      case 'banner':
        return {
          type: entry.type,
          href: urlResolver.resolve(locale, entry.destination, entry.parameters),
          src: entry.src,
          text: tt(entry.textToTranslate, locale),
          info: tt(entry.infoToTranslate, locale),
        };
      case 'newsitebanner':
        return {
          type: entry.type,
          href: tt(entry.destination, locale),
          src: entry.src,
          text: tt(entry.textToTranslate, locale),
          info: tt(entry.infoToTranslate, locale),
        };
      case 'blank':
        return { type: entry.type, href: null, banner: null };
      default:
        throw new Error('Unknown entry type');
    }
  });
};
