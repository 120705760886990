module.exports = {

  sanitize(url) {
    const sanitized = url;


    return sanitized;
  },


  /** this function gets
  * pattern -> a url like this: entradas/{token1}/{token2}
  * options -> an array describing valid tokens
  *          {token1: ['value1', 'value2'], token2: ['value3']}
  * selection -> a list of values ['value1','value3']
  *
  * It tries to match the values in selection with the current pattern and valid options
  * reutrns the mathed url or '/' in case of not matching
  */
  findDestination(pattern, options, selection) {
    const regex = /[^\{]*\{([^\}]+)/gm;
    let m;
    const tokens = [];
    // eslint-disable-next-line no-cond-assign
    while ((m = regex.exec(pattern)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex += 1;
      }
      tokens.push(m[1]);
    }
    const numberOfTokens = tokens.length;
    const resolvedTokens = [];

    tokens.forEach((e, index) => {
      if (options[e].includes(selection[index])) resolvedTokens.push(selection[index]);
    });
    if (resolvedTokens.length !== numberOfTokens) return '/';
    let finalPattern = pattern;
    tokens.forEach((e, index) => {
      const startReplace = finalPattern.indexOf('{');
      const endReplace = finalPattern.indexOf('}') + 1;
      finalPattern = finalPattern.substring(0, startReplace) + selection[index] + finalPattern.substring(endReplace);
    });
    return finalPattern
      .replace(/\/+/g, '/') // replace consecutive slashes with a single slash
      .replace(/\/+$/, ''); // remove trailing slashes
  },


};
