import React from 'react';
import styles from './footer.module.scss';
import FooterNewsletter from './FooterNewsletter';
import FooterMenu from './FooterMenu';
import FooterSocials from './FooterSocials';
import FooterLegal from './FooterLegal';

const Footer = ({ tt, locale, cmsFooter, cmsLegalOptions }) => (
  <footer className={styles.main}>
    <div className={styles.content}>
      <FooterNewsletter tt={tt} locale={locale} />
      <FooterMenu tt={tt} locale={locale} cmsFooter={cmsFooter} />
      <FooterSocials tt={tt} locale={locale} />
      <FooterLegal tt={tt} locale={locale} cmsLegalOptions={cmsLegalOptions} />
    </div>
  </footer>
);

export default Footer;
