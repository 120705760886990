import React from 'react';
import SvgIcon from '../../../../common/SvgIcon';
import { socials } from '../../../constants';
import styles from './socials.module.scss';

const Socials = ({ tt, locale }) => (
  <div className={styles.main}>
    <ul className={styles.containerSocials}>
      {socials.map(({ destination, icon }) => (
        <li key={destination}>
          <a
            href={tt(destination, locale)}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialLink}
          >
            <SvgIcon icon={icon} />
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default Socials;
