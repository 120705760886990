import React from 'react';
import FooterLink from './FooterLink';
import styles from './footer.module.scss';

const FooterLegal = ({ tt, locale, cmsLegalOptions }) => (
  <section className={styles.footerLegal}>
    <small className={styles.legalText}>
      {`${tt('© Información legal 2022. PortAventura. Todos los derechos reservados.', locale)} `}
    </small>
    <ul className={styles.legalMenuList}>
      {cmsLegalOptions.map((_legalOption, index, reverse) => {
        const { text, href, type } = reverse[reverse.length - 1 - index];
        return (
          <FooterLink
            key={text}
            text={tt(text, locale)}
            href={href}
            type={type}
            className={styles.legalMenuText}
          />
        );
      })}
    </ul>
  </section>
);

export default FooterLegal;
