import { Link } from 'gatsby';
import React from 'react';
import SvgIcon from '../../../../common/SvgIcon';
import styles from './contacts-info.module.scss';

const ContactInfo = ({ tt, locale, cmsContactOptions }) => (
  <ul className={styles.main}>
    <li className={`${styles.contactItem} ${styles.contactItemLink}`}>
      <div className={locale !== 'fr' ? styles.isHidden : ''}>
        <p className={styles.telephoneTitle}>Notre équipe parle français</p>
        <SvgIcon icon="telefono" className={styles.contactIcon} />
        <a
          href="tel:+34977779090"
          className={styles.contactLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {tt('+34 977 77 90 90', locale)}
        </a>
      </div>
    </li>

    {cmsContactOptions.map((contactOption) => (
      <li key={contactOption.text} className={`${styles.contactItem} ${styles.contactItemLink}`}>
        <SvgIcon icon="formularioMenu" className={styles.contactIcon} />

        <Link className={styles.contactLink} to={tt(contactOption.href, locale)}>
          {contactOption.text}
        </Link>
      </li>
    ))}
  </ul>
);

export default ContactInfo;
