import { Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import SvgIcon from '../../../common/SvgIcon';
import ChangeLanguageLinks from '../ChangeLanguageLinks';
import SearchMenu from '../SearchMenu/SearchMenu';
import styles from './navBar.module.scss';

const NavLink = ({ type, href, text, className }) => {
  switch (type) {
    case 'banner':
    case 'internal':
      return (
        <Link to={href} className={className ? styles.itemSectionText : ''}>
          {text}
        </Link>
      );
    case 'newsitebanner':
      return <a href={href}>{text}</a>;
    case 'newsite':
      return (
        <a href={href} className={className ? styles.itemSectionText : ''}>
          {text}
        </a>
      );
    case 'external':
      return (
        <a
          href={href}
          rel="noopener noreferrer"
          target="_blank"
          className={className ? styles.itemSectionText : ''}
        >
          {text}
        </a>
      );
    case 'root':
    case 'level':
      return <p>{text}</p>;
    default:
      throw new Error(`Unknown menu link type ${type}`);
  }
};

const findInMenu = (text, menu) =>
  menu.reduce((acc, elem) => {
    const found = elem.children ? elem.children.filter((child) => child.text === text) : [];
    return [...acc, ...found];
  }, []);

const searchIn = '//www.google.com/search';

const printSublock = (parent, subelements, currentSection, setNextSection, setNextSection2) => {
  return (
    subelements &&
    (subelements.type === 'root' || subelements.type === 'level') &&
    subelements.children.map((props) => (
      <li
        key={props.text}
        className={`${styles.itemBoard} ${currentSection === props.text ? styles.isSelected : ''}
         ${parent === subelements.text ? '' : styles.isHidden}
        `}
        onMouseEnter={() => {
          setNextSection2 &&
            setNextSection2(props.type === 'level' && props.children ? props.text : '');
          setNextSection &&
            setNextSection(props.type === 'level' && props.children ? props.text : '');
        }}
      >
        <div className={styles.textMenu}>
          <NavLink text={props.text} href={props.href} type={props.type} />
        </div>
        {props.type === 'level' && <SvgIcon icon="chevronRight" className={styles.submenuIcon} />}
      </li>
    ))
  );
};

const printBanner = (printedSection, banners, position, mainMenuSelection) => {
  return (
    <li
      key={position}
      className={`${styles.banner} ${!printedSection && mainMenuSelection ? '' : styles.isHidden}`}
    >
      <div
        style={{
          backgroundImage: `url(${banners[position]?.src})`,
        }}
        className={styles.bannerImg}
      />
      <div className={styles.textImg}>
        {banners[position] && (
          <NavLink
            text={banners[position]?.text}
            href={banners[position].href}
            type={banners[position].type}
          />
        )}
        <p>{banners[position]?.info}</p>
      </div>
    </li>
  );
};

const NavBar = ({
  tt,
  cmsMenu,
  cmsExtraMenu,
  locale,
  options,
  altLanguages,
  handleIsMenuDesktopOpen,
  isMenuDesktopOpen,
}) => {
  const [mainMenuSelection, setMainMenuSelection] = useState('');
  const [firstBlockSelection, setFirstBlockSelection] = useState('');
  const [secondBlockSelection, setSecondBlockSelection] = useState('');

  const firstBlock = cmsMenu;

  const secondBlock = firstBlock.flatMap((elem) =>
    elem.type === 'level' || elem.type === 'root' ? elem.children : []
  );
  const thirdBlock = secondBlock.flatMap((elem) =>
    elem && (elem.type === 'level' || elem.type === 'root') ? elem.children : []
  );

  const secondBlockSelected = findInMenu(firstBlockSelection, cmsMenu)[0];
  const thirdBlockSelected =
    secondBlockSelected && findInMenu(secondBlockSelection, [secondBlockSelected])[0];

  const [forceCloseMenu, setForceCloseMenu] = useState(false);
  useEffect(() => {
    if (!isMenuDesktopOpen) {
      setForceCloseMenu(true);
      setTimeout(() => {
        setForceCloseMenu(false);
      }, 0);
    }
  }, [isMenuDesktopOpen]);

  const menuClicked = useCallback(
    (event) => {
      if (event.target instanceof Element) {
        switch (event.target.tagName) {
          case 'A':
            handleIsMenuDesktopOpen(false);
            return;
          default:
            // Relay the click to the closest a inside this element
            event.target.querySelector('a')?.click();
        }
      }
    },
    [handleIsMenuDesktopOpen]
  );

  const [isOpen, setOpen] = useState(false);

  const toggleOpen = (e) => {
    setOpen((current) => !current);
    e.preventDefault();
  };

  return (
    <nav className={styles.main} onClick={menuClicked} aria-hidden="true">
      <ul className={styles.list}>
        <li>
          <Link type="named" to={`/${locale === 'es' ? '' : locale}`}>
            <SvgIcon icon="logoPaw" className={styles.logo} />
          </Link>
        </li>

        {cmsMenu.map(({ text, type, href, classname, banners }) => (
          <li
            key={text}
            className={`${styles.itemSection} ${classname ? styles[classname] : ''}`}
            onMouseEnter={() => {
              handleIsMenuDesktopOpen(type === 'root');
              setMainMenuSelection(text);
              setFirstBlockSelection('');
              setSecondBlockSelection('');
            }}
            onMouseLeave={() => handleIsMenuDesktopOpen(false)}
          >
            <NavLink text={text} href={href} type={type} className={styles.itemSectionText} />
            {type === 'root' && <SvgIcon icon="chevronDown" className={styles.sectionIcon} />}
            {type === 'root' && (
              <div className={`${styles.container} ${forceCloseMenu ? styles.close : ''}`}>
                <div className={styles.board}>
                  {/** First block */}

                  <ul className={styles.listBoard}>
                    {firstBlock.map((elem) =>
                      printSublock(
                        mainMenuSelection,
                        elem,
                        firstBlockSelection,
                        setFirstBlockSelection,
                        setSecondBlockSelection
                      )
                    )}
                  </ul>
                  {/** Second block */}

                  <ul className={styles.listBoard}>
                    {secondBlock.map((subElem) =>
                      printSublock(
                        firstBlockSelection,
                        subElem,
                        secondBlockSelection,
                        setSecondBlockSelection
                      )
                    )}
                    {printBanner(secondBlockSelected, banners, 0, mainMenuSelection)}
                  </ul>
                  {/** Third block */}

                  <ul className={styles.listBoard}>
                    {thirdBlock.map((subElem) => printSublock(secondBlockSelection, subElem))}
                    {banners[1] && printBanner(thirdBlockSelected, banners, 1, mainMenuSelection)}
                  </ul>
                </div>
              </div>
            )}
          </li>
        ))}

        {/* ONLY DESKTOP ENTRY ----> */}
        {/* <li className={styles.sectionBlock}>
          <div className={styles.sectionTitle}>
            <Link className={styles.textMenu} to={cmsExtraMenu[2].href}>
              {cmsExtraMenu[2].text}
            </Link>
          </div>
        </li> */}
        {/* <---- */}
      </ul>

      <ul className={styles.userMenu}>
        <li>
          <SearchMenu locale={locale} searchIn={searchIn} tt={tt} />
        </li>
        <li>
          <a href={tt('/auth/login', locale)} className={styles.areaPriv}>
            <SvgIcon icon="person" className={styles.areaPrivIcon} />
          </a>
        </li>
        <li
          style={{
            display: 'none',
          }}
        >
          <Link to={cmsExtraMenu[0].href} className={`${styles.menuLink} ${styles.hidden}`}>
            <SvgIcon icon="person" className={styles.svgIcons} />
          </Link>
        </li>
        <li>
          <div className={styles.langSelector}>
            <SvgIcon
              icon="internet"
              className={`${styles.svgIcons} ${isOpen ? styles.isOpen : ''}`}
            />
            <button className={styles.langSelectorButton} type="button" onClick={toggleOpen}>
              <p className={`${styles.langSelectorText} ${isOpen ? styles.isOpen : ''}`}>
                {locale}
              </p>
              <SvgIcon
                icon="chevronDown"
                className={`${styles.svgIcons} ${isOpen ? styles.isOpen : ''} ${
                  isOpen ? styles.chevron : ''
                }`}
              />
            </button>

            {isOpen && (
              <div className={styles.langSelectorList}>
                <ChangeLanguageLinks
                  locale={locale}
                  options={options}
                  altLanguages={altLanguages}
                />
              </div>
            )}
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
