import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';

const urlResolver = require('../../helpers/urlResolver');

class PawLink extends React.PureComponent {
  render() {
    const {
      type,
      destination,
      parameters,
      children,
      className,
      id,
      rel,
      ariaLabel,
      itemprop,
      translatedParameters,
      targetLanguage,
      intl,
      state,
      onClick,
    } = this.props;

    const lang = targetLanguage || intl.locale;

    // If this var is set to true, all links are created with regular anchors and not with gatsby link
    if (type === 'anchor') {
      return (
        <a
          onClick={onClick}
          href={destination}
          className={className || ''}
          id={id || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    }

    if (type === 'anchor2') {
      return (
        <a
          onClick={onClick}
          href={destination}
          className={className || ''}
          id={id || ''}
          rel={rel || 'noopener noreferrer'}
        >
          {children}
        </a>
      );
    }

    if (type === 'unknown') {
      if (
        destination &&
        (destination.includes('book.portaventuraworld.com') ||
          destination.includes('search.portaventuraworld.com'))
      ) {
        return (
          <a
            onClick={onClick}
            href={destination}
            aria-label={ariaLabel || ''}
            className={className || ''}
            id={id || ''}
            target="_blank"
            rel={rel || 'noopener noreferrer'}
          >
            {children}
          </a>
        );
      }
      return (
        <a
          onClick={onClick}
          href={destination || '#missing-link'}
          aria-label={ariaLabel || undefined}
          className={className || undefined}
          id={id || undefined}
          rel={rel || 'noopener noreferrer'}
        >
          {children}
        </a>
      );
    }

    if (type === 'named') {
      let urlDestination;
      if (lang === 'sv' || lang === 'cn') {
        urlDestination = translatedParameters
          ? urlResolver.resolveLocalized(lang, destination, 'en', parameters)
          : urlResolver.resolve('en', destination, parameters);
      } else {
        urlDestination = translatedParameters
          ? urlResolver.resolveLocalized(lang, destination, intl.locale, parameters)
          : urlResolver.resolve(lang, destination, parameters);
      }

      return (
        <Link
          onClick={onClick}
          to={urlDestination}
          className={className || undefined}
          id={id || undefined}
          rel={rel || 'noopener noreferrer'}
          itemProp={itemprop || undefined}
        >
          {children}
        </Link>
      );
    }
    if (type === 'menu-item') {
      const url = typeof window !== 'undefined' ? window.location.origin : '';
      let urlDestination;
      if (lang === 'sv' || lang === 'cn') {
        urlDestination = translatedParameters
          ? urlResolver.resolveLocalized(lang, destination, 'en', parameters)
          : urlResolver.resolve('en', destination, parameters);
      } else {
        urlDestination = translatedParameters
          ? urlResolver.resolveLocalized(lang, destination, intl.locale, parameters)
          : urlResolver.resolve(lang, destination, parameters);
      }

      return (
        <a
          onClick={onClick}
          href={url + urlDestination}
          className={className || ''}
          id={id || ''}
          rel={rel || 'noopener noreferrer'}
        >
          {children}
        </a>
      );
    }

    if (type === 'gatsby') {
      return (
        <Link
          onClick={onClick}
          to={destination}
          className={className || ''}
          id={id || ''}
          rel={rel || 'noopener noreferrer'}
        >
          {children}
        </Link>
      );
    }

    if (type === 'breadcrumb') {
      return (
        <Link
          to={destination}
          className={className || ''}
          id={id || ''}
          rel={rel || 'noopener noreferrer'}
          itemProp="item"
          onClick={onClick}
        >
          {' '}
          {children}{' '}
        </Link>
      );
    }
    if (type === 'cookie') {
      return (
        <a className={className || ''} href="javascript: Cookiebot.renew()">
          {children}
        </a>
      );
    }

    return (
      <Link
        onClick={onClick}
        to="/"
        className={className || ''}
        id={id || ''}
        rel={rel || 'noopener noreferrer'}
      >
        {' '}
        {children}{' '}
      </Link>
    );
  }
}

export default injectIntl(PawLink);
