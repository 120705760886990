
const promotionsIcon = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M1024 397.050l-353.78-51.408-158.22-320.582-158.216 320.582-353.784 51.408 256 249.538-60.432 352.352 316.432-166.358 316.432 166.358-60.434-352.352 256.002-249.538z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: [
          'star-full',
        ],
        defaultCode: 58886,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 15,
        order: 1014,
        ligatures: '',
        prevSize: 32,
        code: 58886,
        name: 'star-full',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 14,
    },

  ],
};

export default promotionsIcon;
