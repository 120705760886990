import urlResolver from './urlResolver';

/**
 * @typedef {Object} AltLanguage
 * @property {string} AltLanguage.locale
 * @property {string} AltLanguage.href
 * @property {}
 */
export const hardcodedLanguageUrlsToCMSLanguageURLs = (pageName, options, locale) => {
  if (!pageName) return [];

  return urlResolver.findAlternateLanguages(pageName).map((e) => ({
    locale: e,
    destination: `${urlResolver.resolveLocalized(e, pageName, locale, options)}`,
  }));
};
