import React from 'react';
import FooterLink from '../FooterMenu/FooterLink';
import styles from './legalOptions.module.scss';

const LegalOptions = ({ cmsLegalOptions }) => (
  <ul className={styles.root}>
    {cmsLegalOptions.map((option) => (
      <FooterLink
        className={styles.link}
        key={option.text}
        text={option.text}
        href={option.href}
        type={option.type}
      />
    ))}
  </ul>
);

export default LegalOptions;
