import React, { useCallback, useRef, useState } from 'react';
import SvgIcon from '../../../common/SvgIcon';

import SearchInput from './SearchInput';
import styles from './searchMenu.module.scss';

const SearchMenu = ({ searchIn, locale, tt }) => {
  const [isSearching, _setSearching] = useState(false);
  const searchMenuInput = useRef(null);
  const [searchValue, _setSearchValue] = useState('');
  const setSearchValue = useCallback(
    (event) => _setSearchValue(event.currentTarget.value),
    [_setSearchValue]
  );

  const closeSearch = () => {
    _setSearching(false);
    _setSearchValue('');
    return setTimeout(() => document.getElementById('searchMenu-input'), 0);
  };

  const openSearch = () => {
    _setSearching(true);
    return setTimeout(() => {
      const searchMenuInputElement = searchMenuInput.current;
      if (searchMenuInputElement) {
        searchMenuInputElement.focus();
      }
    }, 0);
  };

  return (
    <div className={styles.main}>
      <button
        type="button"
        onClick={isSearching ? closeSearch : openSearch}
        className={styles.button}
      >
        <SvgIcon icon="buscador" className={styles.icon} />
        <p className={styles.title}>{tt('Buscador', locale)}</p>
      </button>

      <div className={isSearching ? styles.blacklayer : ''}>
        {isSearching && (
          <SearchInput
            searchIn={searchIn}
            closeSearch={closeSearch}
            searchMenuInput={searchMenuInput}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        )}
      </div>
    </div>
  );
};

export default SearchMenu;
