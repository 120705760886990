import React from 'react';
import { injectIntl } from 'react-intl';
import tt from '../../../helpers/translation';
import Pawlink from '../../PawLink/PawLink';
import SvgIcon from '../../common/SvgIcon';

import styles from './fastAccesMenu.module.scss';

const FastAccesMenu = (locale) => {
  const entradasArray = ['entradas', 'entradas_fl', 'entradas_bs', 'entradas_cap'];
  return (
    <div className={styles.main}>
      <div className={styles.menu}>
        <Pawlink
          className={`${styles.itemMenu} ${
            entradasArray.includes(locale.pageName) ? styles.selected : null
          }`}
          type="unknown"
          destination={tt('/entradas', locale.locale)}
        >
          <SvgIcon icon="ticket" />
          {tt('Entradas', locale)}
        </Pawlink>
        <Pawlink
          className={`${styles.itemMenu} ${
            locale.pageName === 'ficha_promo_hotel' ? styles.selected : null
          }`}
          type="named"
          destination="ficha_promo_hotel"
        >
          <SvgIcon icon="hotel" />
          {tt('Entradas + hotel', locale)}
        </Pawlink>
        <Pawlink
          className={`${styles.itemMenu} ${
            locale.pageName === 'distr_promociones' ? styles.selected : null
          }`}
          type="named"
          destination="distr_promociones"
        >
          <SvgIcon icon="promotion" />
          {tt('Promociones', locale)}
        </Pawlink>

        <Pawlink
          className={`${styles.itemMenu} ${
            locale.pageName === 'ficha_calendar' ? styles.selected : null
          }`}
          type="named"
          destination="ficha_calendar"
          parameters={['']}
        >
          <SvgIcon icon="calendar" />
          {tt('Horarios', locale)}
        </Pawlink>
      </div>
    </div>
  );
};
export default injectIntl(FastAccesMenu);
