export const socials = [
  {
    icon: 'twitter',
    text: 'X',
    destination: 'https://X.com/Portaventura_ES',
  },
  {
    icon: 'facebook',
    text: 'Facebook',
    destination: 'https://www.facebook.com/portaventuraoficial',
  },
  {
    icon: 'youtube',
    text: 'YouTube',
    destination: 'https://www.youtube.com/user/PortAventuraTV',
  },
  {
    icon: 'instagram',
    text: 'Instagram',
    destination: 'https://www.instagram.com/portaventuraworld/?hl=es',
  },
  {
    icon: 'tiktok',
    text: 'TikTok',
    destination: 'https://www.tiktok.com/@portaventuraworld?',
  },
];
