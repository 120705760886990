import React from 'react';
import PropTypes from 'prop-types';

const getPath = (iconSet, iconName) => {
  const icon = iconSet.icons.find(icons => icons.properties.name === iconName);
  if (icon) {
    return icon.icon.paths.join(' ');
  }
  return '';
};

const Icon = ({ width, height, icon, color, iconSet, viewB, className }) => (
  <svg
    width={width}
    height={height}
    className={className || icon}
    viewBox={`${viewB || 0} 0 1024 1024`}
  >
    <path d={getPath(iconSet, icon)} fill={color} />
  </svg>
);

Icon.propTypes = {
  iconSet: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  viewB: PropTypes.number,
};

Icon.defaultProps = {
  width: 22,
  height: 22,
  color: '#000',
  viewB: 0,
};

export default Icon;
