import { Link } from 'gatsby';
import React, { useState } from 'react';
import SvgIcon from '../../common/SvgIcon';
import styles from './footer.module.scss';
import newsLetterFetch from './newsLetterFetch';
import urlResolver from '../../../helpers/urlResolver';

const FooterNewsletter = ({ tt, locale }) => {
  const [email, setEmail] = useState('');

  const handleOnSubmit = () => {
    newsLetterFetch(email, locale);
  };

  return (
    <section className={styles.newsletter}>
      <Link to="/">
        <SvgIcon icon="logoPaw" className={styles.logo} />
      </Link>
      <form className={styles.form} onSubmit={handleOnSubmit}>
        <label htmlFor="email">
          <div className={styles.message}>
            <p>
              {tt(
                'Deja aquí tu correo electrónico para saber todo lo que pasa en el resort',
                locale
              )}
            </p>
          </div>
          <input
            type="email"
            id="email"
            value={email}
            className={styles.input}
            onChange={(event) => setEmail(event.target.value)}
            placeholder={tt('Escribe tu correo electrónico aquí', locale)}
          />
        </label>
        <input type="submit" value={tt('Enviar', locale)} className={styles.button} />
        <label htmlFor="checkbox" className={styles.privacyConditions}>
          <input
            type="checkbox"
            id="checkbox"
            className={styles.checkbox}
            defaultChecked={false}
            required
          />
          <div className={styles.privacyConditionsMessage}>
            <p>
              {tt('He leído la', locale)}
              <a
                href={tt(
                  'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/016/387/original/Pol%C3%ADtica_de_privacidad_newsletter_ES.pdf',
                  locale
                )}
              >
                {` ${tt('Política de protección de datos', locale)} `}
              </a>
              {tt('y quiero recibir información comercial de PortAventura World', locale)}
            </p>
          </div>
        </label>
      </form>
    </section>
  );
};

export default FooterNewsletter;
