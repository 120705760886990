import React, { useState } from 'react';
import chevronDownBlue from '../../../icons/svgIcons/chevron_down_blue.svg';
import styles from './collapsibleDiv.module.scss';

const CollapsibleDiv = ({ title, icon, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${styles.main} ${isOpen ? styles.isOpen : ''}`}>
      <button
        type="button"
        className={styles.titleWrapper}
        onClick={() => setIsOpen((current) => !current)}
      >
        <h2 className={styles.title}>{title}</h2>
        {icon && <img className={styles.chevronIcon} src={chevronDownBlue} alt="chevronDownBlue" />}
      </button>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default CollapsibleDiv;
