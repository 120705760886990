/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  canonicalLink,
  pathname,
  imageRich,
  disabled,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            baseUrl: url
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  lang = lang || 'es';
  let noindex = {};
  if (disabled) {
    noindex = {
      property: 'robots',
      content: `noindex`,
    };
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      link={
        canonicalLink && [
          {
            rel: 'canonical',
            key: canonicalLink,
            ref: canonicalLink,
          },
        ]
      }
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:site',
          content: '@Portaventura_ES',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content:
            imageRich ||
            'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/001/073/original/PAP-SA_PANO_NONE_DIU_0615_014.jpg',
        },
        {
          name: 'twitter:image',
          content:
            imageRich ||
            'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/001/073/original/PAP-SA_PANO_NONE_DIU_0615_014.jpg',
        },
        {
          property: 'og:url',
          content: `${site.siteMetadata.baseUrl}${pathname}`,
        },
        noindex,
      ]
        .concat(
          keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : []
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
