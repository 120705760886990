import { useLayoutEffect } from 'react';

const useCookiebotSetLanguage = (locale) => {
  const cookieBotSetLanguage = () => {
    const element = document.getElementById('Cookiebot');
    // eslint-disable-next-line no-undef
    if (element && typeof Cookiebot !== 'undefined' && 'init' in Cookiebot) {
      element.setAttribute('data-culture', locale.toUpperCase());
      // eslint-disable-next-line no-undef
      Cookiebot.init();
      // eslint-disable-next-line no-undef
      if (typeof CookiebotDialog !== 'undefined' && CookiebotDialog.visible) {
        // eslint-disable-next-line no-undef
        Cookiebot.show();
      }
    }
  };

  useLayoutEffect(() => {
    if (document.readyState === 'complete') {
      cookieBotSetLanguage();
    } else {
      window.addEventListener('load', cookieBotSetLanguage);
    }
  });

  return null;
};

export default useCookiebotSetLanguage;
