import React from 'react';
import AddCircle from '../../icons/svgIconsNew/add-circle.svg';
import Arrow from '../../icons/svgIconsNew/arrow.svg';
import Back from '../../icons/svgIconsNew/back-menu.svg';
import Bed from '../../icons/svgIconsNew/bed.svg';
import Calendar from '../../icons/svgIconsNew/calendar.svg';
import Calendar31 from '../../icons/svgIconsNew/calendar31.svg';
import Camera from '../../icons/svgIconsNew/camera.svg';
import Cart from '../../icons/svgIconsNew/cart.svg';
import ChevronDown from '../../icons/svgIconsNew/chevron-down.svg';
import ChevronRight from '../../icons/svgIconsNew/chevron-right.svg';
import Close from '../../icons/svgIconsNew/close.svg';
import Rocket from '../../icons/svgIconsNew/cohete.svg';
import Compass from '../../icons/svgIconsNew/compass.svg';
import GreenEllipse from '../../icons/svgIconsNew/ellipse-green.svg';
import RedEllipse from '../../icons/svgIconsNew/ellipse-red.svg';
import StarRaw from '../../icons/svgIconsNew/estrella.svg';
import Facebook from '../../icons/svgIconsNew/facebook-menu.svg';
import Fork from '../../icons/svgIconsNew/fork.svg';
import Formulario from '../../icons/svgIconsNew/formulario-menu.svg';
import Gift from '../../icons/svgIconsNew/gift.svg';
import Group from '../../icons/svgIconsNew/group.svg';
import Height from '../../icons/svgIconsNew/height.svg';
import Hotel from '../../icons/svgIconsNew/hotel-menu.svg';
import InstagramMenu from '../../icons/svgIconsNew/instagram-menu.svg';
import Internet from '../../icons/svgIconsNew/internet.svg';
import Location from '../../icons/svgIconsNew/location.svg';
import LogoPawWhite from '../../icons/svgIconsNew/logo-paw-white.svg';
import LogoPaw from '../../icons/svgIconsNew/logo-paw.svg';
import CAPLogo from '../../icons/svgIconsNew/logoCAP.svg';
import FLLogo from '../../icons/svgIconsNew/logoFL.svg';
import PAWLogo from '../../icons/svgIconsNew/logoPAW.svg';
import Maletin from '../../icons/svgIconsNew/maletin.svg';
import Mapa from '../../icons/svgIconsNew/mapa-menu.svg';
import Message from '../../icons/svgIconsNew/message.svg';
import Mission from '../../icons/svgIconsNew/mission.svg';
import Mobile from '../../icons/svgIconsNew/mobile.svg';
import Moon from '../../icons/svgIconsNew/moon.svg';
import Ok from '../../icons/svgIconsNew/ok.svg';
import Perfil from '../../icons/svgIconsNew/perfil-menu.svg';
import Pizza from '../../icons/svgIconsNew/pizza.svg';
import Plus from '../../icons/svgIconsNew/plus.svg';
import PromoMenu from '../../icons/svgIconsNew/promotion-menu.svg';
import Promotion from '../../icons/svgIconsNew/promotion.svg';
import Search from '../../icons/svgIconsNew/search.svg';
import Shop from '../../icons/svgIconsNew/shop.svg';
import Single from '../../icons/svgIconsNew/single.svg';
import Size from '../../icons/svgIconsNew/size.svg';
import Star from '../../icons/svgIconsNew/star.svg';
import Substract from '../../icons/svgIconsNew/substract.svg';
import Tag from '../../icons/svgIconsNew/tag.svg';
import Telefono from '../../icons/svgIconsNew/telefono-menu.svg';
import Ticket from '../../icons/svgIconsNew/ticket-menu.svg';
import Tickets from '../../icons/svgIconsNew/tickets.svg';

import Alert from '../../icons/svgIconsNew/alert.svg';

import Twitter from '../../icons/svgIconsNew/twitter-menu.svg';
import Wallet from '../../icons/svgIconsNew/wallet.svg';
import Youtube from '../../icons/svgIconsNew/youtube-menu.svg';
import TikTok from '../../icons/svgIconsNew/tiktok.svg';
import Trash from '../../icons/svgIconsNew/trash.svg';
import Train from '../../icons/svgIconsNew/train.svg';

import Wheelchair from '../../icons/svgIconsNew/wheelchair.svg';

import Car from '../../icons/svgIconsNew/car.svg';
import Bus from '../../icons/svgIconsNew/bus.svg';
import Flight from '../../icons/svgIconsNew/flight.svg';
import Ship from '../../icons/svgIconsNew/ship.svg';
import Box from '../../icons/svgIconsNew/box.svg';
import Burger from '../../icons/svgIconsNew/burger-menu.svg';
import Park from '../../icons/svgIconsNew/park.svg';
import Blog from '../../icons/svgIconsNew/blog.svg';
import Bcorp from '../../icons/svgIconsNew/BCorp.svg';

const icons = {
  alert: <Alert />,
  back: <Back />,
  blog: <Blog />,
  burger: <Burger />,
  buscador: <Search />,
  chevronDown: <ChevronDown />,
  internet: <Internet />,
  chevronRight: <ChevronRight />,
  close: <Close />,
  park: <Park />,
  search: <Search />,
  compass: <Compass />,
  bed: <Bed />,
  size: <Size />,
  group: <Group />,
  rocket: <Rocket />,
  star_raw: <StarRaw />,
  calendar: <Calendar />,
  calendar31: <Calendar31 />,
  wallet: <Wallet />,
  shop: <Shop />,
  fork: <Fork />,
  hotel: <Hotel />,
  ok: <Ok />,
  person: <Perfil />,
  promotion_menu: <PromoMenu />,
  ticket: <Ticket />,
  message: <Message />,
  mission: <Mission />,
  star: <Star />,
  tickets: <Tickets />,
  cart: <Cart />,
  arrow: <Arrow />,
  substract: <Substract />,
  plus: <Plus />,
  mapa: <Mapa />,
  mobile: <Mobile />,
  maletin: <Maletin />,
  twitter: <Twitter />,
  facebook: <Facebook />,
  youtube: <Youtube />,
  instagram: <InstagramMenu />,
  telefonoMenu: <Telefono />,
  formularioMenu: <Formulario />,
  location: <Location />,
  single: <Single />,
  telefono: <Telefono />,
  formulario: <Formulario />,
  promotion: <Promotion />,
  camera: <Camera />,
  height: <Height />,
  logoPaw: <LogoPaw />,
  logoPawWhite: <LogoPawWhite />,
  tiktok: <TikTok />,
  addcircle: <AddCircle />,
  trash: <Trash />,
  moon: <Moon />,
  train: <Train />,
  greenEllipse: <GreenEllipse />,
  redEllipse: <RedEllipse />,
  wheelchair: <Wheelchair />,
  pizza: <Pizza />,
  portaventuraLogo: <PAWLogo />,
  ferrarilandLogo: <FLLogo />,
  caribeLogo: <CAPLogo />,
  car: <Car />,
  bus: <Bus />,
  flight: <Flight />,
  ship: <Ship />,
  gift: <Gift />,
  tag: <Tag />,
  box: <Box />,
  bcorp: <Bcorp />,
};

/**
 * Componente que renderiza un icono de acuerdo a un tipo de icono.
 *
 * @param icon Tipo de icono a renderizar.
 * @param className estilos a aplicar al icono.
 * @returns algo del estilo <Icon icon="compass" className="icon-class" />
 */

const SvgIcon = ({ className = '', icon }) => {
  return React.cloneElement(icons[icon], { className });
};

export default SvgIcon;
