import React from 'react';
import SvgIcon from '../../../common/SvgIcon';
import styles from './searchMenu.module.scss';

const SearchInput = ({ searchIn, searchValue, setSearchValue, searchMenuInput, closeSearch }) => (
  <form action={searchIn} className={styles.form}>
    <SvgIcon icon="buscador" className={styles.iconInput} />

    <input
      value={searchValue}
      required
      onChange={setSearchValue}
      id="searchMenu-input"
      ref={searchMenuInput}
      type="search"
      placeholder="Buscar en PortAventura World..."
      className={styles.input}
    />
    <input type="hidden" name="q" value={`${searchValue} site:www.portaventuraworld.com`} />
    <button type="button" onClick={closeSearch} className={styles.close}>
      <SvgIcon icon="close" />
    </button>
  </form>
);

export default SearchInput;
